import React from 'react';
import './farmCard.css';
import { divideWithDecimals } from '../../utils/mathUtils';
import { weiToEthWithDecimals } from '../../web3/utils';
import { DynamicIcon } from '../dynamicIcon/dynamicIcon';

type FarmCardProps = {
    totalShares: string;
    potValue: string;
}

export const FarmCard: React.FC<FarmCardProps> = ({ totalShares, potValue }) => {

    const farmShareIcon = <DynamicIcon name='FS' width={20} />
    const scratchIcon = <DynamicIcon name='SCRATCH' width={25} />

    return (
        <div className='farm-card'>
            <div className='farm-card-header'>
                <div className='farm-card-header-title'>
                    HOUSE FARM
                </div>
                <div className='farm-card-header-subtitle'>
                    {farmShareIcon}{weiToEthWithDecimals(totalShares, 0)} Farm Shares
                </div>
            </div>
            <div className='farm-card-body'>
                <div className='farm-card-body-pot'>
                    <div className='farm-card-body-pot-title'>
                        ~ Pot Value
                    </div>
                    <div className='farm-card-body-pot-value'>
                        {scratchIcon}{weiToEthWithDecimals(potValue, 0)}
                    </div>
                </div>
                <div className='farm-card-body-pot'>
                    <div className='farm-card-share-price-title'>
                        ~ Share Price
                    </div>
                    <div className='farm-card-share-price-value'>
                        {scratchIcon}{totalShares !== '0' ? divideWithDecimals(potValue, totalShares, 4) : '0'}
                    </div>
                </div>
            </div>
        </div>
    )
}