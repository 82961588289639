import './tetrisPage.css';
import { useCallback, useEffect, useState } from 'react';
import { usePageLoad } from '../../../../context/pageLoadContext';
import useHeartbeat from '../../../../hooks/useHeartbeat';
import useWebSocket from '../../../../hooks/useWebSocket';
import config from '../../../../config';
import { TetrisGame } from '../../../../components/games/tetris/tetrisGame';
import arcadeApi from '../../../../requests/arcadeApi';
import { HighScore } from '../../../../components/games/tetris/tetrisTypes';
import { DynamicTier } from '../../../../components/dynamicTier/dynamicTier';
import { DynamicIcon } from '../../../../components/dynamicIcon/dynamicIcon';
import { TetrisFooter } from './tetrisFooter';
import { PVPGameState } from './tetrisPageTypes';
import { useWeb3 } from '../../../../context/web3Context';
import { ButtonHandler } from '../../../../components/games/tetris/tetrisControls/tetrisControls';
import { useArcadeBalance } from '../../../../context/arcadeBalanceContext';
import { isBiggerThan } from '../../../../utils/mathUtils';
import Web3 from 'web3';

export const TetrisPage: React.FC = () => {

    const { togglePageLoad } = usePageLoad();
    const { address } = useWeb3();
    const { arcadeScratchBalance, arcadeZoomerBalance, refreshBalance } = useArcadeBalance();

    // Use the heartbeat hook to keep the user's session alive
    useHeartbeat();

    const handleWebSocketMessage = useCallback((event: MessageEvent) => {
        const jsonData = JSON.parse(event.data);

        if (jsonData.game === 'BLOCKBUSTER' && jsonData.updateTag) {
            switch (jsonData.updateTag) {
                case 'UPDATE':
                    setGameState(jsonData.gameState);
                    break;
                case 'GAME_OVER':
                    // Handle the game over scenario
                    // This could involve updating the UI, showing scores, etc.
                    setIsPlaying(false);
                    setGameState(jsonData.gameState);
                    initialLoad();
                    // refresh balance after 2 seconds
                    setTimeout(() => {
                        refreshBalance();
                    }, 2000);
                    break;
                // Add cases for other actions as necessary
            }
        } else if (jsonData.game === 'BLOCKBUSTER' && jsonData.message === "High score saved") {
            loadHighScores();
        }
    }, []);

    const initialGameState = {
        board: Array.from(Array(20), () => new Array(10).fill(0)),
        upcomingPieces: [0, 0, 0],
        score: 0,
        level: 0,
    };

    const { ws, sendMessage } = useWebSocket(config.BASE_WS_URL, handleWebSocketMessage);
    const [gameState, setGameState] = useState<any>(initialGameState);
    const [loading, setLoading] = useState<boolean>(true);
    const [isPlaying, setIsPlaying] = useState<boolean>(false); // TODO: use this to show/hide the menu
    const [userHighScores, setUserHighScores] = useState<HighScore[]>([]);
    const [globalHighScores, setGlobalHighScores] = useState<HighScore[]>([]);
    const [gameMode, setGameMode] = useState<'PRACTICE' | 'PVP' | null>(null);
    const [PVPTier, setPVPTier] = useState<'BRONZE' | 'SILVER' | 'GOLD' | null>(null);
    const [windowView, setWindowView] = useState<'MODE_SELECT' | 'GAME'>('MODE_SELECT');
    const [gameHistory, setGameHistory] = useState<PVPGameState[]>([]);
    const [activeGames, setActiveGames] = useState<PVPGameState[]>([]);

    const withPageLoad = async (
        fns: (() => Promise<void>)[],
        togglePageLoad: (bool: boolean) => void
    ) => {
        togglePageLoad(true);
        await Promise.all(fns.map(fn => fn()));
        togglePageLoad(false);
    };

    const withStateLoading = async (
        fns: (() => Promise<void>)[],
        setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        setLoading(true);
        try {

            await Promise.all(fns.map(fn => fn()));
            setLoading(false);
        } catch (e) {
            console.log('Error fetching high scores:', e);
            setLoading(false);
        }
    };


    const fetchHighScores = async () => {
        return arcadeApi.getHighScores('BLOCKBUSTER')
            .then((res) => {
                console.log('High Scores:', res);
                if (res.userHighScores && res.globalHighScores) {
                    setUserHighScores(res.userHighScores);
                    setGlobalHighScores(res.globalHighScores);
                }
            })
    }

    const fetchGameHistory = async () => {
        return arcadeApi.getUserBlockbusterPVPGameHistory()
            .then((res) => {
                console.log('Game History:', res);
                if (res.gameHistory && res.activeGames) {
                    setGameHistory([...res.gameHistory].reverse());
                    setActiveGames(res.activeGames);
                }
            })
    };

    const loadHighScores = async () => {
        await withStateLoading([fetchHighScores], setLoading);
    };

    const initialLoad = async () => {
        await withPageLoad([loadHighScores, fetchGameHistory], togglePageLoad);
    };

    const onStartGame = () => {
        if (!ws || !gameMode) return;
        if (gameMode === 'PVP' && !PVPTier) return;
        sendMessage({
            game: 'BLOCKBUSTER',
            action: 'START_GAME',
            gameMode: gameMode,
            PVPTier: PVPTier,
        });
        setIsPlaying(true);
    };

    const checkCanStartGame = () => {
        if (gameMode === 'PVP' && !PVPTier) return false;
        const playAmountWei = Web3.utils.toWei(getPlayAmount(), 'ether');
        if (gameMode === 'PVP' && isBiggerThan(playAmountWei, arcadeScratchBalance)) return false;

        return true;
    };

    const sendPlayerAction = (actionType, eventType) => {
        sendMessage({
            game: 'BLOCKBUSTER',
            action: 'PLAYER_ACTION',
            actionType: actionType,
            eventType: eventType
        });
    };

    // Mobile control objects
    const handleLeftButton: ButtonHandler = {
        up: () => sendPlayerAction('left', 'up'),
        down: () => sendPlayerAction('left', 'down'),
    }

    const handleRightButton: ButtonHandler = {
        up: () => sendPlayerAction('right', 'up'),
        down: () => sendPlayerAction('right', 'down'),
    }

    const handleDownButton: ButtonHandler = {
        up: () => sendPlayerAction('down', 'up'),
        down: () => sendPlayerAction('down', 'down'),
    }

    const handleHardDropButton: ButtonHandler = {
        up: () => { },
        down: () => sendPlayerAction('hardDrop', 'down'),
    }

    const handleRotateButton: ButtonHandler = {
        up: () => { },
        down: () => sendPlayerAction('rotate', 'down'),
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            const eventCode = event.code;
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Space'].includes(eventCode)) {
                event.preventDefault();
                if (event.repeat) return; // Prevent handling key repeats

                switch (eventCode) {
                    case 'ArrowDown':
                        sendPlayerAction('down', 'down');
                        break;
                    case 'ArrowUp':
                        sendPlayerAction('rotate', 'down');
                        break;
                    case 'ArrowLeft':
                        sendPlayerAction('left', 'down');
                        break;
                    case 'ArrowRight':
                        sendPlayerAction('right', 'down');
                        break;
                    case 'Space': // Handle the spacebar for hard drop
                        sendPlayerAction('hardDrop', 'down');
                        break;
                }
            }
        };


        const handleKeyUp = (event) => {
            const eventCode = event.code;
            if (['ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(eventCode)) {
                event.preventDefault();

                switch (eventCode) {
                    case 'ArrowDown':
                        sendPlayerAction('down', 'up');
                        break;
                    case 'ArrowLeft':
                        sendPlayerAction('left', 'up');
                        break;
                    case 'ArrowRight':
                        sendPlayerAction('right', 'up');
                        break;
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [sendPlayerAction]); // sendPlayerAction used as a dependency to make sure it uses most recent websocket connection



    // Get high scores from backend
    useEffect(() => {
        initialLoad();
    }, [])

    const modeSelectCard = (mode: 'PRACTICE' | 'PVP', tier: 'BRONZE' | 'SILVER' | 'GOLD' | null) => {

        const scratchIcon = <DynamicIcon name='SCRATCH' width={50} />;

        const getCost = (mode: 'PRACTICE' | 'PVP', tier: 'BRONZE' | 'SILVER' | 'GOLD' | null) => {
            if (mode === 'PRACTICE') return 'PLAY FOR FREE!';
            if (mode === 'PVP') {
                if (tier === 'BRONZE') return '50';
                if (tier === 'SILVER') return '200';
                if (tier === 'GOLD') return '500';
            }
        }

        const getColor = () => {
            if (tier === 'BRONZE') return 'BRONZE';
            if (tier === 'SILVER') return 'SILVER';
            if (tier === 'GOLD') return 'GOLD';
            return 'BRONZE'
        }

        return (
            <div
                className={'button tetris-modeSelect-button'}
                onClick={() => {
                    setGameMode(mode);
                    setPVPTier(tier);
                    setWindowView('GAME');
                }}
            >
                <div className='tetris-modeSelect-button-title'>{mode}</div>
                {
                    tier && <div className='tetris-modeSelect-button-tier'>{tier}</div>
                }

                {mode === 'PVP' && <DynamicTier tier={getColor()} width={130} />}
                <div className='tetris-modeSelect-button-description'>{getCost(mode, tier)}{mode === 'PVP' && scratchIcon}</div>
            </div>
        )
    };

    const getPlayAmount = (): string => {
        switch (PVPTier) {
            case 'BRONZE':
                return '50';
            case 'SILVER':
                return '200';
            case 'GOLD':
                return '500';
            case null:
            default:
                return 'FREE'
        }
    }

    const getEstimatedPlayCost = () => {
        console.log('arcadeZoomerBalance: ', arcadeZoomerBalance)
        if (!PVPTier) {
            return 'FREE'
        }
        if (isBiggerThan(arcadeZoomerBalance, '2')) {
            return '3.0%'
        } else if (isBiggerThan(arcadeZoomerBalance, '1')) {
            return '3.5%'
        } else if (isBiggerThan(arcadeZoomerBalance, '0')) {
            return '4.0%'
        } else if (arcadeZoomerBalance === '0') {
            return '5.0%'
        } else {
            return '5.0%'
        }
    }

    const showWindowView = () => {
        switch (windowView) {
            case 'MODE_SELECT':
                return (
                    <div className={'tetris-modeSelect'}>
                        {modeSelectCard('PRACTICE', null)}
                        {modeSelectCard('PVP', 'BRONZE')}
                        {modeSelectCard('PVP', 'SILVER')}
                        {modeSelectCard('PVP', 'GOLD')}
                    </div>
                );
            case 'GAME':
                return (
                    <TetrisGame
                        currentBoard={gameState.board || undefined}
                        upcomingBlocks={gameState.upcomingPieces || undefined}
                        score={gameState.score || undefined}
                        level={gameState.level || undefined}
                        startGame={onStartGame}
                        isPlaying={isPlaying}
                        userHighScores={userHighScores}
                        globalHighScores={globalHighScores}
                        loading={loading}
                        handleLeft={handleLeftButton}
                        handleRight={handleRightButton}
                        handleDown={handleDownButton}
                        handleHardDrop={handleHardDropButton}
                        handleRotate={handleRotateButton}
                        gameMode={gameMode}
                        pvpTier={PVPTier}
                        playAmount={getPlayAmount()}
                        estimatedPlayCost={getEstimatedPlayCost()}
                    />
                );
        };
    };

    return (
        <div className='tetrisPage-with-footer page'>
            <div className="tetrisPage non-selectable">
                <img className={'tetris-titleImage'} src={'titleCards/blockbuster.png'} alt='' />
                <div className='blockbuster-disclaimer'>
                    <div>
                        You may only have 5 <span style={{ color: 'var(--highlight-text)' }}> Active Games at a time</span>
                    </div>
                    <div>
                        <span style={{ color: 'var(--highlight-text)' }}> Scratch </span><DynamicIcon name='SCRATCH' width={20} /> is deducted from your balance at game start
                    </div>
                    <div>
                        Opponent will be selected based on oldest available <span style={{ color: 'var(--highlight-text)' }}>Seed.</span> A new <span style={{ color: 'var(--highlight-text)' }}>Seed</span> will be created if none exist
                    </div>
                    <div>
                        Both players will get the <span style={{ color: 'var(--highlight-text)' }}>same blocks</span> in the <span style={{ color: 'var(--highlight-text)' }}>same order</span>
                    </div>
                    <div>
                        Game cost is deducted from winner based on their <span style={{ color: 'var(--highlight-text)' }}><DynamicIcon name='ZOOMER' width={20} /> Staked Zoomer Balance</span> at time of win
                    </div>
                    <div>
                        0 <DynamicIcon name='ZOOMER' width={20} /> Zoomers = 5%
                    </div>
                    <div>
                        1 <DynamicIcon name='ZOOMER' width={20} /> Zoomer = 4%
                    </div>
                    <div>
                        2 <DynamicIcon name='ZOOMER' width={20} /> Zoomers = 3.5%
                    </div>
                    <div>
                        3+ <DynamicIcon name='ZOOMER' width={20} /> Zoomers = 3%
                    </div>
                    <div>
                        <span style={{ color: 'var(--highlight-text)' }}>Contact Us </span>on Discord if you have any issues.
                    </div>
                </div>
                {
                    windowView === 'GAME' &&
                    <div
                        className='button large button-primary'
                        onClick={() => {
                            setWindowView('MODE_SELECT');
                            setIsPlaying(false);
                            initialLoad();
                        }}
                    >
                        <DynamicIcon name={'UNDO'} width={25} />Mode Select
                    </div>
                }
                {showWindowView()}
            </div>
            {address && <TetrisFooter activeGames={activeGames} gameHistory={gameHistory} userAddress={address} />}
        </div>
    );
}