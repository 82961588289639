import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ErrorModal } from '../components/modals/errorModal/errorModal';

// Define the type of values that the context will provide
interface ErrorModalContextProps {
    showErrorModal: (message: string) => void;
    hideErrorModal: () => void;
}

// Create the context with optional initial values
const ErrorModalContext = createContext<ErrorModalContextProps | null>(null);

// Export a custom hook to use the Modal context
export const useErrorModal = (): ErrorModalContextProps => {
    const context = useContext(ErrorModalContext);
    if (!context) {
        throw new Error('useErrorModal must be used within a ModalProvider');
    }
    return context;
};

// Define the props for the ModalProvider component
interface ModalProviderProps {
    children: ReactNode;
}

// Define the ModalProvider component
export const ErrorModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');

    const showErrorModal = (message: string) => {
        setErrorModalMessage(message);
        setErrorModalOpen(true);
    };

    const hideErrorModal = () => {
        setErrorModalOpen(false);
        setErrorModalMessage('');
        window.location.href = '/';
    };

    // Provide the showModal and hideModal functions to the components within ModalProvider
    return (
        <ErrorModalContext.Provider value={{ showErrorModal, hideErrorModal }}>
            {children}
            <ErrorModal open={errorModalOpen} message={errorModalMessage} onClose={hideErrorModal} />
        </ErrorModalContext.Provider>
    );
};