import './userPanel.css';
import ConnectButton from './connectButton/connectButton';
import { WalletButton } from './walletButton/walletButton';

type UserPanelProps = {
    userAddress: string | null;
    connectButtonClick: Function;
};

export const UserPanel: React.FC<UserPanelProps> = ({ userAddress, connectButtonClick}) => {

    return (
        <>
            {
                /* Conditionally render the connect button if there is no userAddress */
                userAddress ? (
                    <WalletButton userAddress={userAddress} />
                ) : (
                    <ConnectButton onClick={connectButtonClick}/>
                )
            }
        </>

    );
};