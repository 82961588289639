import { useState } from "react";
import { DynamicIcon } from "../../../dynamicIcon/dynamicIcon";
import './tetrisMenu.css'

type HighScore = {
    userAddress: string;
    score: number;
}

type TetrisMenuProps = {
    startGame: () => void;
    isPlaying: boolean;
    score: number;
    userHighScores: HighScore[];
    globalHighScores: HighScore[];
    loading: boolean;
    gameMode: 'PRACTICE' | 'PVP' | null;
    pvpTier: 'BRONZE' | 'SILVER' | 'GOLD' | null;
    playAmount: string;
    estimatedPlayCost: string;
}

export const TetrisMenu: React.FC<TetrisMenuProps> = (
    {
        startGame,
        isPlaying,
        score,
        userHighScores,
        globalHighScores,
        loading,
        gameMode,
        pvpTier,
        playAmount,
        estimatedPlayCost,
    }) => {

    const [view, setView] = useState<'New Game' | 'Go'>('New Game')

    const getView = () => {
        switch (view) {
            case 'New Game':
                return (
                    <>
                        <div
                            className={`button button-primary medium ${isPlaying ? 'disabled' : ''}`}
                            onClick={() => setView('Go')}>
                            New Game
                        </div>
                        <div className='tetrisBoard-menu-title'>
                            High Scores
                        </div>
                        <div className={'tetrisBoard-menu-container-highScores-container'}>
                            <div className={'tetrisBoard-menu-container-highScores-col'}>
                                <div className={'tetrisBoard-menu-container-highScores-col-title'}>
                                    USER
                                </div>
                                {
                                    loading ?
                                        <div className='tetrisBoard-menu-value'>
                                            Loading...
                                        </div>
                                        :
                                        HighScoreList(userHighScores)
                                }
                            </div>
                            <div className={'tetrisBoard-menu-container-highScores-col'}>
                                <div className={'tetrisBoard-menu-container-highScores-col-title'}>
                                    GLOBAL
                                </div>
                                {
                                    loading ?
                                        <div className='tetrisBoard-menu-value'>
                                            Loading...
                                        </div>
                                        :
                                        HighScoreList(globalHighScores)
                                }
                            </div>
                        </div>
                        <div className='tetrisBoard-menu-row'>
                            <div className='tetrisBoard-menu-title'>
                                LAST GAME
                            </div>
                            <div className='tetrisBoard-menu-value'>
                                {score}
                            </div>
                        </div>
                    </>
                )
            case 'Go':
                return (
                    <>
                        <div
                            className={`button button-primary medium ${isPlaying ? 'disabled' : ''}`}
                            onClick={() => setView('New Game')}>
                            <DynamicIcon name='UNDO' width={20} /> Back
                        </div>
                        <div className="tetrisBoard-menu-title">
                            Settings
                        </div>
                        <div className='tetrisBoard-menu-row'>
                            <div className='tetrisBoard-menu-title'>
                                GAME MODE
                            </div>
                            <div className='tetrisBoard-menu-value-two'>
                                {gameMode}
                            </div>
                        </div>
                        {
                            pvpTier &&
                            <div className='tetrisBoard-menu-row'>
                                <div className='tetrisBoard-menu-title'>
                                    PVP TIER
                                </div>
                                <div className='tetrisBoard-menu-value-two'>
                                    {pvpTier}
                                </div>
                            </div>
                        }
                        <div className='tetrisBoard-menu-row'>
                            <div className='tetrisBoard-menu-title'>
                                PLAY AMOUNT
                            </div>
                            <div className='tetrisBoard-menu-value-two'>
                                {playAmount} {pvpTier && <DynamicIcon name='SCRATCH' width={20} />}
                            </div>
                        </div>
                        <div className='tetrisBoard-menu-row'>
                            <div className='tetrisBoard-menu-title'>
                                EST. PLAY COST
                            </div>
                            <div className='tetrisBoard-menu-value-two'>
                                {estimatedPlayCost}
                            </div>
                        </div>
                        <div
                            className={`button button-tertiary medium ${isPlaying ? 'disabled' : ''}`}
                            onClick={
                                () => {
                                    startGame();
                                    setView('New Game');
                                }
                            }>
                            START!
                        </div>
                    </>
                )
        }
    }

    const HighScoreList = (highScores: HighScore[]) => {
        return (
            <>
                {
                    highScores.map((highScore, index) => {
                        return (
                            <div key={'menuRow-' + index} className='tetrisBoard-menu-row'>
                                <div className='tetrisBoard-menu-key'>
                                    {index + 1}. <span style={{ color: 'var(--secondary-text)' }}>{highScore.userAddress.slice(-4)}</span>
                                </div>
                                <div key={index} className='tetrisBoard-menu-value'>
                                    {highScore.score}
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }

    return (
        <div className={`tetrisBoard-menu-container ${isPlaying && 'isPlaying'}`}>
            <div className='tetrisBoard-menu'>
                {getView()}
            </div>
        </div>
    )
}