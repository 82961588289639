import React, { useState } from "react";
import { formatDate } from "../../../utils/dateUtils";
import { weiToEthWithDecimals } from "../../../web3/utils";
import './depositWithdrawHistory.css'
import Web3 from "web3";
import { DepositWithdrawHistoryLog, DepositWithdrawHistoryLogType } from "../../../types/apiResponse";
import { copyToClipboard, formatTxHash, stringDisplay } from "../../../utils/stringUtils";

type DepositWithdrawHistoryProps = {
    history: DepositWithdrawHistoryLog[];
    setSortedHistory: React.Dispatch<React.SetStateAction<DepositWithdrawHistoryLog[]>>;
}

const ITEMS_PER_PAGE = 10; // History items per page

export const DepositWithdrawHistory: React.FC<DepositWithdrawHistoryProps> = ({ history, setSortedHistory }) => {

    enum SortingMethod {
        TYPE = "TYPE",
        TIME = "TIME",
        TXHASH = "TXHASH",
        AMOUNT = "AMOUNT",
        STATUS = "STATUS"
    }

    // Sorting methods, keep track of ascending/descending per column
    const [timeAscending, setTimeAscending] = useState<boolean>(true);
    const [typeAscending, setTypeAscending] = useState<boolean>(false);
    const [txHashAscending, setTxHashAscending] = useState<boolean>(false);
    const [amountAscending, setAmountAscending] = useState<boolean>(false);
    const [statusAscending, setStatusAscending] = useState<boolean>(false);

    const [sortingMethod, setSortingMethod] = useState<SortingMethod>(SortingMethod.TIME);
    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(history.length / ITEMS_PER_PAGE);

    // Get current page data
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    // Get row color to add to row class
    const getRowColor = (status: string): string => {
        const positive = 'positive';
        const negative = 'negative';
        const neutral = 'neutral';
        switch (status) {
            case "CONFIRMED":
                return positive;
            case "ERROR":
                return negative;
            default:
                return neutral;
        }
    }

    const weiToNum = (wei: string): number => {
        return Number(Web3.utils.fromWei(wei, 'ether'));
    }

    const sortTime = () => {
        setTimeAscending(!timeAscending);
        setSortingMethod(SortingMethod.TIME);
        setSortedHistory([...history.sort((a, b) => {
            return timeAscending ? new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime() : new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        })]);
    }

    const sortType = () => {
        setTypeAscending(!typeAscending);
        setSortingMethod(SortingMethod.TYPE);
        setSortedHistory([...history.sort((a, b) => {
            return typeAscending ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type);
        })]);
    }

    const sortTxHash = () => {
        setTxHashAscending(!txHashAscending);
        setSortingMethod(SortingMethod.TXHASH);
        setSortedHistory([...history.sort((a, b) => {
            return txHashAscending ? (a.txHash || '').localeCompare(b.txHash || '') : (b.txHash || '').localeCompare(a.txHash || '');
        })]);
    }

    const sortAmount = () => {
        setAmountAscending(!amountAscending);
        setSortingMethod(SortingMethod.AMOUNT);
        setSortedHistory([...history.sort((a, b) => {
            return amountAscending ? weiToNum(a.amount) - weiToNum(b.amount) : weiToNum(b.amount) - weiToNum(a.amount);
        })]);
    }

    const sortStatus = () => {
        setStatusAscending(!statusAscending);
        setSortingMethod(SortingMethod.STATUS);
        setSortedHistory([...history.sort((a, b) => {
            return statusAscending ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
        })]);
    }

    const nextPage = () => {
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            paginate(currentPage - 1);
        }
    }

    const firstPage = () => {
        paginate(1);
    }

    const lastPage = () => {
        paginate(totalPages);
    }

    const displaySortingArrow = (method: SortingMethod, isAscending: boolean) => {
        if (sortingMethod === method) {
            return (
                <div className="history-sorting-arrow">
                    {
                        isAscending ?
                            <span>
                                {'▲'}
                            </span>
                            :
                            <span>
                                {'▼'}
                            </span>
                    }
                </div>
            )
        }
        return '';
    }

    const isSelected = (method: SortingMethod): string => {
        return sortingMethod === method ? 'selected' : '';
    }

    return (
        <div className="dw-history-container non-selectable">
            <div className="history-title">
                Deposits / Withdraws
            </div>
            {/* Pagination Controls */}
            <div className="history-pagination">
                <div
                    className="history-pagination-btn"
                    onClick={firstPage}
                >
                    {'<<'}
                </div>
                <div
                    className="history-pagination-btn"
                    onClick={prevPage}
                >
                    {'<'}
                </div>
                PAGE {currentPage} / {totalPages}
                <div
                    className="history-pagination-btn"
                    onClick={nextPage}
                >
                    {'>'}
                </div>
                <div
                    className="history-pagination-btn"
                    onClick={lastPage}
                >
                    {'>>'}
                </div>
            </div>
            <div className="history-table-container">
                <table className="history-table">
                    <thead className="history-grid-header">
                        <tr>
                            <th
                                onClick={sortTime}
                                className={isSelected(SortingMethod.TIME)}
                            >
                                Time {displaySortingArrow(SortingMethod.TIME, timeAscending)}
                            </th>
                            <th
                                onClick={sortType}
                                className={isSelected(SortingMethod.TYPE)}
                            >
                                Type {displaySortingArrow(SortingMethod.TYPE, typeAscending)}
                            </th>
                            <th
                                onClick={sortAmount}
                                className={isSelected(SortingMethod.AMOUNT)}
                            >
                                Scratch Amount {displaySortingArrow(SortingMethod.AMOUNT, amountAscending)}
                            </th>
                            <th
                                onClick={sortTxHash}
                                className={isSelected(SortingMethod.TXHASH)}
                            >
                                txHash {displaySortingArrow(SortingMethod.TXHASH, txHashAscending)}
                            </th>
                            <th
                                onClick={sortStatus}
                                className={isSelected(SortingMethod.STATUS)}
                            >
                                Status {displaySortingArrow(SortingMethod.STATUS, statusAscending)}
                            </th>
                        </tr>
                    </thead>
                    {currentItems.map((log, index) => (
                        <tr key={index} className={getRowColor(log.status)}>
                            {/**Time */}
                            <td>{formatDate(log.timestamp)}</td>
                            {/**Type */}
                            <td >{stringDisplay(log.type)}</td>
                            {/**Amount */}
                            <td>{weiToEthWithDecimals(log.amount, 2)}</td>
                            {/**txHash */}
                            <td
                                className="dw-history-table-txhash-cell"
                                onClick={(log.txHash && log.txHash !== '') ? () => copyToClipboard(log.txHash || '') : () => ''}
                            >
                                {formatTxHash(log.txHash || '')}
                            </td>
                            {/**Status */}
                            <td>{stringDisplay(log.status)}</td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
}