import { useState, useEffect, useCallback } from 'react';

const useWebSocket = (url: string, onMessage: Function) => {
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [wsConnected, wsSetConnected] = useState(false);

    useEffect(() => {
        // Initialize WebSocket connection
        const webSocket = new WebSocket(url);

        webSocket.onopen = () => {
            console.log('Connected to WebSocket server');
            // Additional logic for when the connection is opened
            wsSetConnected(true);
        };

        webSocket.onmessage = (event: MessageEvent) => {
            onMessage(event);
        };

        webSocket.onerror = (event) => {
            console.error('WebSocket error observed:', event);
            // Additional logic for handling errors
        };

        webSocket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            // Additional logic for when the connection is closed
            wsSetConnected(false);
        };

        setWs(webSocket);

        return () => {
            webSocket.close();
            console.log('WebSocket connection closed');
        };
    }, [url]);

    const sendMessage = useCallback((message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not open or connected.');
        }
    }, [ws]);

    return { ws, sendMessage, wsConnected };
};

export default useWebSocket;