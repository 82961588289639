import React, { useState } from 'react';
import './passRedeemCodeTable.css';

type PassRedeemCodeTableProps = {
    codes: string[];
};

export const PassRedeemCodeTable: React.FC<PassRedeemCodeTableProps> = ({ codes }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const totalItems = codes.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const displayedCodes = codes.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const changePage = (page: number) => {
        if (page >= 0 && page < totalPages) {
            setCurrentPage(page);
        }
    }

    return (
        <div className="passRedeemCodeTable">
            <table>
                <thead>
                    <tr>
                        <th>
                            <div>ZTB Labs Etsy Discount Codes</div>
                            <div className='small-warning-text'>Please give up to 72 hrs for your codes to become active</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {displayedCodes.map((code, index) => (
                        <tr key={index}>
                            <td>{code}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="passRedeemCodeTable-pagination">
                <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 0}>Prev</button>
                <span>Page {currentPage + 1} of {totalPages}</span>
                <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages - 1}>Next</button>
            </div>
        </div>
    );
}