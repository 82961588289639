import React, { useState } from 'react';
import './nftList.css';

// const [selectedTokenIds, setSelectedTokenIds] = useState<string[]>([]);

type NFTListProps = {
    tokenName: string;
    itemsPerPage: number;
    tokenIds: string[];
    imageUrl: (tokenId: string) => string;
    selectedTokens: string[];
    setSelectedTokens: (tokenIds: string[]) => void;
};

export const NFTList: React.FC<NFTListProps> = ({ tokenName, itemsPerPage, tokenIds, imageUrl, selectedTokens, setSelectedTokens }) => {
    const [currentPage, setCurrentPage] = useState(1);  // Pagination state

    // Sort tokenIds in descending order
    tokenIds.sort((a, b) => Number(b) - Number(a));

    const handleSelectTokens = (selectedTokens: string[], tokenIds: string[]) => {
        let tempSelectedTokenIds: string[] = [...selectedTokens];  // Clone the current array
        for (let tokenId of tokenIds) {
            if (tempSelectedTokenIds.includes(tokenId)) {
                tempSelectedTokenIds = tempSelectedTokenIds.filter(id => id !== tokenId);
            } else {
                // Only add the tokenId if we haven't reached the limit
                if (tempSelectedTokenIds.length < itemsPerPage) {
                    tempSelectedTokenIds.push(tokenId);
                } else {
                    console.warn(`You have reached the maximum selection limit of ${itemsPerPage} items.`);
                }
            }
        }

        setSelectedTokens(tempSelectedTokenIds);

    }

    const handleSelectPage = (displayedTokenIds: string[]) => {
        // Reset selected token and select page
        const emptyArray: string[] = [];
        handleSelectTokens(emptyArray, displayedTokenIds);
    }

    const resetSelectedTokens = () => {
        const emptyArray: string[] = [];
        setSelectedTokens(emptyArray);
    }

    const totalPages = Math.ceil(tokenIds.length / itemsPerPage);

    const displayedTokenIds = tokenIds.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div className='nft-container'>
            <div className='nft-list-select-buttons-container'>
                <div className='button button-secondary xxsmall'
                    onClick={() => handleSelectPage(displayedTokenIds)}>
                    Select Page
                </div>
                <div className='button button-tertiary xxsmall' onClick={resetSelectedTokens}>
                    Reset Selection
                </div>
            </div>
            <div className="nft-list-container">
                {displayedTokenIds.map(tokenId => (
                    <div
                        key={tokenId}
                        className={`nft-item`}
                        onClick={() => handleSelectTokens(selectedTokens, [tokenId])}
                    >
                        <img src={imageUrl(tokenId)} alt={`NFT ${tokenId}`} />
                        <div className={`nft-item-bottom-container ${selectedTokens.includes(tokenId) ? 'selected' : ''}`}>
                            {tokenName} #{tokenId}
                        </div>
                    </div>
                ))}
            </div>
            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button
                    className="pagination-button button-tertiary button-small"
                    disabled={currentPage === 1 || totalPages === 0}
                    onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                >
                    Previous
                </button>
                <span>{currentPage} of {totalPages}</span>
                <button
                    className="pagination-button button-tertiary button-small"
                    disabled={currentPage === totalPages || totalPages === 0}
                    onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                >
                    Next
                </button>
            </div>
        </div>

    );
};
