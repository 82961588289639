import React from 'react';
import './imageButton.css';

type ImageButtonProps = {
    onClick: () => void;
    width: number;
    src: string; // Image source
}

export const ImageButton: React.FC<ImageButtonProps> = ({ onClick, width, src }) => {
    return (
        <img src={src} className="imageButton" onClick={onClick} style={{ width: `${width}px` }} alt="Button Image" />
    );
}