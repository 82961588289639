import React, { useState } from "react";
import { formatDate } from "../../../utils/dateUtils";
import { weiToEthWithDecimals } from "../../../web3/utils";

import './balanceHistory.css'
import Web3 from "web3";
import { stringDisplay } from "../../../utils/stringUtils";

enum BalanceType {
    GAME_LOSS = "GAME_LOSS",
    GAME_WIN = "GAME_WIN",
    GAME_TIE = "GAME_TIE",
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW",
    REFUND = "REFUND",
    BUY_SHARES = "BUY_SHARES",
    SELL_SHARES = "SELL_SHARES",
    PVP_START = 'PVP_GAME_START',
    PVP_PAYOUT = "PVP_GAME_PAYOUT",
}

export type BalanceLog = {
    amount: string,
    logID: string,
    newBalance: string,
    previousBalance: string,
    timestamp: string,
    txHash: string,
    type: BalanceType,
    userAddress: string
}

type BalanceHistoryProps = {
    history: BalanceLog[];
    setSortedHistory: React.Dispatch<React.SetStateAction<BalanceLog[]>>;
}

const ITEMS_PER_PAGE = 15; // History items per page

export const BalanceHistory: React.FC<BalanceHistoryProps> = ({ history, setSortedHistory }) => {

    enum SortingMethod {
        TIME = "TIME",
        NEW_BAL = "NEW_BAL",
        CHANGE = "CHANGE",
        TYPE = "TYPE"
    }

    // Sorting methods, keep track of ascending/descending per column
    const [timeAscending, setTimeAscending] = useState<boolean>(true);
    const [newBalAscending, setNewBalAscending] = useState<boolean>(false);
    const [changeAscending, setChangeAscending] = useState<boolean>(false);
    const [typeAscending, setTypeAscending] = useState<boolean>(false);
    const [sortingMethod, setSortingMethod] = useState<SortingMethod>(SortingMethod.TIME);
    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(history.length / ITEMS_PER_PAGE);

    // Get current page data
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const formatBalanceChange = (oldBalance: string, newBalance: string, amount: string): string => {
        return Number(oldBalance) < BigInt(newBalance) ? `${weiToEthWithDecimals(amount, 2)}` : `-${weiToEthWithDecimals(amount, 2)}`;
    }

    // Get row color to add to row class
    const getRowColor = (type: BalanceType): string => {
        const positive = 'positive';
        const negative = 'negative';
        const neutral = 'neutral';
        switch (type) {
            case BalanceType.DEPOSIT:
            case BalanceType.GAME_WIN:
            case BalanceType.REFUND:
            case BalanceType.SELL_SHARES:
            case BalanceType.PVP_PAYOUT:
                return positive;
            case BalanceType.BUY_SHARES:
            case BalanceType.GAME_LOSS:
            case BalanceType.GAME_TIE:
            case BalanceType.WITHDRAW:
            case BalanceType.PVP_START:
                return negative;
            default:
                return neutral;
        }
    }

    const weiToNum = (wei: string): number => {
        return Number(Web3.utils.fromWei(wei, 'ether'));
    }

    const sortTime = () => {
        setTimeAscending(!timeAscending);
        setSortingMethod(SortingMethod.TIME);
        setSortedHistory([...history.sort((a, b) => {
            return timeAscending ? new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime() : new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        })]);
    }

    const sortNewBal = () => {
        setNewBalAscending(!newBalAscending);
        setSortingMethod(SortingMethod.NEW_BAL);
        setSortedHistory([...history.sort((a, b) => {
            return newBalAscending ? weiToNum(a.newBalance) - weiToNum(b.newBalance) : weiToNum(b.newBalance) - weiToNum(a.newBalance);
        })]);
    }

    const sortChange = () => {
        setChangeAscending(!changeAscending);
        setSortingMethod(SortingMethod.CHANGE);
        setSortedHistory([...history.sort((a, b) => {
            return changeAscending ? weiToNum(a.amount) - weiToNum(b.amount) : weiToNum(b.amount) - weiToNum(a.amount);
        })]);
    }

    const sortType = () => {
        setTypeAscending(!typeAscending);
        setSortingMethod(SortingMethod.TYPE);
        setSortedHistory([...history.sort((a, b) => {
            return typeAscending ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type);
        })]);
    }

    const nextPage = () => {
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            paginate(currentPage - 1);
        }
    }

    const firstPage = () => {
        paginate(1);
    }

    const lastPage = () => {
        paginate(totalPages);
    }

    const displaySortingArrow = (method: SortingMethod, isAscending: boolean) => {
        if (sortingMethod === method) {
            return (
                <div className="history-sorting-arrow">
                    {
                        isAscending ?
                            <span>
                                {'▲'}
                            </span>
                            :
                            <span>
                                {'▼'}
                            </span>
                    }
                </div>
            )
        }
        return '';
    }

    const isSelected = (method: SortingMethod): string => {
        return sortingMethod === method ? 'selected' : '';
    }

    return (
        <div className="history-container non-selectable">
            <div className="history-title">
                Scratch Balance History
            </div>
            {/* Pagination Controls */}
            <div className="history-pagination">
                <div
                    className="history-pagination-btn"
                    onClick={firstPage}
                >
                    {'<<'}
                </div>
                <div
                    className="history-pagination-btn"
                    onClick={prevPage}
                >
                    {'<'}
                </div>
                PAGE {currentPage} / {totalPages}
                <div
                    className="history-pagination-btn"
                    onClick={nextPage}
                >
                    {'>'}
                </div>
                <div
                    className="history-pagination-btn"
                    onClick={lastPage}
                >
                    {'>>'}
                </div>
            </div>
            <div className="history-table-container">
                <table className="history-table">
                    <thead className="history-grid-header">
                        <tr>
                            <th
                                onClick={sortTime}
                                className={isSelected(SortingMethod.TIME)}
                            >
                                Time {displaySortingArrow(SortingMethod.TIME, timeAscending)}
                            </th>
                            <th
                                onClick={sortNewBal}
                                className={isSelected(SortingMethod.NEW_BAL)}
                            >
                                Scratch Bal {displaySortingArrow(SortingMethod.NEW_BAL, newBalAscending)}
                            </th>
                            <th
                                onClick={sortChange}
                                className={isSelected(SortingMethod.CHANGE)}
                            >
                                Change {displaySortingArrow(SortingMethod.CHANGE, changeAscending)}
                            </th>
                            <th
                                onClick={sortType}
                                className={isSelected(SortingMethod.TYPE)}
                            >
                                Type {displaySortingArrow(SortingMethod.TYPE, typeAscending)}
                            </th>
                        </tr>
                    </thead>
                    {currentItems.map((log, index) => (
                        <tr key={index} className={getRowColor(log.type)}>
                            {/**Time */}
                            <td>{formatDate(log.timestamp)}</td>
                            {/**New Bal */}
                            <td>{weiToEthWithDecimals(log.newBalance, 2)}</td>
                            {/**Amount */}
                            <td>{formatBalanceChange(log.previousBalance, log.newBalance, log.amount)}</td>
                            {/**Type */}
                            <td >{stringDisplay(log.type)}</td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
}