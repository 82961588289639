import './tetrisGame.css'
import { TetrisBoard } from "./tetrisBoard/tetrisBoard"
import { TetrisMenu } from "./tetrisMenu/tetrisMenu";
import { TetrisScore } from "./tetrisScore/tetrisScore"
import { HighScore } from "./tetrisTypes";
import { TetrisUpcomingBlocks } from "./tetrisUpcomingBlocks/tetrisUpcomingBlocks"
import { ButtonHandler, TetrisControls } from './tetrisControls/tetrisControls';

type TetrisGameProps = {
    currentBoard: any;
    upcomingBlocks: number[];
    score: number;
    level: number;
    startGame: () => void;
    isPlaying: boolean;
    userHighScores: HighScore[];
    globalHighScores: HighScore[];
    loading: boolean;
    handleLeft: ButtonHandler;
    handleRight: ButtonHandler;
    handleDown: ButtonHandler;
    handleHardDrop: ButtonHandler;
    handleRotate: ButtonHandler;
    gameMode: 'PRACTICE' | 'PVP' | null;
    pvpTier: 'BRONZE' | 'SILVER' | 'GOLD' | null;
    playAmount: string;
    estimatedPlayCost: string;
}

const defaultBoard = Array.from(Array(20), () => new Array(10).fill(0));

export const TetrisGame: React.FC<TetrisGameProps> = ({
    currentBoard = defaultBoard,
    upcomingBlocks = [0, 0, 0],
    score = 0,
    level = 0,
    startGame,
    isPlaying,
    userHighScores,
    globalHighScores,
    loading,
    handleLeft,
    handleRight,
    handleDown,
    handleHardDrop,
    handleRotate,
    gameMode,
    pvpTier,
    playAmount,
    estimatedPlayCost,
}) => {

    return (
        <div className='tetris-container-outer'>
            <div className='tetris-container-inner'>
                <div className='tetirs-container-top'>
                    <TetrisBoard
                        currentBoard={currentBoard}
                    />
                    <div className='tetris-container-panel'>
                        <TetrisUpcomingBlocks upcomingBlocks={upcomingBlocks} />
                        <TetrisScore score={score} level={level} />
                    </div>
                </div>
                <TetrisControls
                    isPlaying={isPlaying}
                    handleLeft={handleLeft}
                    handleRight={handleRight}
                    handleDown={handleDown}
                    handleHardDrop={handleHardDrop}
                    handleRotate={handleRotate}
                />
                <TetrisMenu
                    startGame={startGame}
                    isPlaying={isPlaying}
                    score={score}
                    userHighScores={userHighScores}
                    globalHighScores={globalHighScores}
                    loading={loading}
                    gameMode={gameMode}
                    pvpTier={pvpTier}
                    playAmount={playAmount}
                    estimatedPlayCost={estimatedPlayCost}
                />
            </div>
        </div>
    )
}

