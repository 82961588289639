export const getTetroId = (piece: number) => {
    switch (piece) {
        case 0:
            return 'E';
        case 1:
            return 'I';
        case 2:
            return 'O';
        case 3:
            return 'T';
        case 4:
            return 'S';
        case 5:
            return 'Z';
        case 6:
            return 'J';
        case 7:
            return 'L';
        // case 11:
        //     return 'G I';
        // case 12:
        //     return 'G O';
        // case 13:
        //     return 'G T';
        // case 14:
        //     return 'G S';
        // case 15:
        //     return 'G Z';
        // case 16:
        //     return 'G J';
        // case 17:
        //     return 'G L';
        default:
            return 'E';
    }
}

export const getTetroShape = (piece: number) => {
    switch (piece) {
        case 0:
            return [
                [0],
                [0],
                [0],
                [0],
            ];
        case 1:
            return [
                [1],
                [1],
                [1],
                [1],
            ];
        case 2:
            return [
                [2, 2],
                [2, 2],
            ];
        case 3:
            return [
                [3, 0],
                [3, 3],
                [3, 0],
            ];
        case 4:
            return [
                [4, 0],
                [4, 4],
                [0, 4],
            ];
        case 5:
            return [
                [0, 5],
                [5, 5],
                [5, 0],
            ];
        case 6:
            return [
                [6, 6],
                [6, 0],
                [6, 0],
            ];
        case 7:
            return [
                [7, 0],
                [7, 0],
                [7, 7],
            ]
    }

}