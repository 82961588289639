import config from '../../config'

// Load config variables from ENV
const arcadeStakingContractAddress = config.ARCADE_STAKING_CONTRACT_ADDRESS;

export const stakeZoomers = async (web3: any, address: string, tokenIds: string[]) => {

    // Encode the tokenIds as an array
    const encodedTokenIds = web3.eth.abi.encodeParameter('uint256[]', tokenIds);

    // Construct the raw transaction data
    const txData = '0x0fbf0a93' + encodedTokenIds.substring(2);  // Remove the '0x' prefix from the encoded data

    try {
        const result = await web3.eth.sendTransaction({
            from: address,
            to: arcadeStakingContractAddress,
            data: txData
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const unstakeZoomers = async (web3: any, address: string, tokenIds: string[]) => {

    // Encode the tokenIds as an array
    const encodedTokenIds = web3.eth.abi.encodeParameter('uint256[]', tokenIds);

    // Construct the raw transaction data
    const txData = '0xe449f341' + encodedTokenIds.substring(2);  // Remove the '0x' prefix from the encoded data

    try {
        const result = await web3.eth.sendTransaction({
            from: address,
            to: arcadeStakingContractAddress,
            data: txData
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}