import React, { createContext, useState, useContext, ReactNode } from 'react';
import PageSpinner from '../components/spinners/pageSpinner';

interface PageLoadContextProps {
    isPageLoading: boolean;
    togglePageLoad: (isLoading: boolean) => void;
}

interface PageLoadProviderProps {
    children: ReactNode;
}

const PageLoadContext = createContext<PageLoadContextProps | undefined>(undefined);

export const usePageLoad = (): PageLoadContextProps => {
    const context = useContext(PageLoadContext);
    if (!context) {
        throw new Error("usePageLoad must be used within a PageLoadProvider");
    }
    return context;
};

export const PageLoadProvider: React.FC<PageLoadProviderProps> = ({ children }) => {
    const [isPageLoading, setPageLoading] = useState(false);

    const togglePageLoad = (isLoading: boolean) => {
        setPageLoading(isLoading);
    };

    return (
        <PageLoadContext.Provider value={{ isPageLoading, togglePageLoad }}>
            {/* Conditionally render PageSpinner if isPageLoading is true */}
            {isPageLoading && <PageSpinner />}
            
            {children}
        </PageLoadContext.Provider>
    );
};
