import config from "../config";
import { UserPassRedeemInfo } from "../nav/arcade/passRedeem/passRedeem";
import arcadeApi from "../requests/arcadeApi";
import boomerApi from "../requests/boomerApi";
import { DepositWithdrawHistoryLog } from "../types/apiResponse";

const ZOOMER_CONTRACT_ADDRESS = '0x3D03292D428fd7d6C81a6E76b4A333A3d8a0E296';
const ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS = config.ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS

export const getArcadeBalance = async (): Promise<[string, string, string, string[], string]> => {
    const result = await arcadeApi.getUserBalance()
    return [
        result.balanceResponse.arcadeBalance,
        result.balanceResponse.farmShareBalance,
        result.balanceResponse.zoomerBalance.balance,
        result.balanceResponse.zoomerBalance.tokenIds,
        result.balanceResponse.pfpBalance
    ]
}

/**TODO: Make a new endpoint in API for only getting the SCRATCH Balance */
export const getArcadeScratchBalance = async (): Promise<string> => {
    const result = await arcadeApi.getUserBalance()
    return result.balanceResponse.arcadeBalance
}

export const getBoomRoomStakingStats = async (userAddress: string): Promise<string> => {
    const result = await boomerApi.getBoomRoomStakingStatsV2(userAddress)
    return result.userScoreByPoolType[0]
}

export const getArcadeBalanceHistory = async (): Promise<any[]> => {
    const data = await arcadeApi.getBalanceHistory();
    return data.arcadeBalanceHistory;
}

export const getWalletZoomers = async (address: string): Promise<string[]> => {
    const walletZoomers = await boomerApi.getWalletTokenIds(address, ZOOMER_CONTRACT_ADDRESS)
    return walletZoomers
}

export const getWalletS1PassTokenIds = async (address: string): Promise<string[]> => {
    const walletPassTokenIds = await boomerApi.getWalletTokenIds(address, ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS)
    return walletPassTokenIds
}

export const getArcadeDepositWithdrawHistory = async (): Promise<DepositWithdrawHistoryLog[]> => {
    const data = await arcadeApi.getUserDepositWithdrawHistory();
    return data?.depositWithdrawHistory || [];
}

export const getArcadePassRedeemInfo = async (): Promise<UserPassRedeemInfo> => {
    const data = await arcadeApi.getPassRedeemInfo();
    return data;
}