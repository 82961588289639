import React from 'react';
import './tetrisControls.css';

export type ButtonHandler = {
    up: () => void;
    down: () => void;
}

type TetrisControlsProps = {
    isPlaying: boolean;
    handleLeft: ButtonHandler;
    handleRight: ButtonHandler;
    handleDown: ButtonHandler;
    handleHardDrop: ButtonHandler;
    handleRotate: ButtonHandler;
}

export const TetrisControls: React.FC<TetrisControlsProps> = ({
    isPlaying,
    handleLeft,
    handleRight,
    handleDown,
    handleHardDrop,
    handleRotate,
}) => {
    const buttonClass = `button button-primary medium tetrisControls-button ${isPlaying ? '' : 'disabled'}`;

    return (
        <div className="tetrisControls-container">
            <div className="tetrisControls-row">
                <TetrisControlButton handleAction={handleLeft} symbol="←" buttonClass={buttonClass} isPlaying={isPlaying} />
                <TetrisControlButton handleAction={handleRotate} symbol="↻" buttonClass={buttonClass} isPlaying={isPlaying} />
                <TetrisControlButton handleAction={handleRight} symbol="→" buttonClass={buttonClass} isPlaying={isPlaying} />
            </div>
            <div className="tetrisControls-row">
                <TetrisControlButton handleAction={handleDown} symbol="↓" buttonClass={buttonClass} isPlaying={isPlaying} />
                <TetrisControlButton handleAction={handleHardDrop} symbol="⇩" buttonClass={buttonClass} isPlaying={isPlaying} />
            </div>
        </div>
    );
};

type TetrisControlButtonProps = {
    handleAction: ButtonHandler;
    symbol: string;
    buttonClass: string;
    isPlaying: boolean;
}

const TetrisControlButton: React.FC<TetrisControlButtonProps> = ({ handleAction, symbol, buttonClass, isPlaying }) => {

    const onTouchStart = (e: React.TouchEvent) => {
        console.log('onTouchStart', symbol)
        e.preventDefault(); // Prevents additional mouse events being fired by touch
        handleAction.down();
    };

    const onTouchEnd = (e: React.TouchEvent) => {
        console.log('onTouchEnd', symbol)
        e.preventDefault(); // Prevents additional mouse events being fired by touch
        handleAction.up();
    };

    const onMouseDown = (e: React.MouseEvent) => {
        console.log('onMouseDown', symbol)
        e.preventDefault(); // Optional: Prevent text selection on double-click, etc.
        handleAction.down();
    };

    const onMouseUp = (e: React.MouseEvent) => {
        console.log('onMouseUp', symbol)
        e.preventDefault(); // Optional: Prevent text selection on double-click, etc.
        handleAction.up();
    };

    return (
        <button
            className={buttonClass}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp} // Handles case where mouse leaves button while pressed
            disabled={!isPlaying} // Disables button based on the class
        >
            {symbol}
        </button>
    );
};
