import React, { createContext, useContext, useState, useCallback, useEffect, ReactNode } from 'react';
import { getArcadeBalance } from '../utils/reqUtils';
import { useIsLoggedIn } from './isLoggedInContext';

// Define the shape of your context's value
interface ArcadeBalanceContextType {
    arcadeScratchBalance: string;
    arcadeFarmShareBalance: string;
    arcadeZoomerBalance: string;
    arcadePfpBalance: string;
    refreshBalance: () => Promise<void>;
}

interface ArcadeBalanceProviderProps {
    children: ReactNode;
}

// Provide a default value matching the shape
const defaultValue: ArcadeBalanceContextType = {
    arcadeScratchBalance: '0',
    arcadeFarmShareBalance: '0',
    arcadeZoomerBalance: '0',
    arcadePfpBalance: '0',
    refreshBalance: async () => { }, // A no-op function as a placeholder
};

const ArcadeBalanceContext = createContext<ArcadeBalanceContextType>(defaultValue);

export const useArcadeBalance = () => useContext(ArcadeBalanceContext);

export const ArcadeBalanceProvider: React.FC<ArcadeBalanceProviderProps> = ({ children }) => {

    // Hooks
    const { isLoggedIn } = useIsLoggedIn();

    const [arcadeScratchBalance, setArcadeScratchBalance] = useState<string>('0');
    const [arcadeFarmShareBalance, setArcadeFarmShareBalance] = useState<string>('0');
    const [arcadeZoomerBalance, setArcadeZoomerBalance] = useState<string>('0');
    const [arcadePfpBalance, setArcadePfpBalance] = useState<string>('0');

    const refreshBalance = useCallback(async () => {
        if (isLoggedIn) {
            const balance = await getArcadeBalance();
            setArcadeScratchBalance(balance[0]);
            setArcadeFarmShareBalance(balance[1]);
            setArcadeZoomerBalance(balance[2]);
            setArcadePfpBalance(balance[4]);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        refreshBalance();
    }, [refreshBalance]);

    return (
        <ArcadeBalanceContext.Provider value={
            {
                arcadeScratchBalance,
                arcadeFarmShareBalance,
                arcadeZoomerBalance,
                arcadePfpBalance,
                refreshBalance,
            }
        }>
            {children}
        </ArcadeBalanceContext.Provider>
    );
};
