import React from "react";

type DynamicIconProps = {
    name: string;
    width: number;
    color?: string;
}

export const DynamicIcon: React.FC<DynamicIconProps> = ({ name, width, color = null }) => {

    const getSrc = (name: string) => {
        switch (name) {
            case 'SCRATCH':
                return 'https://boomersquad-web-images.s3.amazonaws.com/boomrooms/SCRATCH.svg';
            case 'ZOOMER':
                return 'https://boomersquad-web-images.s3.amazonaws.com/navbar/zoomerLogo.png';
            case 'FS':
                return 'FS_ICON.png';
            case 'PFP':
                return 'PFP_ICON.png';
            case 'PG':
                return 'PG_ICON.png';
            case 'ZTB':
                return 'ZTB_ICON.png';
            case 'CRO':
                return 'https://s3.coinmarketcap.com/static-gravity/image/84e1ec1257dd4a6da6ee5584338a2460.jpeg';
            case 'OPEN_IN_NEW':
                return 'icon_open_in_new.svg';
            case 'UNDO':
                return 'undo.svg';
            case 'LOGIN':
                return 'login.svg';
            case 'LOGOUT':
                return 'logout.svg';
            case 'START':
                return 'start.svg';
            case 'REFRESH':
                return 'refresh.svg';
            case 'BOOMROOM':
                return 'https://boomersquad-web-images.s3.amazonaws.com/navbar/boomroomLogo.png';
            default:
                return '';
        }
    }

    const style = {
        width: `${width}px`,
        height: `${width}px`,
        borderRadius: '50%',
        flexGrow: 'none',
        flexShrink: 'none',
    }

    if (color) {
        style['backgroundColor'] = color;
    }

    return (
        <img
            src={getSrc(name)}
            style={style}
            alt={name}
        />

    )
}