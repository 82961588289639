import { Web3 } from 'web3';
import arcadeApi from "../requests/arcadeApi";

// This function will be called when the user tries to enter the "Arcade" section of the app
const signMessage = async (web3: Web3, userAddress: string) => {
    try {
        // 1. Create a message for the user to sign
        // You can also add a nonce or a timestamp for added security
        const message = `Please sign this message to verify your ownership of the address: ${userAddress}`;

        // 2. Request signature
        const signature = await web3.eth.personal.sign(message, userAddress, ''); // MetaMask will ignore the 3rd param here (passphrase)

        // 3. Send these details to your backend
        const data = await arcadeApi.authenticateUser(userAddress, message, signature);

        return data

    } catch (error) {
        console.error("Error signing the message:", error);
    }
};

export default signMessage;
