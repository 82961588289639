import React, { useEffect } from 'react';
import './myAccount.css';
import { useWeb3 } from '../../../context/web3Context';
import { usePageLoad } from '../../../context/pageLoadContext';
import { useState } from 'react';
// import config from '../../../config';
import { useNavigate } from 'react-router-dom'; // Import useNavigation
import { BalanceHistory, BalanceLog } from '../../../components/history/balance/balanceHistory';
import { AccountBalance } from '../../../components/accountBalance/accountBalance';
import { getArcadeBalance, getArcadeBalanceHistory, getArcadeDepositWithdrawHistory, getBoomRoomStakingStats, getWalletZoomers } from '../../../utils/reqUtils';
import { DepositWithdrawHistoryLog } from '../../../types/apiResponse';
import { DepositWithdrawHistory } from '../../../components/history/depositWithdraw/depositWithdrawHistory';
import { DepositModal } from '../../../components/modals/depositModal/depositModal';
import { WithdrawModal } from '../../../components/modals/withdrawModal/withdrawModal';

export const MyAccount: React.FC = () => {

    // Context imports
    const { web3, address, ethBalance, scratchBalance } = useWeb3();
    const { togglePageLoad } = usePageLoad();

    // Navigation
    const navigate = useNavigate(); // Destructure navigate function

    // Replace these with actual logic to get Scratch and Scratch Token balances
    const [arcadeScratchBalance, setArcadeScratchBalance] = useState<string | null>(null);
    const [arcadeZoomerBalance, setArcadeZoomerBalance] = useState<string | null>(null);
    const [cronosZoomerBalance, setCronosZoomerBalance] = useState<string | null>(null); // [ '1', '2', '3'
    const [cronosBoomRoomStakingPoints, setCronosBoomRoomStakingPoints] = useState<string | null>(null); // [ '1', '2', '3'
    // const [isCopied, setIsCopied] = useState<boolean>(false);
    const [arcadeFarmShareBalance, setArcadeFarmShareBalance] = useState<string | null>(null);
    const [arcadePermaFarmPointsBalance, setArcadePermaFarmPointsBalance] = useState<string | null>(null);
    const [arcadeBalanceHistory, setArcadeBalanceHistory] = useState<BalanceLog[]>([]);
    const [depositWithdrawHistory, setDepositWithdrawHistory] = useState<DepositWithdrawHistoryLog[]>([]);
    const [depositModalOpen, setDepositModalOpen] = useState<boolean>(false);
    const [withdrawModalOpen, setWithdrawModalOpen] = useState<boolean>(false);

    const loadData = async () => {
        togglePageLoad(true);
        if (web3 && address) {
            try {
                const [
                    [
                        arcadeScratchBalance,
                        arcadeFarmShareBalance,
                        arcadeZoomerBalance,
                        arcadeZoomerTokenIds,  // NOT NEEDED HERE
                        arcadePermaFarmPointsBalance
                    ],
                    walletZoomerTokenIds,
                    arcadeBalanceHistory,
                    depositWithdrawHistory,
                    boomroomStakingStats
                ] = await Promise.all([
                    getArcadeBalance(),
                    getWalletZoomers(address),
                    getArcadeBalanceHistory(),
                    getArcadeDepositWithdrawHistory(),
                    getBoomRoomStakingStats(address),
                ])

                // Set states
                setArcadeScratchBalance(arcadeScratchBalance);
                setArcadeFarmShareBalance(arcadeFarmShareBalance);
                setArcadeZoomerBalance(arcadeZoomerBalance);
                setCronosZoomerBalance(walletZoomerTokenIds.length.toString());
                setArcadeBalanceHistory(arcadeBalanceHistory);
                setDepositWithdrawHistory(depositWithdrawHistory);
                setArcadePermaFarmPointsBalance(arcadePermaFarmPointsBalance);
                setCronosBoomRoomStakingPoints(boomroomStakingStats);

                // Stop the loading spinner once all succeed
                togglePageLoad(false);
            } catch (err) {
                // Send user back to home page if there's an error
                togglePageLoad(false);
                navigate("/");
            }
        }
        togglePageLoad(false);
    }

    // get arcade and web3 data on page load
    useEffect(() => {
        togglePageLoad(true);
        loadData();
    }, [address]);

    const disclaimer =
        <div className='disclaimer'>
            Disclaimer:
            Withdraws and deposits may take longer than normal due high chain demands. If you have any issues with your withdraw/deposit, please open a ticket on discord.
        </div>

    return (
        <div className="page-myAccount">
            <img className={'tetris-titleImage'} src={'titleCards/account.png'} alt='' />
            <AccountBalance
                cronosCroBalance={ethBalance || 'N/A'}
                cronosScratchBalance={scratchBalance || 'N/A'}
                cronosZoomerBalance={cronosZoomerBalance || 'N/A'}
                cronosBoomRoomStakingPoints={cronosBoomRoomStakingPoints || 'N/A'}
                arcadeScratchBalance={arcadeScratchBalance || 'N/A'}
                arcadeZoomerBalance={arcadeZoomerBalance || 'N/A'}
                arcadeFarmShareBalance={arcadeFarmShareBalance || 'N/A'}
                arcadePermaFarmPointsBalance={arcadePermaFarmPointsBalance || 'N/A'}
                setDepositModalOpen={setDepositModalOpen}
                setWithdrawModalOpen={setWithdrawModalOpen}
            />
            <DepositWithdrawHistory history={depositWithdrawHistory} setSortedHistory={setDepositWithdrawHistory} />
            <BalanceHistory history={arcadeBalanceHistory} setSortedHistory={setArcadeBalanceHistory} />
            <DepositModal
                isOpen={depositModalOpen}
                setModalOpen={setDepositModalOpen}
                web3={web3}
                address={address || ''}
                scratchBalance={scratchBalance || '0'}
                togglePageLoad={togglePageLoad}
            />
            <WithdrawModal
                isOpen={withdrawModalOpen}
                setModalOpen={setWithdrawModalOpen}
                scratchBalance={arcadeScratchBalance || '0'}
                togglePageLoad={togglePageLoad}
            />
        </div>
    );
};
