import React, { useState } from 'react';
import Roulette from '../../../../components/games/roulette/roulette';
import { PayloadRouletteBets, RouletteBets } from './rouletteTypes';
import { RouletteBetting } from '../../../../components/games/roulette/rouletteBetting';
import { useEffect } from 'react';
import arcadeApi from '../../../../requests/arcadeApi';
import Web3 from 'web3';
import { weiToEthWithDecimals } from '../../../../web3/utils';
import { usePageLoad } from '../../../../context/pageLoadContext';
import './roulettePage.css';
import { generateSeed } from '../../../../utils/seedUtils';
import GameHistory from '../../../../components/history/game/gameHistory';
import { GameInfo, InfoBar } from '../../../../components/games/infoBar/infoBar';
import { getGameFee } from '../../../../utils/gameUtils';

export const RoulettePage: React.FC = () => {

    const { togglePageLoad } = usePageLoad();

    const [spinResult, setSpinResult] = useState<number | null>(null);
    const [visibleSpinResult, setVisibleSpinResult] = useState<number | null>(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [bets, setBets] = useState<RouletteBets>([]);
    const [serverSeedHash, setServerSeedHash] = useState('');
    const [clientSeed, setClientSeed] = useState(generateSeed());
    const [previousRoundWin, setPreviousRoundWin] = useState(0);
    const [previousRoundBet, setPreviousRoundBet] = useState(0);
    const [visiblePreviousRoundWin, setVisiblePreviousRoundWin] = useState(0);
    const [visiblePreviousRoundBet, setVisiblePreviousRoundBet] = useState(0);
    const [arcadeScratchBalance, setArcadeScratchBalance] = useState<string | null>(null);
    const [arcadeZoomerBalance, setArcadeZoomerBalance] = useState<string | null>(null);
    const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
    const [rouletteHistory, setRouletteHistory] = useState<any[]>([]); // TODO: Add type for roulette history
    const [latestWinAmounts, setLatestWinAmounts] = useState<string[]>([]);

    const spin = async () => {
        setSpinResult(null);
        setIsSpinning(true);

        // prepate bets for sendoff, every number is in wei in the backend, so must convert before sending request
        const betsToSend: PayloadRouletteBets = bets.map((bet) => {
            return {
                ...bet,
                amount: Web3.utils.toWei(bet.amount.toString(), 'ether') // Convert to wei
            }
        })

        arcadeApi.playRoulette(betsToSend, clientSeed)
            .then((response) => {
                // Update client seed
                setSpinResult(response.playRouletteRes.spinResult);
                setPreviousRoundWin(parseInt(weiToEthWithDecimals(response.playRouletteRes.totalWinAmount, 0)));
                setPreviousRoundBet(parseInt(weiToEthWithDecimals(response.playRouletteRes.totalBetAmount, 0)));
                setServerSeedHash(response.newSeedHash);

                // Add to history prep
                setLatestWinAmounts(response.playRouletteRes.winAmounts);
            })
    }

    // Allowed to press betting game buttons
    const canInteract = (): boolean => {
        return !isSpinning;
    }

    // Allowed to press spin button
    const canSpin = (): boolean => {
        return !isSpinning && bets.length > 0;
    }

    useEffect(() => {
        // Update history
        const newHistory = [...rouletteHistory];
        newHistory.unshift({
            timestamp: new Date().toISOString(),
            gameInfo: {
                bets: bets.map((bet) => {
                    return {
                        type: bet.type,
                        value: bet.value,
                        amount: Web3.utils.toWei(bet.amount.toString(), 'ether') // Convert to wei
                    }
                }),
                winAmounts: latestWinAmounts,
                spinResult: spinResult
            }
        })
        setRouletteHistory(newHistory);
        setVisiblePreviousRoundWin(previousRoundWin);
        setVisiblePreviousRoundBet(previousRoundBet);
        getArcadeBalance();

    }, [triggerUpdate])

    const getArcadeBalance = () => {
        return arcadeApi.getUserBalance().then((data) => {
            console.log('data', data);
            if (
                data &&
                data.balanceResponse &&
                data.balanceResponse.arcadeBalance &&
                data.balanceResponse.zoomerBalance &&
                data.balanceResponse.zoomerBalance.balance
            ) {
                setArcadeScratchBalance(data.balanceResponse.arcadeBalance);
                setArcadeZoomerBalance(data.balanceResponse.zoomerBalance.balance);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        togglePageLoad(true);
        // Define an async function to use await inside
        const initializeGameSessionAndBalance = async () => {
            try {
                // Create an array of promises
                const gameName = 'ROULETTE';

                const promises = [
                    arcadeApi.startGameSession(gameName),
                    arcadeApi.getUserBalance(),
                    arcadeApi.getGameHistory(gameName),
                ];

                // Use Promise.all to wait for all promises to resolve
                const [startGameSessionResponse, userBalanceResponse, gameHistoryResponse] = await Promise.all(promises);

                // Log the start game session response and set the server seed hash
                console.log(startGameSessionResponse);
                console.log(gameHistoryResponse)
                console.log(gameHistoryResponse.gameHistoryRes.gameHistory.length)

                // Check for balance response and set arcade scratch balance
                if (
                    userBalanceResponse.balanceResponse.arcadeBalance &&
                    startGameSessionResponse.createSessionRes.seedHash &&
                    gameHistoryResponse.gameHistoryRes.gameHistory
                ) {
                    setArcadeScratchBalance(userBalanceResponse.balanceResponse.arcadeBalance);
                    setServerSeedHash(startGameSessionResponse.createSessionRes.seedHash);
                    setRouletteHistory(gameHistoryResponse.gameHistoryRes.gameHistory);
                }
            } catch (err) {
                // Catch any errors from either of the promises
                console.error(err);
            }
            togglePageLoad(false);
        };

        // Execute the async function
        initializeGameSessionAndBalance();
    }, []); // Empty dependency array ensures this effect runs once on mount

    const gameInfo: GameInfo[] = [
        {
            infoName: 'SCRATCH BALANCE',
            infoValue: `${weiToEthWithDecimals(arcadeScratchBalance || '0', 2)}`
        },
        {
            infoName: 'STAKED ZOOMERS',
            infoValue: `${arcadeZoomerBalance}`
        },
        {
            infoName: 'PLAY COST',
            infoValue: getGameFee(arcadeZoomerBalance || '0') + '%'
        },
    ]


    return (
        <div className='page-roulettePage'>
            <InfoBar gameInfo={gameInfo} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img className={'tetris-titleImage'} src={'titleCards/roulette.png'} alt='' />
            </div>
            <div className='roulettePage-game-container'>
                <Roulette
                    onSpin={spin}
                    isSpinning={isSpinning}
                    setIsSpinning={setIsSpinning}
                    spinResult={spinResult}
                    setVisibleSpinResult={setVisibleSpinResult}
                    canSpin={canSpin}
                    setTriggerUpdate={setTriggerUpdate}
                />
                <RouletteBetting
                    visibleSpinResult={visibleSpinResult}
                    bets={bets} setBets={setBets}
                    previousRoundWin={visiblePreviousRoundWin}
                    previousRoundBet={visiblePreviousRoundBet}
                    arcadeScratchBalance={arcadeScratchBalance}
                    canInteract={canInteract}
                    triggerUpdate={triggerUpdate}
                    fee={getGameFee(arcadeZoomerBalance || '0')}
                />
            </div>
            {
                rouletteHistory.length > 0 &&
                <GameHistory gameName={'ROULETTE'} data={rouletteHistory} />
            }

        </div>

    )
}

