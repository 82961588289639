import './connectButton.css';

const ConnectButton = ({ onClick }) => {
  return (
    <button className="connect-button button-primary" onClick={onClick}>
      Connect
    </button>
  );
};

export default ConnectButton;
