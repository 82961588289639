import React, { useState } from 'react';
import { handleInputChange } from '../../../utils/inputUtils';
import { isBiggerThan, isBiggerThanOrEqualTo } from '../../../utils/mathUtils';
import { weiToEthWithDecimals } from '../../../web3/utils';
import { DynamicIcon } from '../../dynamicIcon/dynamicIcon';
import arcadeApi from '../../../requests/arcadeApi';

type WithdrawModalProps = {
    isOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scratchBalance: string;
    togglePageLoad: (isLoading: boolean) => void;
}

export const WithdrawModal: React.FC<WithdrawModalProps> = ({ isOpen, setModalOpen, scratchBalance, togglePageLoad }) => {

    const handleClose = () => {
        setModalOpen(false);
    }

    const handleModalContentClick = (event: React.MouseEvent) => {
        // Prevents the click from propagating to the parent element
        event.stopPropagation();
    };

    const [withdrawAmountDisplay, setWithdrawAmountDisplay] = useState<string>('0');
    const [withdrawAmountWei, setWithdrawAmountWei] = useState<string>('0');

    const checkCanWithdraw = () => {
        // Check if scratch balance is greater than withdraw amount 
        return isBiggerThanOrEqualTo(scratchBalance, withdrawAmountWei) && isBiggerThan(withdrawAmountWei, '0');
    }

    const handleWithdrawClick = async () => {
        if (!checkCanWithdraw()) {
            console.error('Cannot deposit more than scratch balance');
            return;
        }
        togglePageLoad(true);
        try {
            await arcadeApi.withdrawScratch(withdrawAmountWei);
            await new Promise(r => setTimeout(r, 4000));
            window.location.reload();
        } catch (err) {
            togglePageLoad(false);
        }
    }

    return (
        <div className={`deposit-modal ${isOpen ? 'open' : ''}`} onClick={handleClose}>
            <div className="deposit-modal-content" onClick={handleModalContentClick}>
                <div className='deposit-modal-title'>Scratch Withdraw</div>
                <div className='disclaimer'>
                    Withdraws take a few minutes to process.
                    <span style={{ color: 'var(--highlight-text)' }}> You may only withdraw ONCE every 12 hours. </span>
                    You can keep track of your withdraw status in the
                    <span style={{ color: 'var(--highlight-text)' }}> Deposit / Withdraw History </span>
                    section. Contact us on Discord if you have any issues.
                </div>
                <div className='deposit-modal-row'>
                    MAX - <DynamicIcon name='SCRATCH' width={25} /> {weiToEthWithDecimals(scratchBalance, 2)}
                </div>
                <input
                    className='trade-shares-card-input'
                    type='text'
                    value={withdrawAmountDisplay !== '0' ? withdrawAmountDisplay : ''}
                    placeholder='Enter Scratch Amount'
                    onChange={
                        (event) => handleInputChange(event, setWithdrawAmountDisplay, setWithdrawAmountWei)
                    }
                />
                <div className='deposit-modal-content-buttons'>
                    <div
                        className={`button-primary button medium deposit-modal-content-button ${checkCanWithdraw() ? '' : 'disabled'}`}
                        onClick={checkCanWithdraw() ? handleWithdrawClick : () => null}>
                        Withdraw
                    </div>
                    <div
                        className='button-cancel button medium deposit-modal-content-button'
                        onClick={handleClose}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>
    );
}