// Roulette.tsx
import React, { useState, useEffect } from 'react';
import { squaresWithDegrees } from './rouletteSquares';
import './roulette.css';
import { useArcadeBalance } from '../../../context/arcadeBalanceContext';

type RouletteProps = {
    onSpin: () => void,
    isSpinning: boolean,
    setIsSpinning: React.Dispatch<React.SetStateAction<boolean>>,
    spinResult: number | null,
    setVisibleSpinResult: React.Dispatch<React.SetStateAction<number | null>>,
    canSpin: () => boolean,
    setTriggerUpdate: React.Dispatch<React.SetStateAction<boolean>>,
}

const Roulette: React.FC<RouletteProps> = ({ onSpin, isSpinning, setIsSpinning, spinResult, setVisibleSpinResult, canSpin, setTriggerUpdate }) => {

    const SPIN_STYLE_ID = 'roulette-spin-style';

    const [isSpinPressed, setIsSpinPressed] = useState<boolean>(false);
    const { refreshBalance } = useArcadeBalance();

    // Spin the wheel a full circle
    const spinWheel = () => {
        const keyframes = `
        @keyframes initialSpin {
          from { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }`;
        // Apply the keyframes to the element
        document.getElementById('roulette-wheel')!.style.animation = 'initialSpin 750ms linear infinite';

        // Create and append the stylesheet if it doesn't exist
        let styleSheet = document.getElementById(SPIN_STYLE_ID) as HTMLStyleElement;
        if (!styleSheet) {
            styleSheet = document.createElement('style');
            styleSheet.id = SPIN_STYLE_ID;
            document.head.appendChild(styleSheet);
        }

        // Set the initial spin animation
        styleSheet.textContent = keyframes;
    }

    const spinWheelToNumber = (spinResult: number) => {
        const rouletteWheel = document.getElementById('roulette-wheel');
        if (!rouletteWheel) return;
        // get angle of the square that matches the spinResult and do math to get the degrees to spin
        const squareDegrees = squaresWithDegrees.find(square => square.number === spinResult)?.degrees;
        const num = Math.round(squareDegrees! / (360 / squaresWithDegrees.length));
        const moreSpins = 3;
        const overshootDegrees = `calc(((360deg * ${moreSpins}) - ((360deg / ${squaresWithDegrees.length}) * ${num})) + 3deg)`
        const undershootDegrees = `calc(((360deg * ${moreSpins}) - ((360deg / ${squaresWithDegrees.length}) * ${num})) - 2deg)`
        const finalDegrees = `calc((360deg * ${moreSpins}) - ((360deg / ${squaresWithDegrees.length}) * ${num}))`
        // Create the keyframes
        const keyframes =
            `@keyframes spinToResult {
            from { transform: rotate(0deg); }
            96% { transform: rotate(${overshootDegrees}); }
            98% { transform: rotate(${undershootDegrees}); }
            100% { transform: rotate(${finalDegrees}); }}`
        // Stop the current animation by clearing the style
        rouletteWheel.style.animation = '';

        // Access the existing stylesheet and update it
        document.getElementById(SPIN_STYLE_ID)!.textContent = keyframes;

        // Add animationend event listener to set isSpinning to false once the animation finishes
        rouletteWheel.addEventListener('animationend', (event) => {
            setIsSpinning(false);
            setVisibleSpinResult(spinResult);
            setTriggerUpdate(t => !t);
            refreshBalance();
        }, { once: true }); // The 'once' option auto-removes the listener after it fires


        // Apply the keyframes to the element
        rouletteWheel.style.animation = 'spinToResult 4s ease-out forwards'; // Adjust timing as needed
    };

    useEffect(() => {
        if (isSpinning) {
            cleanUpStyles();
            spinWheel();
        } else {
            // cleanUpStyles();
        }
    }, [isSpinning])

    useEffect(() => {
        const rouletteWheel = document.getElementById('roulette-wheel');
        if (!rouletteWheel) return;

        const handleAnimationIteration = () => {
            if (spinResult !== null) {
                // Remove the event listener since we no longer need it
                rouletteWheel.removeEventListener('animationiteration', handleAnimationIteration);
                // Stop the infinite animation by removing the class
                // rouletteWheel.classList.remove('spinning');
                spinWheelToNumber(spinResult);

            }
        };

        rouletteWheel.addEventListener('animationiteration', handleAnimationIteration);

        // Remove the event listener when the component unmounts or when spinResult changes
        return () => {
            rouletteWheel.removeEventListener('animationiteration', handleAnimationIteration);
        };
    }, [spinResult]); // Dependencies array - this effect will re-run when spinResult changes

    function cleanUpStyles() {
        const existingStyle = document.getElementById(SPIN_STYLE_ID);
        if (existingStyle) {
            existingStyle.remove();
        }
    }

    const handleSpinClick = () => {
        if (canSpin()) {
            setIsSpinPressed(true); // Set the button to the pressed state
            onSpin(); // Call the onSpin function passed as a prop
        }
    };

    // You should have a way to call this function when the condition to unpress the button is met
    const releaseButton = () => {
        setIsSpinPressed(false); // Revert the button to the unpressed state
    };

    // You'll need to call releaseButton at the right time in your application's logic
    // For example, if you need to wait for some data to be fetched or a certain time to pass,
    // that logic would go here. You can also call releaseButton in a useEffect hook if you need to.

    return (
        <div className="roulette-container">
            <img id='roulette-wheel' className="roulette-wheel-img" src="/roulette_wheel3.png" />
            <div className='roulette-wheel-brown-bg1 wooden-texture' />
            <div className='roulette-wheel-brown-bg2 wooden-texture' />
            {/* <img className='roulette-middle-img' src='/BackBSWhite3.png' /> */}
            <div className="roulette-arrow"></div>
            <div className='roulette-spin-btn-container'>
                <div
                    className={`roulette-spin-btn deep-button ${canSpin() ? '' : 'disabled pressed'}`}
                    onClick={handleSpinClick}
                >
                    PLAY
                    {/* <img className='roulette-middle-img2' src='/BackBSWhite3.png' /> */}
                    <div className='button-3d-effect' />
                </div>
            </div>

        </div>
    );
};

export default Roulette;
