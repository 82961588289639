import config from '../../config'

const scratchAddress = '0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66'
const depositAddress = config.DEPOSIT_ADDRESS

// // Request the user to deposit scratch tokens to the deposit wallet address
export const depositScratch = async (web3: any, account: string, weiAmount: string) => {

    // The ERC-20 `transfer` function signature. 
    const data = web3.eth.abi.encodeFunctionCall({
        name: 'transfer',
        type: 'function',
        inputs: [{
            type: 'address',
            name: 'recipient'
        }, {
            type: 'uint256',
            name: 'amount'
        }],
    }, [depositAddress, weiAmount]);

    // Prompt the user to send the transaction
    try {
        const txReceipt = await web3.eth.sendTransaction({
            from: account,
            to: scratchAddress,
            data: data,
            value: '0x0' // No Ether is being sent, only tokens
        });

        return txReceipt;
    } catch (error) {
        console.error("Failed to send scratch tokens:", error);
        throw error;
    }

}

// Get the user's web3 scratch balance
export const getScratchBalance = async (web3: any, account: string): Promise<string | null> => {

    // Encode the function parameters. 
    // Assuming the `balanceOf` function takes a single address parameter, which is the account address.
    const encodedParams = web3.eth.abi.encodeParameters(['address'], [account]);

    // Construct the raw transaction data.
    const txData = '0x70a08231' + encodedParams.substring(2);  // Remove the '0x' prefix

    try {
        const result = await web3.eth.call({
            from: account,
            to: scratchAddress,
            data: txData
        });

        return web3.utils.hexToNumberString(result);
    } catch (error) {
        console.error(error);
        return null
    }
}
