export enum BlackjackCardName {
    ACE_OF_SPADES = 'AS',
    ACE_OF_HEARTS = 'AH',
    ACE_OF_CLUBS = 'AC',
    ACE_OF_DIAMONDS = 'AD',
    TWO_OF_SPADES = '2S',
    TWO_OF_HEARTS = '2H',
    TWO_OF_CLUBS = '2C',
    TWO_OF_DIAMONDS = '2D',
    THREE_OF_SPADES = '3S',
    THREE_OF_HEARTS = '3H',
    THREE_OF_CLUBS = '3C',
    THREE_OF_DIAMONDS = '3D',
    FOUR_OF_SPADES = '4S',
    FOUR_OF_HEARTS = '4H',
    FOUR_OF_CLUBS = '4C',
    FOUR_OF_DIAMONDS = '4D',
    FIVE_OF_SPADES = '5S',
    FIVE_OF_HEARTS = '5H',
    FIVE_OF_CLUBS = '5C',
    FIVE_OF_DIAMONDS = '5D',
    SIX_OF_SPADES = '6S',
    SIX_OF_HEARTS = '6H',
    SIX_OF_CLUBS = '6C',
    SIX_OF_DIAMONDS = '6D',
    SEVEN_OF_SPADES = '7S',
    SEVEN_OF_HEARTS = '7H',
    SEVEN_OF_CLUBS = '7C',
    SEVEN_OF_DIAMONDS = '7D',
    EIGHT_OF_SPADES = '8S',
    EIGHT_OF_HEARTS = '8H',
    EIGHT_OF_CLUBS = '8C',
    EIGHT_OF_DIAMONDS = '8D',
    NINE_OF_SPADES = '9S',
    NINE_OF_HEARTS = '9H',
    NINE_OF_CLUBS = '9C',
    NINE_OF_DIAMONDS = '9D',
    TEN_OF_SPADES = '10S',
    TEN_OF_HEARTS = '10H',
    TEN_OF_CLUBS = '10C',
    TEN_OF_DIAMONDS = '10D',
    JACK_OF_SPADES = 'JS',
    JACK_OF_HEARTS = 'JH',
    JACK_OF_CLUBS = 'JC',
    JACK_OF_DIAMONDS = 'JD',
    QUEEN_OF_SPADES = 'QS',
    QUEEN_OF_HEARTS = 'QH',
    QUEEN_OF_CLUBS = 'QC',
    QUEEN_OF_DIAMONDS = 'QD',
    KING_OF_SPADES = 'KS',
    KING_OF_HEARTS = 'KH',
    KING_OF_CLUBS = 'KC',
    KING_OF_DIAMONDS = 'KD',
}

export enum BlackjackCardValue {
    ACE_ONE = 1 as number,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
    NINE = 9,
    TEN = 10,
    JACK = 10,
    QUEEN = 10,
    KING = 10,
    ACE_ELEVEN = 11
}

export type BlackjackCard = {
    name: BlackjackCardName;
    value: BlackjackCardValue;
}

export type BlackjackHand = {
    cards: BlackjackCard[];
    playAmount: string;
    handOutcome: BlackjackHandState; // e.g., Win, Lose, Push
};

export enum BlackjackAction {
    HIT = 'HIT',
    STAND = 'STAND',
    DOUBLE = 'DOUBLE',
    SPLIT = 'SPLIT',
    // INSURANCE = 'INSURANCE',
}

export enum BlackjackGameState {
    IN_PLAY = 'IN_PLAY',
    GAME_OVER = 'GAME_OVER',
}

export enum BlackjackHandState {
    PLAYING = 'PLAYING', // When the player is still playing
    STAND = 'STAND', // When the player chooses to stand
    WIN = 'WIN', // When the player's hand value is higher than the dealer's hand value without busting
    LOSE = 'LOSE', // When the player's hand value is lower than the dealer's hand value without dealer busting
    BUST = 'BUST', // When the player's hand value is over 21
    TW_ONE = 'TW_ONE', // When the player's hand value is 21
    PUSH = 'PUSH', // When the player and dealer have the same hand value
    BLACKJACK = 'BLACKJACK', // When the hand is a natural blackjack
}

export type BlackjackGameClientInfo = {
    userAddress: string;
    playerHands: BlackjackHand[]; // Array to handle multiple hands in case of split
    dealerHand: BlackjackCard[];
    gameState: BlackjackGameState;
    createdAt: string;
    updatedAt: string;
    versionNumber: number;
}

export type BlackjackHandNumber = '0' | '1' | '2' | '3';