// import { useState } from 'react';
import './header.css'
import { Logo } from './logo/logo';
import { UserPanel } from './userPanel/userPanel';
import { ConnectModal } from '../components/connectModal/connectModal';
import { useWeb3 } from '../context/web3Context';
import { useModal } from '../context/modalContext';
import { ImageButton } from '../components/imageButton/imageButton';
import { navigateWithLoading, openInNewTab } from '../utils/navUtils';
import { usePageLoad } from '../context/pageLoadContext';
import { useNavigate, useLocation } from 'react-router-dom';

export const Header = () => {

    const { togglePageLoad } = usePageLoad();
    const location = useLocation();
    const navigate = useNavigate();
    const { address } = useWeb3();
    const { isModalVisible, toggleModal } = useModal();

    const buyButtonOnClick = () => {
        openInNewTab('https://crypto.com/nft/drops-event/7b520de3ede01daa71daf44d83ab8e89?tab=info');
    };

    const redeemButtonOnClick = () => {
        navigateWithLoading('/passredeem', togglePageLoad, navigate);
    };

    const passButtons =
        <>
            <ImageButton
                onClick={buyButtonOnClick}
                width={140}
                src={'https://arcade-web-images.s3.amazonaws.com/button/buy_agp_1000x.webp'}
            />
            {
                location && location.pathname !== '/' &&
                <ImageButton
                    onClick={redeemButtonOnClick}
                    width={140}
                    src={'https://arcade-web-images.s3.amazonaws.com/button/redeem_agp_1000x.webp'}
                />
            }
        </>

    const socials = <>
        <a href='https://boomersquad.io' target="_blank"><img className='menu-bar-social' src={'https://boomersquad-web-images.s3.amazonaws.com' + '/navbar/websitelogo.png'} /></a>
        <a href='https://boomer-squad.gitbook.io/boomer-squad-nft/boomer-arcade/play' target='_blank'><img className='menu-bar-social' src={'https://boomersquad-web-images.s3.amazonaws.com' + '/navbar/gitbookLogo.png'} /></a>
        <a href='https://discord.gg/boomer-squad-nft-939820143486320650' target='_blank'><img className='menu-bar-social' src={'https://boomersquad-web-images.s3.amazonaws.com' + '/navbar/discordlogo.png'} /></a>
        <a href='https://twitter.com/BoomerSquadNFT1' target="_blank"><img className='menu-bar-social' src={'https://boomersquad-web-images.s3.amazonaws.com' + '/navbar/twitterlogo.png'} /></a>
    </>

    return (
        <>
            <div className='header'>

                {/* Logo */}
                <div className='logo-container'>
                    <Logo />
                </div>

                {/* Regular MenuBar */}
                <div className="menu-bar-container">
                    {/* <MenuBar /> */}
                    {passButtons}
                </div>
                <div className='menu-bar-rightside-container'>
                    <div className="menu-bar-socials-container">
                        {/* <MenuBar /> */}
                        {socials}
                    </div>

                    {/* User Panel */}
                    <div className="user-panel-container">
                        <UserPanel userAddress={address} connectButtonClick={toggleModal} />
                        <ConnectModal isOpen={isModalVisible} toggleModal={toggleModal} />
                    </div>
                </div>
            </div>
            <div className='header-extra-mobile-only'>
                {socials}
            </div>
            <div className='header-extra-mobile-only'>
                {passButtons}
            </div>
        </>

    );
}
