import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ModalContextProps {
    isModalVisible: boolean;
    toggleModal: () => void;
}

interface ModalProviderProps {
    children: ReactNode;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const useModal = (): ModalContextProps => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [isModalVisible, setModalVisibility] = useState(false);

    const toggleModal = () => {
        setModalVisibility(prev => !prev);
    };

    return (
        <ModalContext.Provider value={{ isModalVisible, toggleModal }}>
            {children}
        </ModalContext.Provider>
    );
};
