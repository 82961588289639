interface Config {
    BASE_API_URL: string;
    BASE_WS_URL: string;
    ARCADE_STAKING_CONTRACT_ADDRESS: string;
    DEPOSIT_ADDRESS: string;
    WEB_IMAGE_URL: string;
    ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS: string;
    S1_REDEEM_CONTRACT_ADDRESS: string;
}

interface ConfigMap {
    [key: string]: Config;
}

const devConfig: Config = {
    BASE_API_URL: 'http://localhost:3002/api/v1',
    BASE_WS_URL: 'ws://localhost:3002',
    ARCADE_STAKING_CONTRACT_ADDRESS: '0xc221DbE83880d8a7257976E8E13b5F1e8c18a993',
    DEPOSIT_ADDRESS: '0x8774368555840316575fB8C20B23a785854Ee9de',
    WEB_IMAGE_URL: 'https://arcade-web-images.s3.amazonaws.com/',
    ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS: '0x89e8C9F406fC9F3De39EC4431e281174C141e64C',
    S1_REDEEM_CONTRACT_ADDRESS: '0xC885893E0F56E6Ccf5dAB6193d795026cBE87090'
};

const stageConfig: Config = {
    BASE_API_URL: 'https://api.boomerarcade.com/api/v1',
    BASE_WS_URL: 'wss://api.boomerarcade.com',
    ARCADE_STAKING_CONTRACT_ADDRESS: '0xc221DbE83880d8a7257976E8E13b5F1e8c18a993',
    DEPOSIT_ADDRESS: '0x8774368555840316575fB8C20B23a785854Ee9de',
    WEB_IMAGE_URL: 'https://arcade-web-images.s3.amazonaws.com/',
    ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS: '0x89e8C9F406fC9F3De39EC4431e281174C141e64C',
    S1_REDEEM_CONTRACT_ADDRESS: '0xC885893E0F56E6Ccf5dAB6193d795026cBE87090'
};

const prodConfig: Config = {
    BASE_API_URL: 'https://api.boomerarcade.io/api/v1',
    BASE_WS_URL: 'wss://api.boomerarcade.io',
    ARCADE_STAKING_CONTRACT_ADDRESS: '0x9E599a979C9fab0ad0bb1CB3476BecD29c4720BE',
    DEPOSIT_ADDRESS: '0xfEC0E26917C66aD9E59BB8D4FcEE99E12b8A8355',
    WEB_IMAGE_URL: 'https://arcade-web-images.s3.amazonaws.com/',
    ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS: '0x0E0C964a06712F3436128CA7Bc1E2232447bd7D4',
    S1_REDEEM_CONTRACT_ADDRESS: '0x5537E300820Db26dF3fdF91EB45c386F3d990ec2'
};

const configMap: ConfigMap = {
    development: devConfig,
    staging: stageConfig,
    production: prodConfig,
};

type Env = 'development' | 'staging' | 'production';

// Ensure that the value of REACT_APP_ENV is one of the accepted values.
const env: Env = process.env.REACT_APP_ENV as Env;

// Ensure we have a valid config for the given environment.
if (!configMap[env]) {
    throw new Error(`Invalid or missing environment setting: REACT_APP_ENV=${env}`);
}

export default configMap[env];
