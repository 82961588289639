import { useNavigate } from 'react-router-dom';
import { weiToEthWithDecimals } from '../../web3/utils';
import './accountBalance.css';
import { navigateWithLoading, openInNewTab } from '../../utils/navUtils';
import { usePageLoad } from '../../context/pageLoadContext';
import { DynamicIcon } from '../dynamicIcon/dynamicIcon';

type AccountBalanceProps = {
    cronosScratchBalance: string;
    cronosCroBalance: string;
    cronosZoomerBalance: string;
    cronosBoomRoomStakingPoints: string;
    arcadeScratchBalance: string;
    arcadeZoomerBalance: string;
    arcadeFarmShareBalance: string;
    arcadePermaFarmPointsBalance: string;
    setDepositModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setWithdrawModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AccountBalance: React.FC<AccountBalanceProps> = ({
    cronosScratchBalance,
    cronosCroBalance,
    cronosZoomerBalance,
    cronosBoomRoomStakingPoints,
    arcadeScratchBalance,
    arcadeZoomerBalance,
    arcadeFarmShareBalance,
    arcadePermaFarmPointsBalance,
    setDepositModalOpen,
    setWithdrawModalOpen
}) => {

    const { togglePageLoad } = usePageLoad();
    const navigate = useNavigate();

    const handleBuyScratchClick = () => {
        // Open new tab to buy scratch
        openInNewTab('https://vvs.finance/swap?outputCurrency=0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66')
    }

    const handleBuyZoomerClick = () => {
        // Open new tab to buy scratch
        openInNewTab('https://app.ebisusbay.com/collection/zoomer-squad')
    }

    const handleBuyArcadeScratchClick = () => {
        return null
    }

    const handleStakeBoomRoomPointsClick = () => {
        openInNewTab('https://www.boomersquad.io/boomrooms/stakinggroundsv2')
    }

    const handleBuyBoomRoomClick = () => {
        openInNewTab('https://app.ebisusbay.com/collection/boomer-squad-boom-rooms')
    }

    const handleDepositScratchClick = () => {
        setDepositModalOpen(true)
    }

    const handleWithdrawScratchClick = () => {
        setWithdrawModalOpen(true)
    }

    const handleStakeZoomerClick = () => {
        // Navigate to zoomer page
        navigateWithLoading('../zoomerstaking', togglePageLoad, navigate)

    }

    const handleTradeFarmShareClick = () => {
        // Navigate to trade page
        navigateWithLoading('../farm', togglePageLoad, navigate)
    }

    const handleBuyPermaFarmPointsClick = () => {
        // Navigate to trade page
        openInNewTab('https://crypto.com/nft/drops-event/7b520de3ede01daa71daf44d83ab8e89?tab=info')
    }

    const iconOpenInNew = <DynamicIcon name='OPEN_IN_NEW' width={15} />
    const scratchTokenIcon = <DynamicIcon name='SCRATCH' width={25} />
    const zoomerIcon = <DynamicIcon name='ZOOMER' width={25} />
    const croIcon = <DynamicIcon name='CRO' width={25} />
    const farmShareIcon = <DynamicIcon name='FS' width={25} />
    const PermaFarmPointsIcon = <DynamicIcon name='PFP' width={25} />
    const boomRoomIcon = <DynamicIcon name='BOOMROOM' width={25} />

    return (
        <div className='accountBalance'>
            <div className="accountBalance-container">
                <div className="accountBalance-section-container">
                    <div className="accountBalance-section-title">
                        Cronos Balance
                    </div>
                    <div className="accountBalance-section">
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {scratchTokenIcon} {weiToEthWithDecimals(cronosScratchBalance, 2)} SCRATCH
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall' onClick={handleBuyScratchClick}>
                                    Buy {iconOpenInNew}
                                </div>
                            </div>
                        </div>
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {zoomerIcon}{cronosZoomerBalance} Zoomers
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall' onClick={handleBuyZoomerClick}>
                                    Buy {iconOpenInNew}
                                </div>
                            </div>
                        </div>
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {croIcon} {weiToEthWithDecimals(cronosCroBalance, 2)} CRO
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall disabled' onClick={handleBuyArcadeScratchClick}>
                                    Buy Arcade SCRATCH
                                </div>
                            </div>
                        </div>
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {boomRoomIcon} {cronosBoomRoomStakingPoints} Points Staked
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall' onClick={handleStakeBoomRoomPointsClick}>
                                    Stake {iconOpenInNew}
                                </div>
                                <div className='button button-primary xsmall' onClick={handleBuyBoomRoomClick}>
                                    Buy {iconOpenInNew}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accountBalance-section-container">
                    <div className="accountBalance-section-title">
                        Arcade Balance
                    </div>
                    <div className="accountBalance-section">
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {scratchTokenIcon} {weiToEthWithDecimals(arcadeScratchBalance, 2)} SCRATCH
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall' onClick={handleDepositScratchClick}>
                                    Deposit
                                </div>
                                <div className='button button-primary xsmall' onClick={handleWithdrawScratchClick}>
                                    Withdraw
                                </div>
                            </div>
                        </div>
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {zoomerIcon} {arcadeZoomerBalance} Zoomers Staked
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall' onClick={handleStakeZoomerClick}>
                                    Stake
                                </div>
                            </div>
                        </div>
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {farmShareIcon} {weiToEthWithDecimals(arcadeFarmShareBalance, 2)} Farm Shares
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall' onClick={handleTradeFarmShareClick}>
                                    Trade
                                </div>
                            </div>
                        </div>
                        <div className="accountBalance-section-row">
                            <div className="accountBalance-section-row-value">
                                {PermaFarmPointsIcon} {arcadePermaFarmPointsBalance} Perma Farm Points
                            </div>
                            <div className='accountBalance-section-buttons'>
                                <div className='button button-primary xsmall' onClick={handleBuyPermaFarmPointsClick}>
                                    Buy {iconOpenInNew}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}