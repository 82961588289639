export const checkIsApprovedForAll = async (web3: any, account: string, spenderContract: string, tokenContract: string): Promise<boolean> => {
    // Encode the function parameters
    const encodedParams = web3.eth.abi.encodeParameters(['address', 'address'], [account, spenderContract]);

    const functionSignature = "isApprovedForAll(address,address)";
    const functionSelector = web3.eth.abi.encodeFunctionSignature(functionSignature);


    // Construct the raw transaction data (you'd have to get the correct function selector for `isApprovedForAll`)
    const txData = functionSelector + encodedParams.substring(2);  // Remove the '0x' prefix

    try {
        const approved = await web3.eth.call({
            to: tokenContract,
            data: txData
        });
        return approved === '0x0000000000000000000000000000000000000000000000000000000000000001';  // True in Ethereum's raw return
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const approveContractForAll = async (web3: any, account: string, spenderContract: string, tokenContract: string): Promise<any> => {

    // Encode the function parameters
    const encodedParams = web3.eth.abi.encodeParameters(['address', 'bool'], [spenderContract, true]);

    // Construct the raw transaction data (you'd have to get the correct function selector for `setApprovalForAll`)
    const txData = '0xa22cb465' + encodedParams.substring(2);  // Remove the '0x' prefix

    try {
        const result = await web3.eth.sendTransaction({
            from: account,
            to: tokenContract,
            data: txData
        });
        return result;
    } catch (error) {
        console.error(error);
    }
}