import React from 'react';
import './tetrisUpcomingBlocks.css';
import { getTetroId, getTetroShape } from '../tetrisUtils';

export const TetrisUpcomingBlocks: React.FC<{ upcomingBlocks?: number[] }> = ({ upcomingBlocks = [0, 0, 0] }) => {
    return (
        <div className="tetrisUpcomingBlocks">
            <div className="tetrisUpcomingBlocks_title">Next</div>
            <div className="tetrisUpcomingBlocks_blocks">
                {upcomingBlocks.map((blockTypeId, index) => {
                    const blockShape = getTetroShape(blockTypeId);
                    const blockId = getTetroId(blockTypeId);
                    return (
                        <div key={index} className="tetrisUpcomingBlock">
                            {blockShape?.map((row, rowIndex) => (
                                <div key={rowIndex} className="tetrisUpcomingBlock_row">
                                    {row.map((cell, cellIndex) => (
                                        <div key={cellIndex} className={`tetrisUpcomingBlock_cell ${cell !== 0 ? `${blockId}` : 'E'}`}></div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
