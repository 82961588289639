import { toast } from "react-toastify";

export const formatTxHash = (txHash: string): string => {
    return txHash.slice(0, 2) + ".." + txHash.slice(-4);
};

export function stringDisplay(input: string): string {
    if (input.length === 0) return input; // Check if the string is empty
    return input.charAt(0).toUpperCase() +
        input.slice(1).toLowerCase().replace(/_/g, ' ');
}

export const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    toast.success(`Copied to clipboard!`);
};
