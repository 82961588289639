import { useNavigate } from 'react-router-dom';
import arcadeApi from '../../requests/arcadeApi';
import './logo.css';
import { usePageLoad } from '../../context/pageLoadContext';

export const Logo = () => {

    const { togglePageLoad } = usePageLoad();
    const navigate = useNavigate();

    const handleLogoClick = async () => {
        togglePageLoad(true);
        // check if user is still logged in
        try {
            const res = await arcadeApi.checkUserIsLoggedIn();
            if (res.message === 'User is logged in') {
                // Navigate to lobby
                navigate('lobby')
            }
        } catch (error) {
            // Navigate to home
            navigate('/')
        }

    }

    return (
        <div className='logo' onClick={handleLogoClick}>
            Boomer<span className="logo-accent">Arcade</span>
        </div>
    );
};
