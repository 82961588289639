import { add, multiply } from "../../../utils/mathUtils";
import { BlackjackCard, BlackjackGameClientInfo, BlackjackGameState, BlackjackHandNumber, BlackjackHandState } from "./blackjackTypes";

export const getHandValue = (hand: BlackjackCard[]): number => {
    return hand.reduce((acc, card) => acc + card.value, 0);
}

export const canHit = (game: BlackjackGameClientInfo, hand: BlackjackHandNumber): boolean => {
    // Check if undefined
    if (game.playerHands[hand] === undefined) { return false };
    // Get the current hand
    const currentHand = game.playerHands[hand];
    // Check if the hand outcome is PLAYING
    if (currentHand.handOutcome !== BlackjackHandState.PLAYING) { return false };
    return true;
}

export const canStand = (game: BlackjackGameClientInfo, hand: BlackjackHandNumber): boolean => {
    // Check if undefined
    if (game.playerHands[hand] === undefined) { return false };
    // Get the current hand
    const currentHand = game.playerHands[hand];
    // Check if the hand outcome is PLAYING
    if (currentHand.handOutcome !== BlackjackHandState.PLAYING) { return false };
    return true;
}

/**
 * 
 * @param game // The current game state
 * @param hand // The hand to double down on
 * @returns // boolean indicating whether the hand can double down
 */
export const canDoubleDown = (game: BlackjackGameClientInfo, hand: BlackjackHandNumber): boolean => {
    // Check if undefined
    if (game.playerHands[hand] === undefined) { return false };
    // Get the current hand
    const currentHand = game.playerHands[hand];
    // Check if the hand outcome is PLAYING
    if (currentHand.handOutcome !== BlackjackHandState.PLAYING) { return false };
    // Check if the hand has 2 cards
    if (currentHand.cards.length !== 2) { return false };
    return true;
}

export const canSplit = (game: BlackjackGameClientInfo, hand: BlackjackHandNumber): boolean => {
    // Check if undefined
    if (game.playerHands[hand] === undefined) { return false };
    // If user already has more than 2 hands, return false
    if (game.playerHands.length >= 4) { return false };
    // Get the current hand
    const currentHand = game.playerHands[hand];
    // Check if the hand outcome is PLAYING
    if (currentHand.handOutcome !== BlackjackHandState.PLAYING) { return false };
    // Check if the hand has 2 cards
    if (currentHand.cards.length !== 2) { return false };
    // If the two cards have the same name, return true
    if (currentHand.cards[0].name === currentHand.cards[1].name) { return true };
    // If the two cards have the same value, return true
    if (currentHand.cards[0].value === currentHand.cards[1].value) { return true };
    // if the two cards are aces, return true
    if (currentHand.cards[0].name.includes('A') && currentHand.cards[1].name.includes('A')) { return true };
    // Else, return false
    return false;
}

// Check whether any of the hands are still in PLAYING
export const hasHandsPlaying = (game: BlackjackGameClientInfo): boolean => {
    return game.playerHands.some(hand => hand.handOutcome === BlackjackHandState.PLAYING);
}

export const calculateWinnings = (game: BlackjackGameClientInfo): string => {
    let winnings = '0';
    game.playerHands.forEach(hand => {
        if (hand.handOutcome === BlackjackHandState.WIN) {
            winnings = add(winnings, multiply(hand.playAmount, '2'));
        } else if (hand.handOutcome === BlackjackHandState.PUSH) {
            winnings = add(winnings, hand.playAmount);
        } else if (hand.handOutcome === BlackjackHandState.BLACKJACK) {
            winnings = add(winnings, multiply(hand.playAmount, '2.5'));
        }
    });

    return winnings;
}

export const getTotalPlayed = (game: BlackjackGameClientInfo): string => {
    return game.playerHands.reduce((acc, hand) => add(acc, hand.playAmount), '0');
}

export const getTotalWon = (game: BlackjackGameClientInfo): string => {
    return game.playerHands.reduce((acc, hand) => {
        if (hand.handOutcome === BlackjackHandState.WIN) {
            return add(acc, multiply(hand.playAmount, '2'));
        } else if (hand.handOutcome === BlackjackHandState.PUSH) {
            return add(acc, hand.playAmount);
        } else if (hand.handOutcome === BlackjackHandState.BLACKJACK) {
            return add(acc, multiply(hand.playAmount, '2.5'));
        }
        return acc;
    }, '0');
}

export const getHandStateName = (handState: BlackjackHandState): string => {
    switch (handState) {
        case BlackjackHandState.PLAYING:
            return 'Playing';
        case BlackjackHandState.BUST:
            return 'Bust';
        case BlackjackHandState.BLACKJACK:
            return 'Blackjack';
        case BlackjackHandState.WIN:
            return 'Win';
        case BlackjackHandState.LOSE:
            return 'Lose';
        case BlackjackHandState.PUSH:
            return 'Tie';
        case BlackjackHandState.TW_ONE:
            return '21';
        case BlackjackHandState.STAND:
            return 'Stand';
        default:
            return 'Unknown';
    }
}
