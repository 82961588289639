import config from "../../config";

const redeemContractAddress = config.S1_REDEEM_CONTRACT_ADDRESS;

// Redeem S1 Passes
export const redeemS1Passes = async (web3: any, account: string, selectedTokenIds: string[]): Promise<any> => {
    // Encode the function call to the smart contract
    const data = web3.eth.abi.encodeFunctionCall({
        name: 'redeem',
        type: 'function',
        inputs: [{
            type: 'uint256[]',
            name: 'tokenIds'
        }]
    }, [selectedTokenIds]);

    // Send the transaction
    try {
        const txReceipt = await web3.eth.sendTransaction({
            from: account,
            to: redeemContractAddress,
            data: data,
            value: '0x0' // Assuming no Ether is being sent
        });

        return txReceipt;
    } catch (error) {
        console.error("Failed to redeem S1 passes:", error);
        throw error;
    }
}