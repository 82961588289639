import { Web3 } from 'web3';

// Type of the connect function's parameter
interface ConnectMetaMaskProps {
    setWeb3: React.Dispatch<React.SetStateAction<Web3 | null>>;
}

export const connectMetaMask = async ({ setWeb3 }: ConnectMetaMaskProps) => {
    // Access Ethereum window object
    // maybe as any is causing issues with defi wallet
    // const ethereum = (window as any).ethereum;
    const ethereum = window.ethereum;

    if (ethereum) {
        try {
            // Request account access if needed
            // REMOVING THIS BECAUSE IT MIGHT BE CAUSING ISSUES WITH DEFI WALLET SINCE IT CONNECTS AUTOMATICALLY BUT NO PROVIDER IS SET YET
            // await ethereum.request({ method: 'eth_requestAccounts' });

            // Create a Web3 instance with the current provider (MetaMask)
            const web3 = new Web3(ethereum);

            // Now, set your Web3 instance with the provider received from MetaMask
            setWeb3(web3);
        } catch (error) {
            // Handle error. For example, rejected request, no provider, etc.
            console.error("Could not connect to MetaMask", error);
            // Optionally: setWeb3(null) to represent no connection in your state
            setWeb3(null);
        }
    } else {
        // If the provider is not detected, MetaMask is not installed
        console.error("Please install MetaMask!");
        // Optionally: setWeb3(null) to represent no connection in your state
        setWeb3(null);
    }
};