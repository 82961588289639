import './blackjackGame.css'
import React, { useEffect, useState } from 'react';
import { BlackjackGameClientInfo, BlackjackGameState, BlackjackHand, BlackjackHandNumber, BlackjackHandState } from "./blackjackTypes";
import config from '../../../config';
import { getHandValue, canHit, canStand, canDoubleDown, canSplit, getTotalPlayed, getTotalWon, getHandStateName } from './blackjackUtils';
import ButtonSpinner from '../../spinners/buttonSpinner';
import { weiToEthWithDecimals } from '../../../web3/utils';
import { isBiggerThan, subtract } from '../../../utils/mathUtils';
import { DynamicIcon } from '../../dynamicIcon/dynamicIcon';

type BlackjackGameProps = {
    game: BlackjackGameClientInfo | null;
    onStartGame: () => void;
    onHit: (handNum: BlackjackHandNumber) => void;
    onStand: (handNum: BlackjackHandNumber) => void;
    onDouble: (handNum: BlackjackHandNumber) => void;
    onSplit: (handNum: BlackjackHandNumber) => void;
    loading: boolean;
    playAmountDisplay: number;
    handlePlayAmountChange: (amount: number) => void;
    checkCanIncreasePlayAmount: (amount: number) => boolean;
    checkCanPlay: () => boolean;
}

export const BlackjackGame: React.FC<BlackjackGameProps> = ({
    game,
    onStartGame,
    onHit,
    onStand,
    onDouble,
    onSplit,
    loading,
    playAmountDisplay,
    handlePlayAmountChange,
    checkCanIncreasePlayAmount,
    checkCanPlay
}) => {

    const [selectedHand, setSelectedHand] = useState<BlackjackHandNumber | null>(null);
    const [canPressHit, setCanPressHit] = useState<boolean>(false);
    const [canPressStand, setCanPressStand] = useState<boolean>(false);
    const [canPressDouble, setCanPressDouble] = useState<boolean>(false);
    const [canPressSplit, setCanPressSplit] = useState<boolean>(false);
    const [view, setView] = useState<'STATS' | 'START MENU'>('STATS');

    useEffect(() => {
        // Reset buttons
        if (game !== null) {
            if (selectedHand !== null) {
                setCanPressStand(canStand(game, selectedHand));
                setCanPressHit(canHit(game, selectedHand));
                setCanPressDouble(canDoubleDown(game, selectedHand));
                setCanPressSplit(canSplit(game, selectedHand));
                // check if selected hand is still playing
                if (game.playerHands[selectedHand].handOutcome !== BlackjackHandState.PLAYING) {
                    setSelectedHand(null);
                }
            }
            if (
                game.playerHands['0'].handOutcome === BlackjackHandState.PLAYING &&
                selectedHand !== '0' &&
                game.playerHands['1'] === undefined
            ) {
                setSelectedHand('0');
            }
            setView('STATS');
        } else {
            setCanPressStand(false);
            setCanPressHit(false);
            setCanPressDouble(false);
            setCanPressSplit(false);
            setView('START MENU');
        }
    }, [game, selectedHand]);

    const handleSetSelectedHand = (handNum: BlackjackHandNumber) => {
        if (
            game?.playerHands[handNum] === undefined ||
            game?.playerHands[handNum].handOutcome !== BlackjackHandState.PLAYING
        ) {
            return;
        } else {
            setSelectedHand(handNum);

        }
    }

    let totalDealerHandValue: number = 0;
    let totalPlayed: string = '0';
    let totalWon: string = '0';
    let resultDiff: string = '0';

    if (game !== null) {
        totalDealerHandValue = getHandValue(game.dealerHand)
        totalPlayed = getTotalPlayed(game!)
        totalWon = getTotalWon(game!)
        resultDiff = subtract(totalWon, totalPlayed)

    }

    const handlePressHit = () => {
        if (selectedHand === null) {
            return;
        }
        onHit(selectedHand);
    }

    const handlePressStand = () => {
        if (selectedHand === null) {
            return;
        }
        onStand(selectedHand);
    }

    const handlePressDouble = () => {
        if (selectedHand === null) {
            return;
        }
        onDouble(selectedHand);
    }

    const handlePressSplit = () => {
        if (selectedHand === null) {
            return;
        }
        onSplit(selectedHand);
    }

    const hand_bg_colors = [
        'one',
        'two',
        'three',
        'four'
    ];

    useEffect(() => {
        const styleSheet = document.createElement('style');
        document.head.appendChild(styleSheet);
        if (styleSheet.sheet !== null) {
            styleSheet.sheet.insertRule(`
                    .blackjackGame-endresult-container-section:nth-child(3)::after {
                        background-color: 
                        ${isBiggerThan(resultDiff, '0') ? 'rgb(27, 153, 58)' :
                    isBiggerThan('0', resultDiff) ? 'rgb(155, 10, 53)' :
                        '#cd9300'};
                    }
                `, styleSheet.sheet.cssRules.length);
        }

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, [resultDiff]);

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);

    const scratchIcon40 = <DynamicIcon name='SCRATCH' width={screenWidth > 1200 ? 40 : 25} />
    const scratchIcon30 = <DynamicIcon name='SCRATCH' width={screenWidth > 1200 ? 30 : 20} />

    const isMobile = screenWidth < 1200;

    const playComp =
        <div className='blackjackGame-endresult-container-section'>
            <div className='blackjackGame-endresult-container-section-title'>
                Played
            </div>
            <div className='blackjackGame-endresult-container-section-value'>
                {isMobile ? null : scratchIcon40}
                -{weiToEthWithDecimals(totalPlayed, 2)}
            </div>
        </div>

    const wonComp =
        <div className='blackjackGame-endresult-container-section'>
            <div className='blackjackGame-endresult-container-section-title'>
                Won
            </div>
            <div className='blackjackGame-endresult-container-section-value'>
                {isMobile ? null : scratchIcon40}
                {isBiggerThan(resultDiff, '0') ? '+' : ''}
                {weiToEthWithDecimals(totalWon, 2)}
            </div>
        </div>

    const resultComp =
        <div className='blackjackGame-endresult-container-section'>
            <div className='blackjackGame-endresult-container-section-title'>
                Total
            </div>
            <div className='blackjackGame-endresult-container-section-value'>
                {isMobile ? null : scratchIcon40}
                {isBiggerThan(resultDiff, '0') ? '+' : ''}
                {weiToEthWithDecimals(resultDiff, 2)}
            </div>
        </div>

    const endGameResultComp =
        <>
            {
                view === 'STATS' ?
                    <div className='blackjackGame-endresult-container'>
                        {playComp}
                        {wonComp}
                        {resultComp}
                        <div className='blackjackGame-newGame-buttons primary' onClick={() => setView('START MENU')}>New Game</div>
                    </div>
                    :
                    <div className='blackjackGame-newGame-container'>


                        <div
                            className={`blackjackGame-playAmount-button ${checkCanIncreasePlayAmount(25) ? '' : 'disabled'}`}
                            onClick={() => handlePlayAmountChange(25)}
                        >
                            +25 {scratchIcon30}
                        </div>

                        <div
                            className={`blackjackGame-playAmount-button ${checkCanIncreasePlayAmount(5) ? '' : 'disabled'}`}
                            onClick={() => handlePlayAmountChange(5)}
                        >
                            +5 {scratchIcon30}
                        </div>
                        <div
                            className={`blackjackGame-playAmount-button ${checkCanIncreasePlayAmount(1) ? '' : 'disabled'}`}
                            onClick={() => handlePlayAmountChange(1)}
                        >
                            +1 {scratchIcon30}
                        </div>
                        <div
                            className={`blackjackGame-playAmount-button`}
                            onClick={() => handlePlayAmountChange(0)}
                        >
                            Reset
                        </div>
                        <div className='blackjackGame-currentBet-display'>
                            <div className='blackjackGame-currentBet-display-value'>
                                {playAmountDisplay}
                                {scratchIcon40}
                            </div>
                        </div>

                        <div className='blackjackGame-newGame-buttons-container'>
                            <div
                                className='blackjackGame-newGame-buttons primary'
                                onClick={() => setView('STATS')}
                            >
                                STATS
                            </div>
                            <div
                                className={`blackjackGame-newGame-buttons tertiary ${!checkCanPlay() ? 'disabled' : ''}`}
                                onClick={onStartGame}
                            >
                                PLAY
                            </div>
                        </div>
                    </div>
            }
        </>

    const buttonPanel =
        <div
            className={`blackjackGame-actions-container`}
        >
            {
                game?.gameState === BlackjackGameState.GAME_OVER || game === null ?
                    <>
                        {
                            loading ?

                                <div className='button button-primary medium disabled'><ButtonSpinner size={25} /></div>

                                :
                                endGameResultComp

                        }
                    </>
                    :
                    <>
                        <BlackjackActionButton text='Hit' onClick={handlePressHit} disabled={!canPressHit} />
                        <BlackjackActionButton text='Stand' onClick={handlePressStand} disabled={!canPressStand} />
                        <BlackjackActionButton text='Double' onClick={handlePressDouble} disabled={!canPressDouble} />
                        <BlackjackActionButton text='Split' onClick={handlePressSplit} disabled={!canPressSplit} />
                    </>
            }
        </div>

    return (
        <div className='blackjackGame-container non-selectable'>
            {/* <img className='blackjackGame-titleImage' src={`${config.WEB_IMAGE_URL}titleCards/hit_me.png`} alt='blackjack table' /> */}
            <div className='blackjackGame-dealerhand'>
                <div className='blackjackGame-cards-container'>
                    {
                        game?.dealerHand.map((card, index) => {
                            return (
                                <img
                                    key={index}
                                    className='blackjackGame-card'
                                    src={`${config.WEB_IMAGE_URL}cards/200x275/${card.name}.webp`}
                                    alt={card.name}
                                />
                            )

                        })
                    }
                </div>
                <DealerHandValueComp handValue={totalDealerHandValue} />
            </div>
            {buttonPanel}
            {
                hand_bg_colors.map((color, index) => {
                    const handNumber = index.toString() as BlackjackHandNumber;

                    return (
                        <div
                            key={index}
                            className={`blackjackGame-playerhand blackjackGame-bg ${color} ${selectedHand === handNumber ? 'selected' : ''}`}
                            onClick={() => handleSetSelectedHand(handNumber)}
                        >
                            {
                                game?.playerHands[index] === undefined ?
                                    null
                                    :
                                    <>
                                        <div className='blackjackGame-cards-container'>
                                            {
                                                game?.playerHands[index].cards.map((card, index) => {
                                                    return (
                                                        <img
                                                            key={index}
                                                            className={`blackjackGame-card ${selectedHand === handNumber ? 'selected' : ''}`}
                                                            src={`${config.WEB_IMAGE_URL}cards/200x275/${card.name}.webp`}
                                                            alt={card.name}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='blackjackGame-playerhand-info'>
                                            <HandStateComp stateName={getHandStateName(game?.playerHands[index].handOutcome)} />
                                            <HandValueComp hand={game?.playerHands[index]} />
                                        </div>
                                    </>

                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

const BlackjackActionButton = ({ text, onClick, disabled }: { text: string, onClick: () => void, disabled: boolean }) => {
    return (
        <div
            className={`blackjackGame-button button button-primary small ${disabled ? 'disabled' : ''}`}
            onClick={() => disabled ? null : onClick()}
        >
            {text}
        </div>
    )
}

const DealerHandValueComp = ({ handValue }: { handValue: number }) => {
    return (
        handValue !== 0 ?
            <div className='blackjackGame-playerhand-cardValue'>
                {handValue}
            </div>
            : null
    )

}

const HandValueComp = ({ hand }: { hand: BlackjackHand, }) => {

    const handValue = getHandValue(hand.cards);
    const handStateName = getHandStateName(hand.handOutcome);

    const isAceHand = hand.cards.some(card => card.name.includes('A') && card.value === 11);
    const isPlaying = hand.handOutcome === BlackjackHandState.PLAYING;
    const isBoth = isAceHand && isPlaying;

    return (
        <div className={`blackjackGame-playerhand-cardValue blackjackGame-${handStateName} ${isBoth ? 'Acehand' : ''}`}>
            {
                isBoth ?
                    `${handValue - 10}/${handValue}`
                    :
                    handValue
            }
        </div>
    )
}

const HandStateComp = ({ stateName }: { stateName: string }) => {
    return (
        <div className={`blackjackGame-playerhand-handState blackjackGame-${stateName}`}>
            {stateName}
        </div>
    )
}
