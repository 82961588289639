import './errorModal.css';

// Define the props for the ErrorModal component
interface ErrorModalProps {
    message: string;
    open: boolean;
    onClose: () => void;
}

// Define the ErrorModal component
export const ErrorModal: React.FC<ErrorModalProps> = ({ message, open, onClose }) => {
    if (!open) return null;

    return (
        <div className="error-modal">
            <div className="error-modal-content">
                <h4>Error</h4>
                <p>{message}</p>
                <div
                className='button-primary button medium'
                onClick={onClose}>
                    Close
                </div>
            </div>
        </div>
    );
};