import './tetrisScore.css';

type TetrisScoreProps = {
    score: number;
    level: number;
}

export const TetrisScore: React.FC<TetrisScoreProps> = ({ score, level }) => {
    return (
        <div className="tetrisScore">
            <div className='tetrisScore_container'>
                <div className="tetrisScore_title">Score</div>
                <div className="tetrisScore_score">{score}</div>
            </div>
            <div className='tetrisScore_container'>
                <div className="tetrisScore_title">Level</div>
                <div className="tetrisScore_score">{level}</div>
            </div>
        </div>
    );
}