import './zoomerStaking.css';
import { useCallback, useEffect, useState } from 'react';
import { NFTList } from '../../../components/nftList/nftList';
import { usePageLoad } from '../../../context/pageLoadContext';
import { useWeb3 } from '../../../context/web3Context';
import { stakeZoomers, unstakeZoomers } from '../../../web3/methods/arcadeStakingMethods';
import { approveArcadeStakingContract, checkIsApprovedForAllZoomers } from '../../../web3/methods/zoomersMethods';
import { getArcadeBalance, getWalletZoomers } from '../../../utils/reqUtils';
import { useNavigate } from 'react-router-dom';
import { DynamicIcon } from '../../../components/dynamicIcon/dynamicIcon';
import { useArcadeBalance } from '../../../context/arcadeBalanceContext';

export const ZoomerStaking = () => {

    // Context imports
    const { web3, address } = useWeb3();
    const { togglePageLoad } = usePageLoad();

    const navigate = useNavigate(); // Destructure navigate function
    const { refreshBalance } = useArcadeBalance(); // HUD refresh

    const [cronosZoomerTokenIds, setCronosZoomerTokenIds] = useState<string[]>([]);
    const [arcadeZoomerTokenIds, setArcadeZoomerTokenIds] = useState<string[]>([]);
    const [selectedStakedZoomers, setSelectedStakedZoomers] = useState<string[]>([]);
    const [selectedWalletZoomers, setSelectedWalletZoomers] = useState<string[]>([]);
    const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);

    const resetSelectedZoomers = useCallback(() => {
        const emptyArray: string[] = [];
        setSelectedWalletZoomers(emptyArray);
        setSelectedStakedZoomers(emptyArray);
    }, [])

    const isUnstakeable = selectedStakedZoomers.length > 0;
    const isStakeable = selectedWalletZoomers.length > 0 && isApprovedForAll;

    const handleUnstakeButtonClick = async () => {
        togglePageLoad(true);
        if (web3 && address && isUnstakeable) {
            try {
                await unstakeZoomers(web3, address, selectedStakedZoomers)
                await new Promise(resolve => setTimeout(resolve, 3000));
                await loadData()
            } catch (err) {
                console.log(err);
                togglePageLoad(false);
            }
        }
    }

    const handleStakeButtonClick = async () => {
        togglePageLoad(true);
        if (web3 && address && isStakeable) {
            try {
                await stakeZoomers(web3, address, selectedWalletZoomers)
                await new Promise(resolve => setTimeout(resolve, 3000));
                await loadData()
            } catch (err) {
                console.log(err);
                togglePageLoad(false);
            }
        }
    }

    const handleApproveButtonClick = async () => {
        togglePageLoad(true);
        if (address && web3) {
            try {
                await approveArcadeStakingContract(web3, address)
                await loadData()
            } catch (err) {
                console.log(err);
                togglePageLoad(false);
            }
        }
        togglePageLoad(false);
    }

    const loadData = useCallback(async () => {
        togglePageLoad(true);
        resetSelectedZoomers();
        if (web3 && address) {
            try {
                const [
                    [
                        , // arcadeScratchBalance EMPTY, DON'T NEED DATA
                        , // arcadeFarmShareBalance EMPTY, DON'T NEED DATA
                        , // arcadeZoomerBalance EMPTY, DON'T NEED DATA
                        arcadeZoomerTokenIds,
                    ],
                    walletZoomerTokenIds,
                    isApprovedForAll,
                ] = await Promise.all([
                    getArcadeBalance(),
                    getWalletZoomers(address),
                    checkIsApprovedForAllZoomers(web3, address),
                    refreshBalance(),
                ]);

                // Set states
                setArcadeZoomerTokenIds(arcadeZoomerTokenIds);
                setCronosZoomerTokenIds(walletZoomerTokenIds);
                setIsApprovedForAll(isApprovedForAll);
            } catch (err) {
                // Handle error
                navigate("/");
            } finally {
                // Always stop the loading spinner, whether it succeeds or fails
                togglePageLoad(false);
            }
        } else {
            togglePageLoad(false);
        }
    }, [web3, address, resetSelectedZoomers, navigate, refreshBalance]);

    // get arcade and web3 data on page load
    useEffect(() => {
        togglePageLoad(true);
        loadData();
    }, [loadData]);

    return (
        <div className='zoomerStaking-page page'>
            <img className={'tetris-titleImage'} src={'titleCards/zoomerstaking.png'} alt='' />
            <div className='zoomerStaking-page-section'>
                <h1 className="zoomerStaking-page-subtitle">Staked Zoomers<DynamicIcon name='ZOOMER' width={50} /></h1>
                <div className={`button-primary button medium zoomerStaking-page-button ${isUnstakeable ? '' : 'disabled'}`}
                    onClick={isUnstakeable ? handleUnstakeButtonClick : () => null}
                >
                    Unstake Selected
                </div>
                <NFTList
                    tokenName={'Zoomer'}
                    itemsPerPage={20}
                    tokenIds={arcadeZoomerTokenIds}
                    imageUrl={(tokenId) => `https://zoomers-revealed.s3.amazonaws.com/images/variant2/${tokenId}.png`}
                    selectedTokens={selectedStakedZoomers}
                    setSelectedTokens={setSelectedStakedZoomers}
                />
            </div>
            <div className='zoomerStaking-page-section'>
                <h1 className="zoomerStaking-page-subtitle">Cronos Zoomers <DynamicIcon name='ZOOMER' width={50} /></h1>
                {
                    isApprovedForAll ?
                        <div className={`button-primary button medium zoomerStaking-page-button ${isStakeable ? '' : 'disabled'}`}
                            onClick={isStakeable ? handleStakeButtonClick : () => null}
                        >
                            Stake Selected
                        </div>
                        :
                        <div className='button-primary button medium'
                            onClick={handleApproveButtonClick}
                        >
                            Approve Contract
                        </div>
                }
                <NFTList
                    tokenName={'Zoomer'}
                    itemsPerPage={20}
                    tokenIds={cronosZoomerTokenIds}
                    imageUrl={(tokenId) => `https://zoomers-revealed.s3.amazonaws.com/images/variant2/${tokenId}.png`}
                    selectedTokens={selectedWalletZoomers}
                    setSelectedTokens={setSelectedWalletZoomers}
                />
            </div>

        </div>
    )
}