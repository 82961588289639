import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { Web3 } from 'web3';
import { getScratchBalance } from '../web3/methods/scratchMethods';

interface Web3State {
    web3: Web3 | null;
    address: string | null;
    chainId: number | null;
    ethBalance: string | null;
    scratchBalance: string | null;
    setWeb3: React.Dispatch<React.SetStateAction<Web3 | null>>;
    disconnect: () => void;
}

interface Web3ProviderProps {
    children: ReactNode;
    provider: any;
}

// Create a context
export const Web3Context = createContext<Web3State | undefined>(undefined);

// Custom hook to use Web3 context
export const useWeb3 = () => {
    const context = useContext(Web3Context);
    if (!context) {
        throw new Error("useWeb3 must be used within a Web3Provider");
    }
    return context;
};



export const Web3Provider: React.FC<Web3ProviderProps> = ({ children, provider }) => {
    const [web3, setWeb3] = useState<Web3 | null>(new Web3(provider));
    const [address, setAddress] = useState<string | null>(null);
    const [chainId, setChainId] = useState<number | null>(null);
    const [ethBalance, setEthBalance] = useState<string | null>(null);
    const [scratchBalance, setScratchBalance] = useState<string | null>(null);

    const disconnect = () => {
        // Disconnect from provider
        if (web3?.currentProvider?.disconnect) {
            web3.currentProvider.disconnect();
        }
        // Reset state
        setWeb3(null);
        setAddress(null);
        setChainId(null);
        setEthBalance(null);
        setScratchBalance(null);
    };

    useEffect(() => {
        if (web3) {
            const init = async () => {
                let accounts: string[] = [];
                // Request account access if needed
                try {
                    accounts = await web3.eth.requestAccounts();
                } catch (error) {
                    console.error('User denied account access');
                }
                if (accounts.length === 0) {
                    throw new Error('No accounts available. If you are using MetaMask, please unlock and allow access to your accounts.');
                }
                const account = Web3.utils.toChecksumAddress(accounts[0]);  // Assuming you want to use the first account
                const [chainId, ethBalanceWei, scratchBalance] = await Promise.all([
                    web3.eth.getChainId(),
                    web3.eth.getBalance(account),
                    getScratchBalance(web3, account)
                ])

                setAddress(account);
                setChainId(parseFloat(chainId.toString()));  // Convert hexadecimal chainId to decimal
                setEthBalance(ethBalanceWei.toString());  // Convert balance (in Wei) from hex and then to decimal string
                setScratchBalance(scratchBalance);
            }

            init();

            const onAccountsChanged = async (accounts: string[]) => {
                const account = Web3.utils.toChecksumAddress(accounts[0]);
                const [ethBalanceWei, scratchBalance] = await Promise.all([
                    web3.eth.getBalance(account),
                    getScratchBalance(web3, account)
                ])
                setAddress(account);
                setEthBalance(ethBalanceWei.toString());  // Convert balance (in Wei) from hex and then to decimal string
                setScratchBalance(scratchBalance);
            };

            const onChainChanged = async (chainIdHex: string) => {
                const chainId = parseInt(chainIdHex, 16);
                setChainId(chainId);
            };

            web3.currentProvider?.on('accountsChanged', onAccountsChanged);
            web3.currentProvider?.on('chainChanged', onChainChanged);

            // Cleanup function to remove the listeners
            return () => {
                if (web3.currentProvider?.removeListener) {
                    web3.currentProvider.removeListener('accountsChanged', onAccountsChanged);
                    web3.currentProvider.removeListener('chainChanged', onChainChanged);
                }
            };
        }
    }, [web3]);


    return (
        <Web3Context.Provider value={{ web3, address, chainId, ethBalance, scratchBalance, setWeb3, disconnect }}>
            {children}
        </Web3Context.Provider>
    );
};