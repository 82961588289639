export const redeemBlurb = `
    Arcade $SCRATCH
    Each Arcade Game Pass will be redeemable for $SCRATCH to be used in our arcade. $SCRATCH is our arcade game currency, which can be used to play various games and earn rewards in PVP (Player v Player), PVF (Player v Farm), or PVL (Player v Leaderboard) games.
    Yellow = 200 $SCRATCH, Blue = 250 $SCRATCH, Green = 300 $SCRATCH, Red = 400 $SCRATCH

    Arcade Permanent Farm Points (PFP)
    Each game pass, when redeemed, will permanently boost your arcade Permanent Farm Points. These points are a factor used to determine seasonal rewards gained from arcade game costs that are distributed to the farms. Your farm points come from various areas within our ecosystem to calculate how much of the platform game costs are awarded to your account.
    Yellow = 1 Point, Blue = 3 Points, Green = 5 Points, Red = 10 Points

    Playground Tokens (PGTs)
    Each Arcade Game Pass will allow you to redeem for 2-3 Playground Game Tokens depending on the tier. Playground Game Tokens hold a mint value 30-50 CRO depending on the season and are redeemable for any regular season event (not the mini game events). Playground season rules including the CRO price of a game token is subject to change from season to season to accommodate community suggestions and market conditions.
    The playground is a king of the hill hands-off style game played on Cronos that anyone can enjoy on PC or mobile. In this game, your PGTs function similarly to a Royal Rumble event. The longer you survive, the higher your reward. Seasons 1 & 2 each had a prize pool of over 100k CRO + $SCRATCH.
    Yellow & Blue = 2 PGTs, Green & Red = 3 PGTs

    Merch Claim
    Each game pass will be redeemable for Boomer Squad official merch (SHIPPING NOT INCLUDED).
    Yellow = Sticker Set (Boomer Squad, Zoomer Squad, BS Logo, 3 Pack), Blue = Boomer Squad Official Collectable Coin, Green = Sticker Set + Collectable Coin, Red = Sticker Set + Collectable Coin + Boomer Squad Official Trucker Snapback Hat
    To Redeem
    Only the current owner of each Arcade Game Pass can redeem for the rewards. All Rewards will be redeemed at any time from now until 6 months after mint.
    To redeem you must transfer the NFT to the Cronos network and then bring the pass to our website Boomersquad.io where you will be able to follow instructions there to redeem. We are also on the Dapps section of the Defi Wallet.

    ZTB Labs Merch Store Discount Code
    Each game pass will give you access to a one-time discount code that can be used in our merch shop, providing a 15% discount on any item of your choice per Arcade Game Pass. ZTB Labs offers a wide range of items for sale, including crypto gear, Boomer Squad Gear, CROFAM gear, seasonal items, meme gear, and custom prints.
    `

export const redeemBlurbFormated = redeemBlurb.split('\n').map((item, i) => <p key={i}>{item}</p>)