import './infoBar.css'

export type GameInfo = {
    infoName: string;
    infoValue: string;
}

type InfoBarProps = {
    gameInfo: GameInfo[];
}

export const InfoBar: React.FC<InfoBarProps> = ({ gameInfo }) => {
    return (
        <div className='infobar'>
            {
                gameInfo.map((info, index) => {
                    return (
                        <>
                            <div
                                key={index}
                                className='infobar-info'
                            >
                                {info.infoName}:
                                <span className='infobar-info-value'>
                                    {info.infoValue}
                                </span>
                            </div>
                        </>

                    )
                })
            }
        </div>
    )
};