import React, { useState } from 'react';
import './gameHistory.css';
import { renderGameInfo } from './renderGameInfo';

type GameHistoryProps = {
    gameName: string;
    data: any[];
};

const GameHistory: React.FC<GameHistoryProps> = ({ gameName, data }) => {
    // Existing state and variables
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 20;
    const totalItems = data.length; // Replace with your actual data array
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [isDetailedView, setIsDetailedView] = useState(false);

    // Page navigation handlers
    const goToPage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        // Additional logic to load data for the selected page
    };

    // Sort data by timestamp
    const sortedData = [...data].sort((a, b) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className='game-history-container'>
            <div
                className={`non-selectable button small game-history-view-btn ${isDetailedView ? 'button-tertiary' : 'button-primary'}`}
                onClick={() => setIsDetailedView(v => !v)}
            >
                {isDetailedView ? 'Switch to Simple View' : 'Switch to Detailed View'}
            </div>
            <div className='game-history-pagination non-selectable'>

                <div
                    className='game-history-pagination-num button xxsmall button-secondary'
                    onClick={() => goToPage(Math.min(currentPage - 1 > 0 ? currentPage - 1 : currentPage))}
                >
                    «
                </div>
                {Array.from({ length: totalPages }, (v, i) => i).map(pageNumber => {

                    const className = `
                    game-history-pagination-num button xxsmall button-secondary 
                    ${currentPage === pageNumber ? 'active' : ''}
                    ${(currentPage - 1 > pageNumber || currentPage + 1 < pageNumber) &&
                            pageNumber.toString() !== '0' &&
                            pageNumber !== totalPages - 1 ?
                            'hidden' : ''
                        }`

                    return (
                        <>
                            {pageNumber === currentPage - 1 && currentPage - 1 > 1 && <span>...</span>}
                            <div
                                key={pageNumber}
                                onClick={() => goToPage(pageNumber)}
                                className={className}
                            >
                                {pageNumber + 1}
                            </div>
                            {pageNumber === currentPage + 1 && pageNumber + 1 < totalPages - 1 && <span>...</span>}
                        </>
                    )
                })}
                <div
                    className='game-history-pagination-num button xsmall button-secondary'
                    onClick={() => goToPage(Math.min(currentPage + 1 < totalPages ? currentPage + 1 : totalPages - 1))}
                >
                    »
                </div>
            </div>
            {renderGameInfo(gameName, currentItems, isDetailedView)}
        </div>

    );
};

export default GameHistory;
