export const navigateWithLoading = (
    path: string,
    togglePageLoad: (isLoading: boolean) => void,
    navigate: (path: string) => void
) => {
    togglePageLoad(true);
    navigate(path);
}

export const openInNewTab = (url: string) => {
    // Check if it's a valid URL
    if (url) {
        // Open a new tab with the specified URL
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}