import { DropDownMenu } from '../../../components/dropDownMenu/dropDownMenu';
import { useState } from 'react';
import './walletButton.css'
import { navigateWithLoading } from '../../../utils/navUtils';
import { useNavigate } from 'react-router-dom';
import { usePageLoad } from '../../../context/pageLoadContext';
import { useIsLoggedIn } from '../../../context/isLoggedInContext';

export const WalletButton = ({ userAddress }) => {

    const { togglePageLoad } = usePageLoad();
    const { logout } = useIsLoggedIn();

    // Navigation
    const navigate = useNavigate(); // Destructure navigate function

    const lastFourDigits = userAddress ? userAddress.slice(-4) : "----";

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropDown = () => {
        setIsOpen(!isOpen);
    }

    const dropDownButton =
        <div className={`non-selectable wallet-button ${isOpen && 'open'}`}>
            {lastFourDigits}<div className={`arrow ${isOpen && 'open'}`}></div>
        </div>

    const dropDownItems = [
        'Account',
        'Disconnect',
    ];
    const dropDownItemClicks = [
        () => navigateWithLoading('/myaccount', togglePageLoad, navigate),
        () => {
            navigate('/');
        },
    ]; // TODO: clear cookies

    return (
        <DropDownMenu
            button={dropDownButton}
            items={dropDownItems}
            itemOnClicks={dropDownItemClicks}
            isOpen={isOpen}
            toggleDropDown={toggleDropDown}
            position='left'
        />
    );
};
