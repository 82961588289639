import React, { ReactNode } from 'react';
import { DynamicTier } from '../../../../components/dynamicTier/dynamicTier';
import { formatDate } from '../../../../utils/dateUtils';
import { formatTxHash } from '../../../../utils/stringUtils';
import './tetrisFooter.css'
import { PVPGameState } from './tetrisPageTypes';
import { PaginatedList } from '../../../../components/paginatedList/paginatedList';

interface TetrisFooterProps {
    activeGames: PVPGameState[];
    gameHistory: PVPGameState[];
    userAddress: string;
}

export const TetrisFooter: React.FC<TetrisFooterProps> = ({ activeGames, gameHistory, userAddress }) => {
    return (
        <div className="tetris-page-footer">
            <ActiveGamesComponent activeGames={activeGames} />
            <GameHistoryComponent gameHistory={gameHistory} userAddress={userAddress} />
        </div>
    )
};

const ActiveGamesComponent: React.FC<{ activeGames: PVPGameState[] }> = ({ activeGames }) => {

    const gameList = getBlockbusterGameCardList(activeGames);

    return (
        <div className="active-games-container">
            <div className="active-games-title">
                Active Games
            </div>
            <div className='active-games-cards'>
                {gameList}
            </div>
        </div>
    );
};

const GameHistoryComponent: React.FC<{ gameHistory: PVPGameState[], userAddress: string }> = ({ gameHistory, userAddress }) => {

    const gameList = getBlockbusterGameCardList(gameHistory, userAddress);
    const itemsPerPage = 10;

    return (
        <div className="game-history-container">
            <div className="game-history-title">
                Game History
            </div>
            <PaginatedList items={gameList} itemsPerPage={itemsPerPage} containerClass={'active-games-cards'} />
        </div>
    );
};

const getBlockbusterGameCardList = (games: PVPGameState[], userAddress?: string): ReactNode[] => {
    const gameCardList: ReactNode[] = games.map((game, index) => {
        const checkEmpty = (value: string) => {
            if (!value || value === '..') {
                return ' - ';
            }
            return value;
        };

        const getBackgroundColor = () => {
            if (!game.winnerAddress || !userAddress) {
                return 'inplay';
            }
            if (game.winnerAddress === userAddress) {
                return 'win';
            } else {
                return 'lose';
            }
        }

        const GameDetailBox: React.FC<{ name: string, value: string, size: 'small' | 'big' }> = ({ name, value, size }) => {
            return (
                <div className={'game-details-box ' + size}>
                    <div className='game-details-box-name'>{name}</div>
                    <div className='game-details-box-value'>{value}</div>
                </div>
            )
        }

        const defenderScore = checkEmpty(game.defenderScore);
        const challengerScore = checkEmpty(game.challengerScore);
        const defenderAddress = checkEmpty(formatTxHash(game.defenderAddress));
        const challengerAddress = checkEmpty(formatTxHash(game.challengerAddress));
        const defenderFinishAt = checkEmpty(formatDate(game.defenderFinishAt));
        const challengerFinishAt = checkEmpty(formatDate(game.challengerFinishAt));

        return (
            <div key={index} className={"active-game-card " + getBackgroundColor()}>
                <div className="active-game-card-boxes">
                    <div className='game-details-box big'>
                        <DynamicTier tier={game.PVPTier} width={80} />
                    </div>
                    <GameDetailBox name='Defender' value={defenderAddress} size='small' />
                    <GameDetailBox name='Defender Score' value={defenderScore} size='small' />
                    <GameDetailBox name='Defended At' value={defenderFinishAt} size='big' />
                    <GameDetailBox name='Challenger' value={challengerAddress} size='small' />
                    <GameDetailBox name='Challenger Score' value={challengerScore} size='small' />
                    <GameDetailBox name='Challenged At' value={challengerFinishAt} size='small' />
                </div>
            </div>
        )
    })

    return gameCardList
};


