import Web3 from 'web3';

export const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    displaySetter: React.Dispatch<React.SetStateAction<string>>,
    weiSetter: React.Dispatch<React.SetStateAction<string>>) => {
    const newValue = event.target.value;
    // Regular expression to validate the input is a positive numeric value and allows up to 18 decimal places.
    const isValidNumber = /^\d*(\.\d{0,18})?$/.test(newValue);
    if (isValidNumber) {
        // It's a valid number; we can set the state.
        displaySetter(newValue);


        //convert to wei
        const weiValue = Web3.utils.toWei(newValue, 'ether');
        weiSetter(weiValue);

    } else {
        // It's not a valid number. Here you might want to provide feedback to the user.
        console.error("Invalid input: Please enter a valid number (up to 18 decimal places).");

        // Reset the state to '0' if the input is invalid.
        weiSetter('0');
        displaySetter('0');
    }
};

// This one also removes leading and trailing zeros
export const handleInputChangeWithMinMax = (
    event: React.ChangeEvent<HTMLInputElement>,
    displaySetter: React.Dispatch<React.SetStateAction<string>>,
    weiSetter: React.Dispatch<React.SetStateAction<string>>,
    minAmount: number,
    maxAmount: number
) => {
    const newValue = event.target.value;
    // Regular expression to validate the input is a positive numeric value and allows up to 18 decimal places.
    const isValidNumber = /^\d*(\.\d{0,18})?$/.test(newValue);
    const numericValue = parseFloat(newValue);

    if (isValidNumber && numericValue >= minAmount && numericValue <= maxAmount) {
        // It's a valid number within the specified range; set the state.
        // Remove leading and trailing zeros for display
        const displayValue = parseFloat(newValue).toString();
        displaySetter(displayValue);

        // Convert to wei
        const weiValue = Web3.utils.toWei(displayValue, 'ether');
        weiSetter(weiValue);
    } else {
        // It's not a valid number or not within the range. Here you might want to provide feedback to the user.
        console.error(`Invalid input: Please enter a valid number between ${minAmount} and ${maxAmount} (up to 18 decimal places).`);

        // Reset the state to '0' if the input is invalid or out of range.
        weiSetter('0');
        displaySetter('0');
    }
};