export const formatDate = (timestamp: string): string => {

    if (!timestamp) {
        return '';
    }

    const date = new Date(timestamp);
    const year = date.getFullYear().toString().substring(2); // Getting last two digits of the year
    const month = date.getMonth() + 1; // JavaScript months are 0-indexed
    const day = date.getDate();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

