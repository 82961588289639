import React from 'react';
import './App.css';
import './global.css';
import { Outlet } from 'react-router-dom';
import { setUnauthorizedCallback } from './authentication/unauthorizedHandler';
import { useErrorModal } from './context/errorModalContext';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // import styles

export const App: React.FC = () => {

    // Used to bring users back to home page when session expires
    const { showErrorModal } = useErrorModal()
    setUnauthorizedCallback(() => {
        showErrorModal('Session has expired, please re-enter arcade.');
    });

    return (
        <div className="App">
            {/** Settings to change default toast container styles */}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Outlet />
        </div>
    );
}