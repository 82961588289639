import { useState } from "react";
import { weiToEthWithDecimals } from "../../../web3/utils";
import { RouletteGameInfo } from "./gameHistoryTypes";
import { add } from "../../../utils/mathUtils";
import { doubleOCheck } from "../../games/roulette/rouletteUtils";

export const renderGameInfo = (gameName: string, currentItems: any, isDetailedView: boolean) => {
    switch (gameName) {
        case 'ROULETTE':
            return <RenderRouletteGameInfo currentItems={currentItems} isDetailedView={isDetailedView} />;
        default:
            return null;
    }
}

type RenderRouletteGameInfoParams = {
    currentItems: any;
    isDetailedView: boolean;
}

const RenderRouletteGameInfo: React.FC<RenderRouletteGameInfoParams> = ({ currentItems, isDetailedView }) => {

    // Return time since in the format of 1d 2h 3m, OR if under 1 minute, 1s
    const getTimeSince = (timestamp: string) => {
        const now = new Date();
        const newTimestamp = new Date(timestamp);
        const diff = now.getTime() - newTimestamp.getTime();
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        const remainingMinutes = minutes % 60;
        const remainingSeconds = seconds % 60;
        return remainingHours > 0 ? `${days}d ${remainingHours}h` : `${remainingMinutes}m ${remainingSeconds}s`;
    }

    const convertValue = (value: string) => {
        if (value === 'firstDozen'){
            return '1 to 12';
        }
        if (value === 'secondDozen'){
            return '13 to 24';
        }
        if (value === 'thirdDozen'){
            return '25 to 36';
        }
        if (value === 'first'){
            return '1 to 18';
        }
        if (value === 'second'){
            return '19 to 36';
        }
        if (value === 'red'){
            return 'Red';
        }
        if (value === 'black'){
            return 'Black';
        }

        return 'num ' + value;
    }

    // Used to give alternating color ot history table rows
    let isAltColor: boolean = false;

    const simpleTable = (
        <table className="game-history-roulette-info-table">
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Total Placed</th>
                    <th>Total Won</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                {
                    currentItems.map((item: any, index: number) => {
                        const timeSince = getTimeSince(item.timestamp);
                        const gameInfo: RouletteGameInfo = item.gameInfo;
                        const totalBet = gameInfo.bets.reduce((acc, bet) => add(acc, bet.amount), '0');
                        const totalWin = gameInfo.winAmounts.reduce((acc, win) => add(acc, win), '0');
                        isAltColor = !isAltColor;
                        return (
                            <tr key={index} style={{backgroundColor: `${isAltColor ? 'var(--secondary-dark)' : 'var(--soft-background)'}`}}>
                                <td>{timeSince}</td>
                                <td>{weiToEthWithDecimals(totalBet, 0)}</td>
                                <td>{weiToEthWithDecimals(totalWin, 0)}</td>
                                <td>{doubleOCheck(gameInfo.spinResult)}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

    const expandedTable = (
        <table className="game-history-roulette-info-table">
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Info</th>
                    <th>Placed</th>
                    <th>Won</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                {
                    currentItems.map((item: any, index: number) => {
                        const timeSince = getTimeSince(item.timestamp);
                        const gameInfo: RouletteGameInfo = item.gameInfo;
                        isAltColor = !isAltColor;
                        return gameInfo.bets.map((bet, index) => (
                            <tr key={index} style={{backgroundColor: `${isAltColor ? 'var(--secondary-dark)' : 'var(--soft-background)'}`}}>
                                <td>{timeSince}</td>
                                <td>{convertValue(doubleOCheck(bet.value))}</td>
                                <td>{weiToEthWithDecimals(bet.amount, 0)}</td>
                                <td>{weiToEthWithDecimals(gameInfo.winAmounts[index], 0)}</td>
                                <td>{doubleOCheck(gameInfo.spinResult)}</td>
                            </tr>
                        ))

                    })
                }
            </tbody>
        </table>
    )

    return (
        <>
            {isDetailedView ? expandedTable : simpleTable}
        </>
    )
}