// arcadeApi.tsx
import config from "../config";
import axios from "axios";
import { triggerUnauthorizedCallback } from '../authentication/unauthorizedHandler';
import { toast } from 'react-toastify';
import { PayloadRouletteBets, RouletteBetType } from "../nav/arcade/games/roulette/rouletteTypes";
import { DepositWithdrawHistoryRes } from "../types/apiResponse";


// load config variables from ENV
const BASE_API_URL = config.BASE_API_URL;

const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    withCredentials: true,
    // ... other axios settings
});

// axiosInstance.interceptors.response.use(
//     response => response, 
//     error => {
//         if (error.response && error.response.status === 401) {
//             triggerUnauthorizedCallback('Session has expired, please re-enter arcade.');
//         }
//         return Promise.reject(error);
//     }
// );

axiosInstance.interceptors.response.use(
    response => {
        // Check if the 'showToast' flag is true before showing the toast.
        if (response.config.headers.showToast) {
            // check to see if there is a valid message in the object
            if (response.data && response.data.message) {
                toast.success(response.data.message);
            }
        }
        return response;
    },
    error => {

        let errorMessage = 'Something went wrong';

        if (error.response) {
            if (error.response.status === 401) {
                triggerUnauthorizedCallback('Session has expired, please re-enter arcade.');
            } else {
                errorMessage = error.response.data && error.response.data.message
                    ? error.response.data.message
                    : errorMessage;
            }

            // Similar check for errors.
            if (error.config && error.config.headers.showToast) {

                if (error.response) {
                    errorMessage = error.response.data && error.response.data.message
                        ? error.response.data.message
                        : 'Something went wrong';
                } else if (error.request) {
                    errorMessage = "No response from the server. Please check your connection.";
                } else {
                    errorMessage = `Error: ${error.message}`;
                }

                toast.error(`Error: ${errorMessage}`);
            }

            return Promise.reject(error);
        }
    }
);

const arcadeApi = {
    // Authenticate user with signed message
    authenticateUser: async (userAddress: string, message: string, signature: string) => {
        try {
            const response = await axiosInstance.post('/auth/authenticate', {
                userAddress,
                message,
                signature
            });

            return response.data;
        } catch (error) {
            throw new Error('Authentication failed');
        }
    },

    // Check if user is still logged in
    checkUserIsLoggedIn: async () => {
        try {
            const response = await axiosInstance.get('/user/is_logged_in');
            return response.data;
        } catch (error) {
            throw new Error('Authentication failed');
        }
    },

    // Get user info by address
    getUserBalance: async () => {
        try {
            const response = await axiosInstance.get('/user/balance');

            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    getUserDepositWithdrawHistory: async (): Promise<DepositWithdrawHistoryRes | void> => {
        try {
            const response = await axiosInstance.get('/user/deposit_withdraw_history');

            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    getFarmBalance: async () => {
        try {
            const response = await axiosInstance.get('/user/farm/balance');

            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    buyFarmShares: async (scratchAmount: string) => {
        try {
            const response = await axiosInstance.post('/user/farm/buy_shares', {
                scratchAmount
            },
                { headers: { showToast: true } } // Adding the showToast flag in headers
            );
            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    sellFarmShares: async (shareAmount: string) => {
        try {
            const response = await axiosInstance.post('/user/farm/sell_shares', {
                shareAmount
            },
                { headers: { showToast: true } } // Adding the showToast flag in headers
            );
            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    startGameSession: async (gameType: string) => {
        try {
            const response = await axiosInstance.post('/user/game/create_game_session', {
                gameType: gameType,
            },
                { headers: { showToast: false } } // Adding the showToast flag in headers
            );
            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    getGameHistory: async (gameType: string) => {
        try {
            const response = await axiosInstance.post('/user/game/history', {
                gameType: gameType,
            },
                { headers: { showToast: false } } // Adding the showToast flag in headers
            );
            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    playRoulette: async (bets: PayloadRouletteBets, clientSeed: string) => {
        try {
            const response = await axiosInstance.post('/user/game/roulette', {
                bets,
                clientSeed,
            },
                { headers: { showToast: true } } // Adding the showToast flag in headers
            );
            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    withdrawScratch: async (amount: string) => {
        try {
            const response = await axiosInstance.post('/user/withdraw', {
                amount
            },
                { headers: { showToast: true } } // Adding the showToast flag in headers
            );
            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    getBalanceHistory: async () => {
        try {
            const response = await axiosInstance.get('/user/balance_history');

            return response.data;
        } catch (error: any) {
            if (error && error.response && error.response.data) {
                console.log(error.response.data);
            }
        }
    },

    sendGameScore: async (gameType: string, score: number) => {
        try {
            const response = await axiosInstance.post('/user/game/save_game_score', {
                gameType,
                score
            },
                { headers: { showToast: true } } // Adding the showToast flag in headers
            );
            return response.data;
        } catch (error) {
            throw new Error('Failed to send score');
        }
    },

    // Get high scores for the user and all users
    getHighScores: async (gameType: string) => {
        try {
            const response = await axiosInstance.post('/user/game/get_high_scores', {
                gameType
            });
            return response.data;
        } catch (error) {
            throw new Error('Failed to get high scores');
        }
    },

    // Get current blackjack game
    getBlackjackGame: async () => {
        try {
            const response = await axiosInstance.get('/user/game/blackjack/get_current_game');
            return response.data;
        } catch (error) {
            throw new Error('Failed to get blackjack game');
        }
    },

    // Get pass redeem info
    getPassRedeemInfo: async () => {
        try {
            const response = await axiosInstance.get('/user/pass_redeem_info');
            return response.data;
        } catch (error) {
            throw new Error('Failed to get pass redeem info');
        }
    },

    // get Blockbuster PVP game history
    getUserBlockbusterPVPGameHistory: async () => {
        try {
            const response = await axiosInstance.get('/user/game/blockbuster/pvp_game_history');
            return response.data;
        } catch (error) {
            throw new Error('Failed to fetch user blockbuster pvp game history');
        }
    },

    // Add other API request functions as needed...
};

export default arcadeApi;
