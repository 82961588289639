import './lobby.css'
import { useEffect } from 'react';
import { usePageLoad } from '../../../context/pageLoadContext';
import { useNavigate } from 'react-router-dom';
import { navigateWithLoading } from '../../../utils/navUtils';

export const Lobby = () => {

    const { togglePageLoad } = usePageLoad();

    // Navigation
    const navigate = useNavigate(); // Destructure navigate function

    useEffect(() => {
        // Stop loading on component mount. This is to prevent the loading screen from showing when navigating back to this page
        togglePageLoad(false);
    })

    return (
        <div className='page-lobby'>
            <div className='lobby-options-container'>
                <LobbySection title='ROULETTE' navigateWithLoading={() => navigateWithLoading('/roulette', togglePageLoad, navigate)} />
                <LobbySection title='HIT ME' navigateWithLoading={() => navigateWithLoading('/hitme', togglePageLoad, navigate)} />
                <LobbySection title='BLOCK BUSTER' navigateWithLoading={() => navigateWithLoading('/blockbuster', togglePageLoad, navigate)} />
                <LobbySection title='SCRATCH FARM' navigateWithLoading={() => navigateWithLoading('/farm', togglePageLoad, navigate)} />
                <LobbySection title='ZOOMER STAKING' navigateWithLoading={() => navigateWithLoading('/zoomerstaking', togglePageLoad, navigate)} />
                <LobbySection title='ACCOUNT' navigateWithLoading={() => navigateWithLoading('/myaccount', togglePageLoad, navigate)} />
                <LobbySectionUnderC title='horderunner' />
                <LobbySectionUnderC title='beerrun' />
            </div>
        </div>
    )
}

const LobbySection = ({ title, navigateWithLoading }: { title: string, navigateWithLoading: Function }) => {

    const titleWithNoSpaces = title.replace(/\s/g, '').toLowerCase();

    return (
        <div
            className={`lobby-section ${titleWithNoSpaces}`}
            onClick={() => {
                navigateWithLoading()
            }}>
            <img
                className="lobby-section-title"
                src={`titleCards/${titleWithNoSpaces}.png`}
            >
            </img>
        </div>
    )
}

const LobbySectionUnderC = ({ title }: { title: string }) => {

    const titleWithNoSpaces = title.replace(/\s/g, '').toLowerCase();

    return (
        <div
            className={`lobby-section-underc ${titleWithNoSpaces}`}
        >
            <img
                className="lobby-section-title-underc"
                src={`titleCards/${titleWithNoSpaces}.png`}
            >
            </img>
        </div>
    )
}