import { useEffect } from 'react';
import arcadeApi from '../requests/arcadeApi';

let isHeartbeatActive = false; // Global flag to track if heartbeat is already active

const useHeartbeat = (intervalMs = 60000) => { // Default to 60 seconds
    useEffect(() => {
        if (isHeartbeatActive) {
            console.log('Heartbeat already active, skipping new interval setup');
            return;
        }

        console.log('Starting new heartbeat interval');
        isHeartbeatActive = true; // Set the flag to indicate heartbeat is active

        const refreshToken = async () => {
            try {
                await arcadeApi.checkUserIsLoggedIn();
                console.log('Heartbeat successful');
            } catch (error) {
                console.error('Heartbeat failed:', error);
            }
        };

        // Call the function immediately and then set up the interval
        refreshToken();
        const intervalId = setInterval(refreshToken, intervalMs);

        return () => {
            clearInterval(intervalId);
            isHeartbeatActive = false; // Reset the flag when the component using the hook unmounts
            console.log('Heartbeat interval cleared');
        };
    }, [intervalMs]);
};

export default useHeartbeat;
