let unauthorizedCallback: (() => void) | null = null;

export const setUnauthorizedCallback = (callback: () => void) => {
    unauthorizedCallback = callback;
};

export const triggerUnauthorizedCallback = (message: string) => {
    if (unauthorizedCallback) {
        unauthorizedCallback();
    }
};