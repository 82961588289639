import React, { useState, ReactNode } from 'react';
import './paginatedList.css';

type PaginatedListProps = {
    items: ReactNode[];
    itemsPerPage: number;
    containerClass?: string;
};

export const PaginatedList: React.FC<PaginatedListProps> = ({ items, itemsPerPage, containerClass }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(items.length / itemsPerPage);

    const firstPage = () => setCurrentPage(1);
    const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    const nextPage = () => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages);
    const lastPage = () => setCurrentPage(totalPages);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = items.slice(startIndex, startIndex + itemsPerPage);

    const containerClassName = containerClass ? containerClass : 'paginatedList-container';

    return (
        <div>
            {/* Pagination controls */}
            <div className="paginatedList">
                <div className="paginatedList-btn" onClick={firstPage}>{'<<'}</div>
                <div className="paginatedList-btn" onClick={prevPage}>{'<'}</div>
                PAGE {currentPage} / {totalPages}
                <div className="paginatedList-btn" onClick={nextPage}>{'>'}</div>
                <div className="paginatedList-btn" onClick={lastPage}>{'>>'}</div>
            </div>
            {/* Render the current page's items */}
            <div className={containerClassName} >
                {paginatedItems}
            </div>
        </div>
    );
};
