import { useEffect, useState } from "react";
import { Bet, ColorBetType, ColorValues, EvenOddBetType, EvenOddValues, HalvesBetType, HalvesValues, NumberBetType, NumberValues, RouletteBetType, RouletteBets, ThirdsBetType, ThirdsValues } from "../../../nav/arcade/games/roulette/rouletteTypes";
import { squaresWithDegrees } from "./rouletteSquares";
import { Chip } from "../gameAssets/chip";
import { weiToEthWithDecimals } from "../../../web3/utils";
import Web3 from "web3";
import { doubleOCheck } from "./rouletteUtils";
import './rouletteBetting.css';

type RouletteBettingProps = {
    visibleSpinResult: number | null,
    bets: Bet<RouletteBetType>[],
    setBets: React.Dispatch<React.SetStateAction<Bet<RouletteBetType>[]>>,
    previousRoundWin: number,
    previousRoundBet: number,
    arcadeScratchBalance: string | null,
    canInteract: () => boolean,
    triggerUpdate: boolean,
    fee: string,
}

// The main RouletteBetting component
export const RouletteBetting: React.FC<RouletteBettingProps> = ({
    visibleSpinResult,
    bets,
    setBets,
    previousRoundWin,
    previousRoundBet,
    arcadeScratchBalance,
    canInteract,
    triggerUpdate,
    fee
}) => {

    // Max size of the spin result history = 40
    const [spinResultHistory, setSpinResultHistory] = useState<string[]>([]);
    const [selectedChip, setSelectedChip] = useState<ChipValue>({ amount: 1, color: 'red' });

    const MAX_BET = 30;

    useEffect(() => {
        // Add visible spin result to history
        if (visibleSpinResult !== null) {
            let currentHistory = spinResultHistory;
            let newHistory: string[];
            // if the currentSpinResult length is 40, remove the last element
            if (currentHistory.length === 40) {
                currentHistory.pop();
            }
            // Add result to the beginning of the array
            newHistory = [doubleOCheck(visibleSpinResult), ...spinResultHistory];

            setSpinResultHistory(newHistory);
        }
    }, [visibleSpinResult, triggerUpdate]);

    const handleAddBet = (type: string, value: string | number, amount: number): Bet<RouletteBetType> | null => {
        switch (type) {
            case 'evenOdd':
                return { type: 'evenOdd', value, amount } as Bet<EvenOddBetType>;
            case 'halves':
                return { type: 'halves', value, amount } as Bet<HalvesBetType>;
            case 'thirds':
                return { type: 'thirds', value, amount } as Bet<ThirdsBetType>;
            case 'color':
                return { type: 'color', value, amount } as Bet<ColorBetType>;
            case 'number':
                return { type: 'number', value, amount } as Bet<NumberBetType>;
            default:
                throw new Error('Invalid bet type');
        }
    };

    const getCurrentTotalBetAmount = () => {
        return bets.reduce((acc, bet) => acc + bet.amount, 0);
    }

    const canIncreaseBetAmountTotal = (amount: number): boolean => {
        return (
            getCurrentTotalBetAmount() + amount <= MAX_BET &&
            arcadeScratchBalance !== null &&
            getCurrentTotalBetAmount() + amount <= parseFloat(Web3.utils.fromWei(arcadeScratchBalance, 'ether'))
        )
    }

    // Higher-order function that takes the original click handler and returns a new function
    const withClickGuard = (originalOnClick: any) => {
        // Return a function that takes the necessary arguments
        return (...args: any[]) => {
            // The returned function is what gets called on click
            if (canInteract()) {
                originalOnClick(...args);
            } else {
                // Handle the "not allowed" case here
                console.log('Click not allowed');
            }
        };
    };

    // Clear bets
    const canClear = (): boolean => {
        return bets.length > 0 && canInteract();
    }

    const onClearClick = () => {
        if (canClear()) {
            setBets([]);
        }
    }

    const canDoubleBets = (): boolean => {
        return canIncreaseBetAmountTotal(getCurrentTotalBetAmount()) && canInteract() && bets.length > 0;
    }

    const onDoubleBetsClick = () => {
        if (canDoubleBets()) {
            const newBets = bets.map((bet) => ({ ...bet, amount: bet.amount * 2 }));
            setBets(newBets);
        }
    }

    const canHalfBets = (): boolean => {
        return bets.length > 0 && canInteract();
    }

    const onHalfBetsClick = () => {
        if (canHalfBets()) {
            const newBets = bets
                .map((bet) => ({ ...bet, amount: Math.floor(bet.amount / 2) }))
                .filter((bet) => bet.amount > 0);
            setBets(newBets);
        }
    }

    const canChipClick = (): boolean => {
        return canInteract();
    }

    const onChipClick = (chipValue: ChipValue): void => {
        if (canChipClick()) {
            setSelectedChip(chipValue);
        }
    }

    const canSquareClick = (): boolean => {
        return (
            canInteract() &&
            canIncreaseBetAmountTotal(selectedChip.amount)
        )
    }

    const onSquareClick = (
        type: 'evenOdd' | 'halves' | 'thirds' | 'color' | 'number',
        value: EvenOddValues | HalvesValues | ThirdsValues | ColorValues | NumberValues
    ) => {
        if (canSquareClick()) {
            // Check to see if the bet type already exists
            if (checkBetExists(type, value)) {
                // If it does exist, add the amount of the selected chip to the bet
                const newBets = bets.map((bet) => {
                    if (bet.type === type && bet.value === value) {
                        return { ...bet, amount: bet.amount + selectedChip.amount };
                    }
                    return bet;
                });
                setBets(newBets);
            } else {
                // If it doesnt exist, create the bet and add it to the bets array
                const newbet = handleAddBet(type, value, selectedChip.amount);
                if (!newbet) {
                    return null
                }
                const newBets = bets.concat(newbet);
                setBets(newBets);
            }
        }
    }

    const guardedOnClearClick = withClickGuard(onClearClick);
    const guardedOnDoubleBetsClick = withClickGuard(onDoubleBetsClick);
    const guardedOnHalfBetsClick = withClickGuard(onHalfBetsClick);
    const guardedOnChipClick = withClickGuard(onChipClick);
    const guardedOnSquareClick = withClickGuard(onSquareClick);

    const orderedSquares = squaresWithDegrees.sort((a, b) => a.number - b.number);

    type ChipValue = {
        amount: number;
        color: 'red' | 'black' | 'green' | 'blue';
    }

    const chipValues: ChipValue[] = [
        { amount: 1, color: 'red' },
        { amount: 5, color: 'blue' },
        { amount: 10, color: 'green' },
        { amount: 25, color: 'black' },
    ]

    const checkBetExists = (
        type: 'evenOdd' | 'halves' | 'thirds' | 'color' | 'number',
        value: EvenOddValues | HalvesValues | ThirdsValues | ColorValues | NumberValues
    ) => {
        return bets.some((bet) => bet.type === type && bet.value === value);
    }



    type ChipDisplayParams = {
        type: 'evenOdd' | 'halves' | 'thirds' | 'color' | 'number';
        value: EvenOddValues | HalvesValues | ThirdsValues | ColorValues | NumberValues;
    }

    const ChipDisplay: React.FC<ChipDisplayParams> = ({ type, value }) => {
        // Find a bet that matches the type and value, if found display the bet amount
        const bet = bets.find((bet) => bet.type === type && bet.value === value);
        //determine chip color from amount
        if (!bet) {
            return null;
        }
        const chipColor = bet.amount < 5 ? 'red' : bet.amount < 10 ? 'blue' : bet.amount < 25 ? 'green' : 'black';
        return (
            <div className='roulette-chip-display'>
                <Chip size={40} color={chipColor} amount={bet.amount} />
            </div>
        )
    }

    type RouletteGridSquareProps = {
        square: { number: number; color?: string }; // Adjust the type as necessary
        classes: string;
    };

    const RouletteGridSquare: React.FC<RouletteGridSquareProps> = ({ square, classes }) => {

        return (
            <div
                className={classes}
                onClick={() => guardedOnSquareClick('number', square.number as NumberValues)}
            >
                {square.number === 37 ? '00' : square.number}
                <ChipDisplay type={'number'} value={square.number as NumberValues} />
            </div>
        );
    };

    type OuterBetObj = {
        className: string;
        betType: 'evenOdd' | 'halves' | 'thirds' | 'color' | 'number';
        betValue: EvenOddValues | HalvesValues | ThirdsValues | ColorValues | NumberValues;
        betText: string;
    }

    type OuterBetSquaresDisplayParams = {
        outerBetObjs: OuterBetObj[];

    };

    const outerBetObjs: OuterBetObj[] = [
        {
            className: 'half-square-top',
            betType: 'halves',
            betValue: 'first',
            betText: '1 to 18',
        },
        {
            className: 'half-square-bottom',
            betType: 'halves',
            betValue: 'second',
            betText: '19 to 36',
        },
        {
            className: 'third-square-top',
            betType: 'thirds',
            betValue: 'firstDozen',
            betText: '1 to 12',
        },
        {
            className: 'third-square-middle',
            betType: 'thirds',
            betValue: 'secondDozen',
            betText: '13 to 24',
        },
        {
            className: 'third-square-bottom',
            betType: 'thirds',
            betValue: 'thirdDozen',
            betText: '25 to 36',
        },
        {
            className: 'black-square',
            betType: 'color',
            betValue: 'black',
            betText: 'BLACK',
        },
        {
            className: 'red-square',
            betType: 'color',
            betValue: 'red',
            betText: 'RED',
        },
    ]

    const OuterBetSquaresDisplay: React.FC<OuterBetSquaresDisplayParams> = ({ outerBetObjs }) => {
        return (
            <>
                {outerBetObjs.map((outerBetObj) => (
                    <div
                        key={outerBetObj.betText}
                        className={
                            `roulette-grid-square 
                            ${outerBetObj.className}
                            ${canSquareClick() ? '' : 'disabled'}`
                        }
                        onClick={() => guardedOnSquareClick(outerBetObj.betType, outerBetObj.betValue)}
                    >
                        <div className='sideways-text'>{outerBetObj.betText}</div>
                        <ChipDisplay type={outerBetObj.betType} value={outerBetObj.betValue} />
                    </div>
                ))}
            </>
        )
    }

    return (
        <div
            className='non-selectable'>
            <div className='roulette-panel'>
                <div className='roulette-panel-row-container' style={{ borderRadius: '10px 10px 0px 0px' }}>
                    <div className={`button-primary button small ${canClear() ? '' : 'disabled'}`} onClick={guardedOnClearClick}>CLEAR</div>
                    <div
                        className={
                            `button-secondary button small
                            ${canDoubleBets() ? '' : 'disabled'}`
                        }
                        onClick={guardedOnDoubleBetsClick}
                    >
                        2X
                    </div>
                    <div className={`button-secondary button small ${canHalfBets() ? '' : 'disabled'}`} onClick={guardedOnHalfBetsClick}>1/2</div>
                </div>
                <div className='roulette-panel-row-container'>
                    {
                        chipValues.map((chipValue) => {

                            const style = selectedChip ?
                                selectedChip.amount === chipValue.amount ? { backgroundColor: 'var(--primary-button)' } : {} : {};

                            return (
                                <div
                                    className={`roulette-panel-chip-btn button ${canChipClick() ? '' : 'disabled'}`}
                                    key={chipValue.color}
                                    style={style}
                                    onClick={() => guardedOnChipClick(chipValue)}
                                >
                                    <Chip size={50} color={chipValue.color} amount={chipValue.amount} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className='roulette-panel-row-container userbalance'>
                    Arcade Scratch Balance:
                    <span style={{ color: 'var(--primary-button)' }}>{arcadeScratchBalance && weiToEthWithDecimals(arcadeScratchBalance, 2)}</span>
                </div>
            </div>
            <div className="roulette-bet-board-container">
                <div>
                    <div className='roulette-grid-scoreboard'>
                        <div className="roulette-grid-scoreboard-column">
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">{'CURRENT:'}</span>
                                <span className="scoreboard-text-right">{bets.reduce((acc, bet) => acc + bet.amount, 0)}</span>
                            </div>
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">{'PLAY COST:'}</span>
                                <span className="scoreboard-text-right">
                                    {bets.reduce((acc, bet) => acc + bet.amount, 0) * ((parseFloat(fee) / 100))}
                                </span>
                            </div>
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">{'LAST result:'}</span>
                                <span className="scoreboard-text-right">{visibleSpinResult ? doubleOCheck(visibleSpinResult) : 'N/A'}</span>
                            </div>
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">{'PRV RND WIN:'}</span>
                                <span className="scoreboard-text-right">{previousRoundWin}</span>
                            </div>
                        </div>
                        <div className="roulette-grid-scoreboard-column">
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">&nbsp;</span>
                                <span className="scoreboard-text-right">&nbsp;</span>
                            </div>
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">{'MAX:'}</span>
                                <span className="scoreboard-text-right">{MAX_BET}</span>
                            </div>
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">{'MIN:'}</span>
                                <span className="scoreboard-text-right">1</span>
                            </div>
                            <div className="roulette-grid-scoreboard-row-section">
                                <span className="scoreboard-text-left">{'PRV RND:'}</span>
                                <span className="scoreboard-text-right">{previousRoundBet}</span>
                            </div>
                        </div>
                    </div>
                    <div className="roulette-grid">
                        <div className="roulette-grid-logo top">Boomer<span className='roulette-grid-logo-accent'>Arcade</span></div>
                        {/* Top square */}
                        <RouletteGridSquare
                            square={orderedSquares[0]}
                            classes={
                                `roulette-grid-square roulette-grid-top-square
                                ${canSquareClick() ? '' : 'disabled'}`
                            }
                        />

                        {/* Middle squares */}
                        {orderedSquares.slice(1, 37).map((square) => (
                            <RouletteGridSquare
                                key={square.number}
                                square={square}
                                classes={
                                    `roulette-grid-square roulette-grid-middle-square 
                                    ${square.color === 'red' ? 'red' : 'black'}
                                    ${canSquareClick() ? '' : 'disabled'}`
                                }
                            />
                        ))}

                        {/* Bottom square */}
                        <RouletteGridSquare
                            square={orderedSquares[orderedSquares.length - 1]}
                            classes={
                                `roulette-grid-square roulette-grid-bottom-square
                                ${canSquareClick() ? '' : 'disabled'}`
                            }
                        />

                        {/* Outer bet squares */}
                        <OuterBetSquaresDisplay outerBetObjs={outerBetObjs} />
                        <div className="roulette-grid-logo bottom upsidedown-text">Boomer<span className='roulette-grid-logo-accent'>Arcade</span></div>
                    </div>
                </div>
                <div className="roulette-bet-board-history">
                    <div className="roulette-bet-board-history-container">
                        {spinResultHistory.map((result, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`roulette-bet-board-history-row ${orderedSquares.find((square) => square.number.toString() === result)?.color}`}
                                >
                                    {result}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div >
    );
};