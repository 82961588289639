import { TetrisBlock } from '../tetrisTypes';
import { getTetroId } from '../tetrisUtils';
import './tetrisCell.css'

type TetrisCellProps = {
    cellValue: TetrisBlock;
}

export const TetrisCell: React.FC<TetrisCellProps> = ({ cellValue }) => {

    const cellValueIdentifier = getTetroId(cellValue);

    return (
        <div className={`tetrisCell ${cellValueIdentifier}`} />
    )
}