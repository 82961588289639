import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// CSS
import './index.css';

// Navigation Comps
import { App } from './App';
import { Home } from './nav/home/home';
import { Help } from './nav/help/help';
import { Header } from './header/header';
import { PageNotFound } from './nav/pageNotFound/pageNotFound';
import { MyAccount } from './nav/arcade/myAccount/myAccount';

// Context Providers
import { Web3Provider } from './context/web3Context';
import { ModalProvider } from './context/modalContext';
import { PageLoadProvider } from './context/pageLoadContext';
import { ErrorModalProvider } from './context/errorModalContext';
import { Lobby } from './nav/arcade/lobby/lobby';
import { Farm } from './nav/arcade/farm/farm';
import { RoulettePage } from './nav/arcade/games/roulette/roulettePage';
import { ZoomerStaking } from './nav/arcade/zoomerStaking/zoomerStaking';
import { BlackjackPage } from './nav/arcade/games/blackjack/blackjackPage';
import { PassRedeem } from './nav/arcade/passRedeem/passRedeem';
import { TetrisPage } from './nav/arcade/games/tetris/tetrisPage';
import { Hud } from './hud/hud';
import { ArcadeBalanceProvider } from './context/arcadeBalanceContext';
import { IsLoggedInProvider } from './context/isLoggedInContext';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const provider = window.ethereum || null;

root.render(
	<PageLoadProvider>
		<ErrorModalProvider>
			<ModalProvider>
				<IsLoggedInProvider>
					<ArcadeBalanceProvider>
						<Web3Provider provider={provider}>
							<React.StrictMode>
								<BrowserRouter>
									<Header />
									<Hud />
									<Routes>
										<Route path="/" element={<App />}>
											<Route path="/" element={<Home />} />
											<Route path="help" element={<Help />} />
											<Route path="lobby" element={<Lobby />} />
											<Route path="farm" element={<Farm />} />
											<Route path="myaccount" element={<MyAccount />} />
											<Route path='passredeem' element={<PassRedeem />} />

											{/* Games */}
											<Route path="roulette" element={<RoulettePage />} />
											<Route path="blockbuster" element={<TetrisPage />} />
											<Route path="blockbusterbeta" element={<TetrisPage />} />
											<Route path="hitme" element={<BlackjackPage />} />
											{/* Games */}

											<Route path="zoomerstaking" element={<ZoomerStaking />} />
											<Route path="*" element={<PageNotFound />} />
										</Route>
									</Routes>
								</BrowserRouter>
							</React.StrictMode>
						</Web3Provider>
					</ArcadeBalanceProvider>
				</IsLoggedInProvider>
			</ModalProvider >
		</ErrorModalProvider>
	</PageLoadProvider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
