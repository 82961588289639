import config from '../../config'
import { approveContractForAll, checkIsApprovedForAll } from './generalMethods';

// Load config variables from ENV
const zoomersContractAddress = '0x3D03292D428fd7d6C81a6E76b4A333A3d8a0E296'
const arcadeStakingContractAddress = config.ARCADE_STAKING_CONTRACT_ADDRESS;

// Check if the user has approved the arcade staking contract to spend their zoomers
export const checkIsApprovedForAllZoomers = async (web3: any, account: string): Promise<boolean> => {

    const approved = await checkIsApprovedForAll(web3, account, arcadeStakingContractAddress, zoomersContractAddress)

    return approved;
}

export const approveArcadeStakingContract = async (web3: any, account: string): Promise<any> => {

    const tx = await approveContractForAll(web3, account, arcadeStakingContractAddress, zoomersContractAddress)

    return tx;
}