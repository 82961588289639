import { useState, useEffect } from 'react';
import './hud.css';
import './hudMobile.css';
import { isBiggerThan, subtract } from '../utils/mathUtils';
import { weiToEthWithDecimals } from '../web3/utils';
import { DynamicIcon } from '../components/dynamicIcon/dynamicIcon';
import { useArcadeBalance } from '../context/arcadeBalanceContext';
import { useIsLoggedIn } from '../context/isLoggedInContext';
import ButtonSpinner from '../components/spinners/buttonSpinner';

export const Hud = () => {

    // Use the context-based hook to get the current arcade balance and the refresh function
    const {
        arcadeScratchBalance,
        arcadeFarmShareBalance,
        arcadeZoomerBalance,
        arcadePfpBalance,
        refreshBalance,
    } = useArcadeBalance();

    // Context for logged in
    const { isLoggedIn } = useIsLoggedIn();

    const [previousBalance, setPreviousBalance] = useState('0');
    const [balanceChange, setBalanceChange] = useState('');
    const [showBalanceChange, setShowBalanceChange] = useState(false);
    const [isPositive, setIsPositive] = useState(false);
    const [isExtended, setIsExtended] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);

    const refreshWithLoading = async () => {
        setLoading(true);
        await refreshBalance();
        // wait an extra second
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const toggleExtend = () => setIsExtended(!isExtended);


    useEffect(() => {
        if (arcadeScratchBalance !== previousBalance) {
            const difference = subtract(arcadeScratchBalance, previousBalance);
            setIsPositive(isBiggerThan(difference, '0'));
            setBalanceChange(difference);
            setPreviousBalance(arcadeScratchBalance);

            // Dont show the balance change on the first load
            if (!firstLoad) {
                setShowBalanceChange(true);
                // Trigger the fade-out animation
                setTimeout(() => {
                    setShowBalanceChange(false);
                }, 3000); // Adjust time as needed
            } else {
                setFirstLoad(false);
            }
        }
    }, [previousBalance, arcadeScratchBalance]);

    const HudBalanceDisplay: React.FC<{ iconName: string, value: string }> = ({ iconName, value }) => {
        const iconSize = 25;
        return (
            <div className="hud-balance-display">
                <DynamicIcon name={iconName} width={iconSize} /> {!loading ? value : <ButtonSpinner size={20} />}
            </div>
        )
    }

    const MobileHudBalanceDisplay: React.FC<{ iconName: string, value: string }> = ({ iconName, value }) => {
        const iconSize = 25;
        return (
            <div className="mobile-hud-balance-display">
                <DynamicIcon name={iconName} width={iconSize} /> {!loading ? value : <ButtonSpinner size={20} />}
            </div>
        )
    }

    // Mobile HUD becomes invisible when screen width is more than 1200
    // Desktop HUD becomes invisible when screen width is less than 1200

    return (
        <>
            {/*DESKTOP HUD*/}
            {
                isLoggedIn &&
                <div className={`non-selectable hud-fixed-position ${isExtended ? '' : 'hud-retracted'}`}
                    onClick={toggleExtend}
                    onMouseEnter={(e) => e.currentTarget.classList.add(isExtended ? 'hud-hover-extended' : 'hud-hover-retracted')}
                    onMouseLeave={(e) => e.currentTarget.classList.remove('hud-hover-extended', 'hud-hover-retracted')}>
                    <div className='hud-balance-container'>
                        <div className='hud-balance-title'>
                            Arcade Balance
                            <div
                                className='hud-refresh'
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent click event from bubbling up
                                    refreshWithLoading();
                                }}
                            >
                                <DynamicIcon name='REFRESH' width={20} />
                            </div>
                        </div>
                        <div className='hud-balance-display-container'>
                            <HudBalanceDisplay iconName='SCRATCH' value={weiToEthWithDecimals(arcadeScratchBalance, 2)} />
                            <HudBalanceDisplay iconName='FS' value={weiToEthWithDecimals(arcadeFarmShareBalance, 2)} />
                            <HudBalanceDisplay iconName='ZOOMER' value={arcadeZoomerBalance} />
                            <HudBalanceDisplay iconName='PFP' value={arcadePfpBalance} />
                        </div>
                        {showBalanceChange && (
                            <div className={`hud-balance-change ${isPositive ? 'increase' : 'decrease'}`}>
                                {isPositive ? '+' : ''}{weiToEthWithDecimals(balanceChange, 2)}
                            </div>
                        )}
                    </div>
                </div>
            }
            {/**MOBILE HUD*/}
            {
                isLoggedIn &&
                <div className={`non-selectable mobile-hud-fixed-position ${isExtended ? '' : 'mobile-hud-retracted'}`}
                    onClick={toggleExtend}
                >
                    <div className='mobile-hud-balance-container'>
                        <div className='hud-balance-title'>
                            Arcade Balance
                        </div>
                        <div className='mobile-hud-balance-display-container'>
                            <div className='mobile-hud-balance-display-container-extras'>
                                <HudBalanceDisplay iconName='SCRATCH' value={weiToEthWithDecimals(arcadeScratchBalance, 2)} />
                                <div
                                    className='hud-refresh'
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent click event from bubbling up
                                        refreshWithLoading();
                                    }}
                                >
                                    <DynamicIcon name='REFRESH' width={20} />
                                </div>
                            </div>

                            <div className='mobile-hud-balance-display-container-extras'>
                                <MobileHudBalanceDisplay iconName='FS' value={weiToEthWithDecimals(arcadeFarmShareBalance, 2)} />
                                <MobileHudBalanceDisplay iconName='ZOOMER' value={arcadeZoomerBalance} />
                                <MobileHudBalanceDisplay iconName='PFP' value={arcadePfpBalance} />
                            </div>

                        </div>
                        {showBalanceChange && (
                            <div className={`mobile-hud-balance-change ${isPositive ? 'increase' : 'decrease'}`}>
                                {isPositive ? '+' : ''}{weiToEthWithDecimals(balanceChange, 2)}
                            </div>
                        )}
                    </div>
                </div>
            }
        </>
    );
};