import axios from "axios";

// load config variables from ENV
const BASE_API_URL = 'https://api.boomersquad.io';

const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    // ... other axios settings
});

const boomerApi = {
    // Authenticate user with signed message
    getWalletTokens: async (userAddress: string, contractAddress: string) => {
        try {
            const response = await axiosInstance.post('/fetch_metadata', {
                account: userAddress,
                contractAddress: contractAddress
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Get Wallet Zoomers Failed');
        }
    },

    getWalletTokenIds: async (userAddress: string, contractAddress: string) => {
        try {
            const response = await axiosInstance.post('/fetch_tokenids', {
                account: userAddress,
                contractAddress: contractAddress
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Get Wallet Zoomers Failed');
        }
    },

    getBoomRoomStakingStatsV2: async (userAddress: string) => {
        try {
            const response = await axiosInstance.post('/boomroom/staking_statsV2', { account: userAddress },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

            return response.data

        } catch (error) {
            throw new Error('Get Boom Room Stats');
        }
    },
}

export default boomerApi;