type DynamicTierProps = {
    tier: string;
    width: number;
}

export const DynamicTier: React.FC<DynamicTierProps> = ({ tier, width }) => {

    const getSrc = (tier: string) => {
        switch (tier) {
            case 'BRONZE':
                return 'https://arcade-web-images.s3.amazonaws.com/icons/BRONZE_WITH_BORDER2.png';
            case 'SILVER':
                return 'https://arcade-web-images.s3.amazonaws.com/icons/SILVER_WITH_BORDER.png';
            case 'GOLD':
                return 'https://arcade-web-images.s3.amazonaws.com/icons/GOLD_WITH_BORDER2.png';
            default:
                return 'https://arcade-web-images.s3.amazonaws.com/icons/BRONZE_WITH_BORDER.png';
        }
    }

    const getClr = () => {
        switch (tier) {
            case 'BRONZE':
                return '#955104';
            case 'SILVER':
                return 'silver';
            case 'GOLD':
                return 'gold';
            default:
                return '#955104';
        }
    }

    const boxShadowWidth = width / 10;

    return (
        <img
            src={getSrc(tier)}
            style={
                {
                    width: `${width}px`,
                    flexGrow: 'none',
                    flexShrink: 'none',
                    boxShadow: `0px 0px ${boxShadowWidth}px 0px ${getClr()}`
                }
            }
            alt={tier}
        />

    )
};