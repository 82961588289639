import signMessage from '../../authentication/signMessage'
import { useWeb3 } from '../../context/web3Context';
import { useModal } from '../../context/modalContext';
import { usePageLoad } from '../../context/pageLoadContext';
import { useNavigate } from 'react-router-dom';

import './home.css'
import config from '../../config';
import { useIsLoggedIn } from '../../context/isLoggedInContext';
import { DynamicIcon } from '../../components/dynamicIcon/dynamicIcon';
import { useEffect } from 'react';

export const Home = () => {

    const { web3, address } = useWeb3();
    const { toggleModal } = useModal();
    const { togglePageLoad } = usePageLoad();
    const { login, logout } = useIsLoggedIn();

    const navigate = useNavigate();

    const handleEnterArcadeClick = () => {
        if (web3 && address) {
            togglePageLoad(true);
            signMessage(web3, address)
                .then((res) => {
                    console.log('res:', res)
                    if (res && res.message === "Authentication successful") {
                        // send users to account page
                        navigate('/lobby')
                        login();
                    } else {
                        togglePageLoad(false);
                    }
                })
                .catch((err) => {
                    console.log('err:', err)
                    togglePageLoad(false);
                })
        } else {
            console.log('web3 or address not found')
        }
    }

    useEffect(() => {
        logout();
    }, [logout])


    return (
        <div className='home page'>
            <div className="home-bg" />
            {web3 && address ?
                <div className='button-tertiary button xxlarge enter-button'
                    onClick={handleEnterArcadeClick}>
                    <DynamicIcon name='LOGIN' width={40} />Enter Arcade
                </div>
                :
                <div className='button-primary button xxlarge enter-button'
                    onClick={toggleModal}>Connect Wallet
                </div>
            }
            <div className='home-logo-container'>
                <img className='home-logo-circle' src={config.WEB_IMAGE_URL + 'logos/bs_logo_circle_500x.webp'} alt='logo' />
                <img className='home-logo-circle' src={config.WEB_IMAGE_URL + 'logos/boomerarcade_logo2_circle_500x.webp'} alt='logo' />
                <img className='home-logo-circle' src={config.WEB_IMAGE_URL + 'logos/zoomersquad_logo_circle_500x.webp'} alt='logo' />
            </div>
        </div>
    )
}