import { Web3 } from 'web3';
import { DeFiWeb3Connector } from "@deficonnect/web3-connector";



interface ConnectDeFiProps {
  setWeb3: React.Dispatch<React.SetStateAction<Web3 | null>>;
}

export const connectDeFi = async ({ setWeb3 }: ConnectDeFiProps) => {

  const connector = new DeFiWeb3Connector({
    appName: 'BoomerArcade',
    chainType: 'eth',
    chainId: '25', // for eth is 1
    rpcUrls: {
      1: '',
      25: 'https://evm-cronos.crypto.org/',
    },
  })

  await connector.activate();
  const provider = await connector.getProvider();
  const web3 = new Web3(provider);
  setWeb3(web3);
};
