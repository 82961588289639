import './chip.css';

enum ChipColors {
    red = '#DC143C',
    black = '#141414',
    green = '#228B22',
    blue = '#3F00FF',
}

type ChipProps = {
    size: number;
    color: 'red' | 'black' | 'green' | 'blue';
    amount: number;
}

export const Chip: React.FC<ChipProps> = ({ size, color, amount }) => {

    const hexColor = (
        color === 'red' ? ChipColors.red :
            color === 'black' ? ChipColors.black :
                color === 'green' ? ChipColors.green :
                    ChipColors.blue
    )

    function darkenColor(colorStr: string, amount: number) {
        // Assumes input is in the format "#RRGGBB"
        let color = colorStr.substring(1); // strip the leading #
        let rgb = parseInt(color, 16); // convert rrggbb to decimal
        let r = (rgb >> 16) & 0xff; // extract red
        let g = (rgb >> 8) & 0xff; // extract green
        let b = (rgb >> 0) & 0xff; // extract blue

        r = Math.max(0, r - amount); // darker red
        g = Math.max(0, g - amount); // darker green
        b = Math.max(0, b - amount); // darker blue

        return "#" + (0x1000000 + r * 0x10000 + g * 0x100 + b).toString(16).slice(1);
    }

    return (
        <div
            className="chip"
            style={{
                width: `${size}px`,
                height: `${size}px`,
                backgroundColor: hexColor,
                boxShadow: `0 ${size / 20}px 0 ${darkenColor(hexColor, 100)}`,
            }}
        >
            <div
                className='chip-inner-circle'
                style={{ borderWidth: `${size / 40}px` }}
            />
            <div className='chip-outer-white one' />
            <div className='chip-outer-white two' />
            <div className='chip-outer-white three' />
            <div className='chip-outer-white four' />
            <div className='chip-outer-white five' />
            <div className='chip-outer-white six' />
            <div
                className='chip-amount'
                style={{ fontSize: `${size / 3}px` }}
            >
                {amount}</div>
        </div>
    )
}
