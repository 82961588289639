import './tetrisBoard.css';
import { TetrisCell } from "../tetrisCell/tetrisCell";
import { TetrisBlock } from '../tetrisTypes';

type TetrisBoardProps = {
    currentBoard: TetrisBlock[][];
}

export const TetrisBoard: React.FC<TetrisBoardProps> = ({ currentBoard }) => {

    return (
        <div className="tetrisBoard">
            {currentBoard.map((row, rowIndex) => (
                <div className="tetrisBoard_row" id={`tetris_row_${rowIndex}`} key={rowIndex}>
                    {
                        row.map((cell, colIndex) => <TetrisCell cellValue={cell} key={`${rowIndex}-${colIndex}`} />)
                    }
                </div>
            ))}
        </div>
    )
}