import { FarmCard } from '../../../components/farmCard/farmCard';
import { divideWithDecimals, isBiggerThanOrEqualTo, subtract, add, divideToIntegers } from '../../../utils/mathUtils';
import './farm.css';
import { useState, useEffect } from 'react';
import { multiply } from '../../../utils/mathUtils';
import { Web3 } from 'web3';
import { weiToEthWithDecimals } from '../../../web3/utils';
import arcadeApi from '../../../requests/arcadeApi';
import { usePageLoad } from '../../../context/pageLoadContext';
import { DynamicIcon } from '../../../components/dynamicIcon/dynamicIcon';
import config from '../../../config';
import { useArcadeBalance } from '../../../context/arcadeBalanceContext';


export type FarmBalance = {
    totalShares: string,
    potValue: string
}

export const Farm = () => {

    // Hooks
    const { togglePageLoad } = usePageLoad();
    const { refreshBalance } = useArcadeBalance(); // HUD refresh

    // User Values
    const [userArcadeScratchBalance, setUserArcadeScratchBalance] = useState<string>('0');
    const [userFarmShareBalance, setUserFarmShareBalance] = useState<string>('0');

    // Farm Values
    const [farmTotalShares, setFarmTotalShares] = useState<string>('0');
    const [farmPotValue, setFarmPotValue] = useState<string>('0');

    const sharePrice = farmTotalShares !== '0' ? divideWithDecimals(farmPotValue, farmTotalShares, 4) : '1';

    // Variables for Buying Shares using Scratch as the input
    const [scratchToSpendInputDisplay, setScratchToSpendInputDisplay] = useState<string>('0');
    const [scratchToSpendWei, setScratchToSpendWei] = useState<string>('0');

    // Variables for Selling Shares using Shares as the input
    const [sharesToSellInputDisplay, setSharesToSellInputDisplay] = useState<string>('0');
    const [sharesToSellWei, setSharesToSellWei] = useState<string>('0');

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        displaySetter: React.Dispatch<React.SetStateAction<string>>,
        weiSetter: React.Dispatch<React.SetStateAction<string>>) => {
        const newValue = event.target.value;
        // Regular expression to validate the input is a positive numeric value and allows up to 18 decimal places.
        const isValidNumber = /^\d*(\.\d{0,18})?$/.test(newValue);
        if (isValidNumber) {
            // It's a valid number; we can set the state.
            displaySetter(newValue);


            //convert to wei
            const weiValue = Web3.utils.toWei(newValue, 'ether');
            weiSetter(weiValue);

        } else {
            // It's not a valid number. Here you might want to provide feedback to the user.
            console.error("Invalid input: Please enter a valid number (up to 18 decimal places).");

            // Reset the state to '0' if the input is invalid.
            weiSetter('0');
            displaySetter('0');
        }
    };

    const handleBuyShares = async () => {
        togglePageLoad(true); // toggle loading screen
        setScratchToSpendInputDisplay('0'); // reset input to prevent double buys
        setScratchToSpendWei('0'); // reset input to prevent double buys
        await arcadeApi.buyFarmShares(scratchToSpendWei)
        // add an extra couple seconds to load so API can catch up
        setTimeout(() => {
            fetchData(true);
        }, 2000);
    }

    const handleSellShares = async () => {
        togglePageLoad(true); // toggle loading screen
        setSharesToSellInputDisplay('0'); // reset input to prevent double buys
        setSharesToSellWei('0'); // reset input to prevent double buys
        await arcadeApi.sellFarmShares(sharesToSellWei)
        setTimeout(() => {
            fetchData(true);
        }, 2000);
    }

    const isAllowedToTrade = (spendAmount: string, spendLimit: string): boolean => {
        return (
            spendAmount !== '0' &&
            spendAmount !== '' &&
            spendAmount !== undefined &&
            isBiggerThanOrEqualTo(subtract(spendLimit, spendAmount), '0')
        )
    }

    const isAllowedToBuyShares = (): boolean => {
        return isAllowedToTrade(scratchToSpendWei, userArcadeScratchBalance);
    }

    const isAllowedToSellShares = (): boolean => {
        return isAllowedToTrade(sharesToSellWei, userFarmShareBalance);
    }

    const getUserArcadeBalance = () => {
        return arcadeApi.getUserBalance().then((data) => {
            if (data &&
                data.balanceResponse &&
                data.balanceResponse.arcadeBalance &&
                data.balanceResponse.farmShareBalance) {
                setUserArcadeScratchBalance(data.balanceResponse.arcadeBalance);
                setUserFarmShareBalance(data.balanceResponse.farmShareBalance);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const getArcadeFarmBalance = () => {
        return arcadeApi.getFarmBalance().then((data) => {
            if (
                data &&
                data.farmBalanceRes &&
                data.farmBalanceRes.farmBalance &&
                data.farmBalanceRes.farmBalance.totalShares &&
                data.farmBalanceRes.farmBalance.potValue

            ) {
                setFarmTotalShares(data.farmBalanceRes.farmBalance.totalShares);
                setFarmPotValue(data.farmBalanceRes.farmBalance.potValue);
            }

        }).catch((err) => {
            console.log(err);
        })
    }

    const fetchData = async (toggleLoad: boolean) => {
        toggleLoad && togglePageLoad(true);
        // Using Promise.all to wait for both operations to complete
        await Promise.all([getUserArcadeBalance(), getArcadeFarmBalance()])
            .then((results) => {
                toggleLoad && togglePageLoad(false);
                refreshBalance()
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
                toggleLoad && togglePageLoad(false);
            });
    }

    const refreshDataInternvalTime = 30000;
    // fetchData on load and on a refreshDataInternvalTime milisecond interval
    useEffect(() => {
        fetchData(true);
        const interval = setInterval(() => {
            fetchData(false);
            console.log('Refreshing Farm Data...' + new Date().toLocaleTimeString('en-US', { hour12: false }) + ' UTC');
        }, refreshDataInternvalTime);
        return () => clearInterval(interval);
    }, [])

    const scratchIcon = <DynamicIcon name='SCRATCH' width={25} />
    const farmShareIcon = <DynamicIcon name='FS' width={25} />

    return (
        <div className='page-farm'>
            <img className={'tetris-titleImage'} src={'titleCards/scratchfarm.png'} alt='' />
            <div className='page-farm-card-container'>
                <FarmCard totalShares={farmTotalShares} potValue={farmPotValue} />
            </div>
            <div className='trade-shares-container'>

                <div className='trade-shares-card buy'>
                    <div className='trade-shares-card-title'>
                        Buy Shares
                    </div>
                    <div className='trade-shares-card-subtitle'>
                        ~ Share Price (SCRATCH)
                    </div>
                    <div className={'trade-shares-card-subtitle-value'}>
                        {scratchIcon}{sharePrice}
                    </div>
                    <div className='trade-shares-card-subtitle'>
                        Enter Scratch to Spend
                    </div>
                    <div className='trade-shares-card-subtitle-value'>
                        {scratchIcon}
                        <input
                            className='trade-shares-card-input buy'
                            type='text'
                            value={scratchToSpendInputDisplay}
                            onChange={
                                (event) => handleInputChange(event, setScratchToSpendInputDisplay, setScratchToSpendWei)
                            }  // Linking the input change event to our new function
                        />
                        <div className='trade-shares-estimate-output'>
                            ≈{weiToEthWithDecimals((divideToIntegers(scratchToSpendWei, sharePrice)), 2)}{farmShareIcon}
                        </div>
                    </div>
                    <div className='trade-shares-balance-info'>
                        <div className='trade-shares-balance-info-title'>
                            Current Scratch Balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {scratchIcon}{userArcadeScratchBalance && weiToEthWithDecimals(userArcadeScratchBalance, 2)}
                        </div>
                        <div className='trade-shares-balance-info-title'>
                            New Scratch Balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {scratchIcon}{userArcadeScratchBalance && weiToEthWithDecimals(subtract(userArcadeScratchBalance, scratchToSpendWei), 2)}
                        </div>
                        <div className='trade-shares-balance-info-title'>
                            Current Shares Balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {farmShareIcon}{weiToEthWithDecimals(userFarmShareBalance, 2)}
                        </div>
                        <div className='trade-shares-balance-info-title'>
                            Est. New Shares balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {farmShareIcon}{weiToEthWithDecimals(add(userFarmShareBalance, (divideWithDecimals(scratchToSpendWei, sharePrice, 18))), 2)}
                        </div>
                    </div>
                    <div
                        className={`trade-shares-button-buy button medium ${isAllowedToBuyShares() ? '' : 'disabled'}`}
                        onClick={
                            () => {
                                if (isAllowedToBuyShares()) {
                                    handleBuyShares()
                                }
                            }
                        }
                    >
                        Buy Shares
                    </div>
                </div>
                <div className='trade-shares-card sell'>
                    <div className='trade-shares-card-title'>
                        Sell Shares
                    </div>
                    <div className='trade-shares-card-subtitle'>
                        ~ Share Price (SCRATCH)
                    </div>
                    <div className={'trade-shares-card-subtitle-value'}>
                        {scratchIcon}{sharePrice}
                    </div>
                    <div className='trade-shares-card-subtitle'>
                        Enter Shares to Sell
                    </div>
                    <div className='trade-shares-card-subtitle-value'>
                        {farmShareIcon}
                        <input
                            className='trade-shares-card-input sell'
                            type='text'
                            value={sharesToSellInputDisplay}
                            onChange={
                                (event) => handleInputChange(event, setSharesToSellInputDisplay, setSharesToSellWei)
                            }  // Linking the input change event to our new function
                        />
                        <div className='trade-shares-estimate-output'>
                            ≈{weiToEthWithDecimals((multiply(sharesToSellWei, sharePrice)), 2)}{scratchIcon}
                        </div>
                    </div>
                    <div className='trade-shares-balance-info'>
                        <div className='trade-shares-balance-info-title'>
                            Current Shares Balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {farmShareIcon}{weiToEthWithDecimals(userFarmShareBalance, 2)}
                        </div>
                        <div className='trade-shares-balance-info-title'>
                            New Shares Balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {farmShareIcon}{userFarmShareBalance && weiToEthWithDecimals(subtract(userFarmShareBalance, sharesToSellWei), 2)}
                        </div>
                        <div className='trade-shares-balance-info-title'>
                            Current Scratch Balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {scratchIcon}{userArcadeScratchBalance && weiToEthWithDecimals(userArcadeScratchBalance, 2)}
                        </div>
                        <div className='trade-shares-balance-info-title'>
                            Est. New Scratch balance
                        </div>
                        <div className='trade-shares-balance-info-value'>
                            {scratchIcon}{weiToEthWithDecimals(add(userArcadeScratchBalance, (multiply(sharesToSellWei, sharePrice))), 2)}
                        </div>
                    </div>
                    <div
                        className={`button medium trade-shares-button-sell ${isAllowedToSellShares() ? '' : 'disabled'}`}
                        onClick={
                            () => {
                                if (isAllowedToSellShares()) {
                                    handleSellShares()
                                }
                            }
                        }
                    >
                        Sell Shares
                    </div>
                </div>
            </div>

        </div>
    )
}