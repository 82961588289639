import { useNavigate } from 'react-router-dom';
import { usePageLoad } from '../../../context/pageLoadContext';
import { useWeb3 } from '../../../context/web3Context';
import './passRedeem.css';
import { useEffect, useState } from 'react';
import { approveS1RedeemContract, checkIsApprovedForAllPassesS1 } from '../../../web3/methods/s1PassMethods';
import { redeemS1Passes } from '../../../web3/methods/s1RedeemMethods.tsx';
import { NFTList } from '../../../components/nftList/nftList';
import { getWalletS1PassTokenIds } from '../../../utils/reqUtils';
import { DynamicIcon } from '../../../components/dynamicIcon/dynamicIcon';
import { redeemBlurbFormated } from './passRedeemBlurb';
import { weiToEthWithDecimals } from '../../../web3/utils';
import { PassRedeemCodeTable } from './passRedeemCodeTable';
import arcadeApi from '../../../requests/arcadeApi';

export type UserPassRedeemInfo = {
    userAddress: string; // partition key
    passesRedeemed: string; // increase
    scratchEarnedWei: string; // increase
    permaFarmPointsEarned: string; // increase
    playgroundTokensEarned: string; // increase
    stickerSetsEarned: string; // increase
    collectibleCoinsEarned: string; // increase
    hatsEarned: string; // increase
    etsyDiscountCodes: string[]; // append with new discount code (1 per tx)
    lastUpdated: string; // update
}

export const PassRedeem = () => {

    const defaultUserPassData: UserPassRedeemInfo = {
        userAddress: '',
        passesRedeemed: '0',
        scratchEarnedWei: '0',
        permaFarmPointsEarned: '0',
        playgroundTokensEarned: '0',
        stickerSetsEarned: '0',
        collectibleCoinsEarned: '0',
        hatsEarned: '0',
        etsyDiscountCodes: [],
        lastUpdated: '',
    }

    // Context imports
    const { web3, address } = useWeb3();
    const { togglePageLoad } = usePageLoad();

    const navigate = useNavigate(); // Destructure navigate function

    const [walletTokenIds, setWalletTokenIds] = useState<string[]>([]);
    const [selectedTokenIds, setSelectedTokenIds] = useState<string[]>([]);
    const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);
    const [userPassData, setUserPassData] = useState<UserPassRedeemInfo>(defaultUserPassData);


    const resetSelected = () => {
        const emptyArray: string[] = [];
        setSelectedTokenIds(emptyArray);
    }

    const handleSelectPass = (selectedTokenIds: string[], setSelectedTokenIds: (tokenId: string[]) => void) => {
        return (tokenId: string) => {
            let tempSelectedTokenIds: string[] = [...selectedTokenIds];  // Clone the current array

            if (selectedTokenIds.includes(tokenId)) {
                tempSelectedTokenIds = selectedTokenIds.filter(id => id !== tokenId);
            } else {
                // Only add the tokenId if we haven't reached the limit
                if (tempSelectedTokenIds.length < 50) {
                    tempSelectedTokenIds.push(tokenId);
                } else {
                    console.warn("You have reached the maximum selection limit of 20 items.");
                }
            }

            setSelectedTokenIds(tempSelectedTokenIds);
        }
    };

    // Usage:
    const handleSelect = handleSelectPass(selectedTokenIds, setSelectedTokenIds);

    const canRedeem = (selectedTokenIds.length > 0) && isApprovedForAll;

    const handleRedeemButtonClick = async () => {
        if (web3 && address && canRedeem) {
            togglePageLoad(true);
            try {
                await redeemS1Passes(web3, address, selectedTokenIds)
                await new Promise(resolve => setTimeout(resolve, 3000));
                await loadData()
            } catch (err) {
                console.log(err);
                togglePageLoad(false);
            }
        }
    }

    const handleApproveButtonClick = async () => {
        togglePageLoad(true);
        if (address && web3) {
            try {
                await approveS1RedeemContract(web3, address)
                await loadData()
            } catch (err) {
                console.log(err);
                togglePageLoad(false);
            }
        }
        togglePageLoad(false);
    }

    const loadData = async () => {
        togglePageLoad(true);
        resetSelected();
        if (web3 && address) {
            try {
                const [
                    walletTokenIds,
                    passRedeemInfo,
                    isApprovedForAll,
                ] = await Promise.all([
                    getWalletS1PassTokenIds(address),
                    arcadeApi.getPassRedeemInfo(),
                    checkIsApprovedForAllPassesS1(web3, address),
                ])

                // Set states
                setWalletTokenIds(walletTokenIds);
                setUserPassData(passRedeemInfo);
                setIsApprovedForAll(isApprovedForAll);

                // Stop the loading spinner once all succeed
                togglePageLoad(false);
            } catch (err) {
                // Send user back to home page if there's an error
                console.log(err)
                togglePageLoad(false);
                // navigate("/");
            }
        }
        togglePageLoad(false);
    }

    // get arcade and web3 data on page load
    useEffect(() => {
        loadData();
    }, [address]);

    const getPassImageColor = (tokenId: string) => {
        const tokenIdNum = parseFloat(tokenId);
        if (tokenIdNum < 5751) {
            return 'yellow';
        } else if (tokenIdNum < 8751) {
            return 'blue';
        } else if (tokenIdNum < 9751) {
            return 'green';
        } else if (tokenIdNum < 10001) {
            return 'red';
        }
    }

    const scratchGridPass = (
        <div className='passRedeem-info-grid-passes-container'>
            <div className='passRedeem-info-grid-pass yellow'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/yellow.jpg`} />
                <div className='passRedeem-info-grid-pass-value yellow'>200<DynamicIcon name='SCRATCH' width={15} /></div>
            </div>
            <div className='passRedeem-info-grid-pass blue'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/blue.jpg`} />
                <div className='passRedeem-info-grid-pass-value blue'>250<DynamicIcon name='SCRATCH' width={20} /></div>
            </div>
            <div className='passRedeem-info-grid-pass green'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/green.jpg`} />
                <div className='passRedeem-info-grid-pass-value green'>300<DynamicIcon name='SCRATCH' width={25} /></div>
            </div>
            <div className='passRedeem-info-grid-pass red'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/red.jpg`} />
                <div className='passRedeem-info-grid-pass-value red'>400<DynamicIcon name='SCRATCH' width={30} /></div>
            </div>
        </div>
    )

    const farmGridPass = (
        <div className='passRedeem-info-grid-passes-container'>
            <div className='passRedeem-info-grid-pass yellow'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/yellow.jpg`} />
                <div className='passRedeem-info-grid-pass-value yellow'>1<DynamicIcon name='PFP' width={15} /></div>
            </div>
            <div className='passRedeem-info-grid-pass blue'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/blue.jpg`} />
                <div className='passRedeem-info-grid-pass-value blue'>3<DynamicIcon name='PFP' width={20} /></div>
            </div>
            <div className='passRedeem-info-grid-pass green'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/green.jpg`} />
                <div className='passRedeem-info-grid-pass-value green'>5<DynamicIcon name='PFP' width={25} /></div>
            </div>
            <div className='passRedeem-info-grid-pass red'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/red.jpg`} />
                <div className='passRedeem-info-grid-pass-value red'>10<DynamicIcon name='PFP' width={30} /></div>
            </div>
        </div>
    )

    const pgtGridPass = (
        <div className='passRedeem-info-grid-passes-container'>
            <div className='passRedeem-info-grid-pass yellow'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/yellow.jpg`} />
                <div className='passRedeem-info-grid-pass-value yellow'>2<DynamicIcon name='PG' width={15} /></div>
            </div>
            <div className='passRedeem-info-grid-pass blue'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/blue.jpg`} />
                <div className='passRedeem-info-grid-pass-value blue'>2<DynamicIcon name='PG' width={20} /></div>
            </div>
            <div className='passRedeem-info-grid-pass green'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/green.jpg`} />
                <div className='passRedeem-info-grid-pass-value green'>3<DynamicIcon name='PG' width={25} /></div>
            </div>
            <div className='passRedeem-info-grid-pass red'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/red.jpg`} />
                <div className='passRedeem-info-grid-pass-value red'>3<DynamicIcon name='PG' width={30} /></div>
            </div>
        </div>
    )

    const merchGridPass = (
        <div className='passRedeem-info-grid-passes-container'>
            <div className='passRedeem-info-grid-pass yellow'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/yellow.jpg`} />
                <div className='passRedeem-info-grid-pass-value yellow'>DS</div>
            </div>
            <div className='passRedeem-info-grid-pass blue'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/blue.jpg`} />
                <div className='passRedeem-info-grid-pass-value blue'>DC</div>
            </div>
            <div className='passRedeem-info-grid-pass green'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/green.jpg`} />
                <div className='passRedeem-info-grid-pass-value green'>DSC</div>
            </div>
            <div className='passRedeem-info-grid-pass red'>
                <img className='passRedeem-info-grid-pass-img' src={`https://boomersquad-arcadepass.s3.amazonaws.com/images/red.jpg`} />
                <div className='passRedeem-info-grid-pass-value red'>DSCH</div>
            </div>
        </div>
    )

    const scratchIcon = <DynamicIcon name='SCRATCH' width={40} />
    const permaFarmPointIcon = <DynamicIcon name='PFP' width={40} />
    const playgroundTokenIcon = <DynamicIcon name='PG' width={40} />
    const ztbIcon = <DynamicIcon name='ZTB' width={40} />

    const iconWidth1: number = 45;

    return (
        <div className='passRedeem-page page'>
            <div className='passRedeem-info-grid-container non-selectable'>
                <div className='passRedeem-info-grid'>
                    <div className='passRedeem-info-grid-item scratch'>
                        <div className='passRedeem-info-grid-item-title'>
                            <div className='passRedeem-info-grid-item-title-section'>{scratchIcon}Arcade Scratch</div>
                            <div className='passRedeem-info-grid-item-title-section extra'>Loads up your Arcade balance on redeem, ready to play</div>
                        </div>
                        {scratchGridPass}
                    </div>
                    <div className='passRedeem-info-grid-item permaFarmPoints'>
                        <div className='passRedeem-info-grid-item'>
                            <div className='passRedeem-info-grid-item-title'>
                                <div className='passRedeem-info-grid-item-title-section'>{permaFarmPointIcon}Perma Farm Points</div>
                                <div className='passRedeem-info-grid-item-title-section extra'>Gained instantly on redeem, more points more rewards</div>
                            </div>
                            {farmGridPass}
                        </div>
                    </div>
                    <div className='passRedeem-info-grid-item pg'>
                        <div className='passRedeem-info-grid-item'>
                            <div className='passRedeem-info-grid-item-title'>
                                <div className='passRedeem-info-grid-item-title-section'>{playgroundTokenIcon}Playground Tokens</div>
                                <div className='passRedeem-info-grid-item-title-section extra'>Earn PGTs, Season 3 starting February 2nd</div>
                            </div>
                            {pgtGridPass}
                        </div>
                    </div>
                    <div className='passRedeem-info-grid-item merch'>
                        <div className='passRedeem-info-grid-item'>
                            <div className='passRedeem-info-grid-item-title'>
                                <div className='passRedeem-info-grid-item-title-section'>{ztbIcon}Merch Redeem</div>
                                <div className='passRedeem-info-grid-item-title-section extra'>
                                    Create
                                    <a
                                        href='https://discord.gg/boomer-squad-nft-939820143486320650'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >discord</a>ticket for merch claim at
                                </div>
                            </div>
                            <div className='passRedeem-info-grid-item-extra'>
                                <div>
                                    <span className='passRedeem-info-grid-item-extra-highlight'>D</span>iscount Code - <a
                                        href='https://ztblabs.etsy.com/'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >ZTB Labs Merch Store</a>
                                </div>
                                <div>
                                    <span className='passRedeem-info-grid-item-extra-highlight'>S</span>ticker Set x3 - Boomer Squad, Zoomer Squad, BS Logo
                                </div>
                                <div>
                                    <span className='passRedeem-info-grid-item-extra-highlight'>C</span>ollectable Coin - Boomer Squad Official
                                </div>
                                <div>
                                    <span className='passRedeem-info-grid-item-extra-highlight'>H</span>at - Boomer Squad Official Trucker Snap Back
                                </div>
                            </div>
                            {merchGridPass}
                        </div>
                    </div>
                </div>
            </div>
            <div className='passRedeem-stats-container non-selectable'>
                <div className='passRedeem-stats-container-section'>
                    <div className='passRedeem-stats-title'>Pass Balance</div>
                    <div className='passRedeem-stats-value'>{walletTokenIds.length}</div>
                </div>
                <div className='passRedeem-stats-container-section'>
                    <div className='passRedeem-stats-title'>Passes Redeemed</div>
                    <div className='passRedeem-stats-value'>{userPassData.passesRedeemed}</div>
                </div>
                <div className='passRedeem-stats-container-section'>
                    <div className='passRedeem-stats-title'>Scratch Earned</div>
                    <div className='passRedeem-stats-value'>{weiToEthWithDecimals(userPassData.scratchEarnedWei, 0)} <DynamicIcon name='SCRATCH' width={iconWidth1} /></div>
                </div>
                <div className='passRedeem-stats-container-section'>
                    <div className='passRedeem-stats-title'>Perma Farm Points Earned</div>
                    <div className='passRedeem-stats-value'>{userPassData.permaFarmPointsEarned} <DynamicIcon name='PFP' width={iconWidth1} /></div>
                </div>
                <div className='passRedeem-stats-container-section'>
                    <div className='passRedeem-stats-title'>Playground Tokens Earned</div>
                    <div className='passRedeem-stats-value'>{userPassData.playgroundTokensEarned} <DynamicIcon name='PG' width={iconWidth1} /></div>
                </div>
                <div className='passRedeem-stats-container-section'>
                    <div className='passRedeem-stats-title merch'>Merch earned</div>
                    <div className='passRedeem-stats-value-merch-container'>
                        <div className='passRedeem-stats-value-merch'>
                            <div className='passRedeem-stats-value-merch-text'>
                                S<span className='passRedeem-stats-value-merch-container-title'>ticker Set x3</span>
                            </div>
                            {userPassData.stickerSetsEarned}
                        </div>
                        <div className='passRedeem-stats-value-merch'>
                            <div className='passRedeem-stats-value-merch-text'>
                                C<span className='passRedeem-stats-value-merch-container-title'>ollectible Coin</span>
                            </div>
                            {userPassData.collectibleCoinsEarned}
                        </div>
                        <div className='passRedeem-stats-value-merch'>
                            <div className='passRedeem-stats-value-merch-text'>
                                H<span className='passRedeem-stats-value-merch-container-title'>at</span>
                            </div>
                            {userPassData.hatsEarned}
                        </div>
                    </div>
                </div>
            </div>
            <div className='passRedeem-steps-text'>
                Approve Contract, Select Passes, and Redeem!
            </div>
            <div className='passRedeem-steps-text-extra'>
                UNLIMITED Redeems, up to 50 passes at a time per Redeem
            </div>
            <div className='passRedeem-steps-text-extra'>
                One time use per Arcade Pass. Turned in on Redeem
            </div>
            {/* <div className='passRedeem-steps-text' style={{ fontSize: '18px', opacity: '1' }}>
                Redeems will turn on within 48 hours of sale start.
            </div> */}
            {
                isApprovedForAll ?
                    <>
                        <div
                            className={`button button-primary large ${canRedeem ? '' : 'disabled'}`}
                            onClick={handleRedeemButtonClick}
                        >
                            Redeem
                        </div>
                        {/* <div
                            className={`button button-primary large disabled`}
                        >
                            Redeem
                        </div> */}
                    </>

                    :
                    <div className='button-primary button medium'
                        onClick={handleApproveButtonClick}
                    >
                        Approve Contract
                    </div>
            }
            <NFTList
                tokenName='Arcade Pass'
                itemsPerPage={50}
                tokenIds={walletTokenIds}
                imageUrl={(tokenId) => `https://boomersquad-arcadepass.s3.amazonaws.com/images/${getPassImageColor(tokenId)}.jpg`}
                selectedTokens={selectedTokenIds}
                setSelectedTokens={setSelectedTokenIds}
            />
            <div className='passRedeem-discount-codes-container'>
                <PassRedeemCodeTable codes={userPassData.etsyDiscountCodes} />
            </div>
            <div className='passRedeem-blurb'>
                {redeemBlurbFormated}
            </div>
        </div>
    );
}