import React, { useEffect, useState, useCallback } from 'react';
import { usePageLoad } from '../../../../context/pageLoadContext';
import arcadeApi from '../../../../requests/arcadeApi';
import { BlackjackGameClientInfo, BlackjackHandNumber } from '../../../../components/games/blackjack/blackjackTypes';
import config from '../../../../config';
import useHeartbeat from '../../../../hooks/useHeartbeat';
import useWebSocket from '../../../../hooks/useWebSocket';
import { BlackjackGame } from '../../../../components/games/blackjack/blackjackGame';
import './blackjackPage.css';
import { generateSeed } from '../../../../utils/seedUtils';
import { weiToEthWithDecimals } from '../../../../web3/utils';
import { GameInfo, InfoBar } from '../../../../components/games/infoBar/infoBar';
import { getGameFee } from '../../../../utils/gameUtils';
import Web3 from 'web3';
import { add, isBiggerThan, multiply } from '../../../../utils/mathUtils';
import { useArcadeBalance } from '../../../../context/arcadeBalanceContext';

export const BlackjackPage = () => {

    // Use the heartbeat hook to keep the user's session alive
    useHeartbeat();

    // Use the context-based hook to get the current arcade balance and the refresh function
    const { refreshBalance } = useArcadeBalance();

    const handleWebSocketMessage = useCallback((event: MessageEvent) => {
        const jsonData = JSON.parse(event.data);
        if (jsonData.type === 'BLACKJACK_GAME_UPDATE' && jsonData.blackjackGameClientInfo) {
            setCurrentBlackjackGame(jsonData.blackjackGameClientInfo);
            if (jsonData.blackjackGameClientInfo.gameState === 'GAME_OVER') {
                // Update the user's balance and server seed hash
                updateAfterGameOver();
            } else {
                // Update the user's balance only in case of split or double
                updateUserBalance();
            }
        }
    }, []);

    const { ws, sendMessage } = useWebSocket(config.BASE_WS_URL, handleWebSocketMessage);

    const { togglePageLoad } = usePageLoad();

    const [currentBlackjackGame, setCurrentBlackjackGame] = useState<BlackjackGameClientInfo | null>(null);
    const [serverSeedHash, setServerSeedHash] = useState('');
    const [clientSeed, setClientSeed] = useState(generateSeed());
    const [arcadeScratchBalance, setArcadeScratchBalance] = useState<string | null>(null);
    const [arcadeZoomerBalance, setArcadeZoomerBalance] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [playAmountDisplay, setPlayAmountDisplay] = useState<number>(0);
    const [playAmountWei, setPlayAmountWei] = useState<string>('0');

    const handlePlayAmountChange = (amount: number) => {

        // Check if can increase play amount
        if (!checkCanIncreasePlayAmount(amount)) {
            return;
        }

        if (amount === 0 as number) {
            setPlayAmountDisplay(0);
            setPlayAmountWei('0');
        } else {
            // Increase play amount by amount
            const newPlayAmountDisplay = playAmountDisplay + amount;
            const newPlayAmountWei = Web3.utils.toWei(newPlayAmountDisplay.toString(), 'ether');

            setPlayAmountDisplay(newPlayAmountDisplay);
            setPlayAmountWei(newPlayAmountWei);
            // Convert number to string and then to wei
        }
    }

    const checkCanIncreasePlayAmount = (amount: number) => {
        // Always allow reset
        if (amount === 0) {
            return true;
        }
        const newPlayAmountDisplay = playAmountDisplay + amount;
        const newPlayAmountWei = Web3.utils.toWei(newPlayAmountDisplay.toString(), 'ether');
        const gameFee = (parseFloat(getGameFee(arcadeZoomerBalance || '0')) / 100).toString();
        const newPlayAmountWeiWithGameFee = add(newPlayAmountWei, multiply(newPlayAmountWei, gameFee));
        // Check if the new play amount + play cost is greater than the user's balance
        if (isBiggerThan(newPlayAmountWeiWithGameFee, arcadeScratchBalance || '0')) {
            return false;
        }
        // Check if the new play amount is greater than the max play amount
        if (isBiggerThan(newPlayAmountDisplay.toString(), '200')) {
            return false;
        }
        return true;
    }

    const checkCanPlay = () => {
        // Check if the user has enough balance to play
        const gameFee = (parseFloat(getGameFee(arcadeZoomerBalance || '0')) / 100).toString();
        const playAmountWeiWithGameFee = add(playAmountWei, multiply(playAmountWei, gameFee));
        if (isBiggerThan(playAmountWeiWithGameFee, arcadeScratchBalance || '0')) {
            return false;
        }
        // Must be greater than 1 ETH
        if (isBiggerThan(Web3.utils.toWei('1', 'ether'), playAmountWei)) {
            return false;
        }
        // Must be less than 200 ETH
        if (isBiggerThan(playAmountWei, Web3.utils.toWei('200', 'ether'))) {
            return false;
        }
        return true;
    }

    const onStartGame = () => {
        if (playAmountWei === '0') {
            return;
        }
        sendMessage({
            game: 'BLACKJACK',
            type: 'START_GAME',
            playAmount: playAmountWei,
            clientSeed: clientSeed
        });
    };

    const onHit = (handNum: BlackjackHandNumber | null) => {
        if (!handNum) {
            return;
        }
        sendMessage({
            game: 'BLACKJACK',
            type: 'ACTION',
            action: 'HIT',
            hand: handNum
        });
    }

    const onStand = (handNum: BlackjackHandNumber | null) => {
        if (!handNum) {
            return;
        }
        sendMessage({
            game: 'BLACKJACK',
            type: 'ACTION',
            action: 'STAND',
            hand: handNum
        });
    }

    const onDouble = (handNum: BlackjackHandNumber | null) => {
        if (!handNum) {
            return;
        }
        sendMessage({
            game: 'BLACKJACK',
            type: 'ACTION',
            action: 'DOUBLE',
            hand: handNum
        });
    }

    const onSplit = (handNum: BlackjackHandNumber | null) => {
        if (!handNum) {
            return;
        }
        sendMessage({
            game: 'BLACKJACK',
            type: 'ACTION',
            action: 'SPLIT',
            hand: handNum
        });
    }

    const gameInfo: GameInfo[] = [
        {
            infoName: 'SCRATCH BALANCE',
            infoValue: `${weiToEthWithDecimals(arcadeScratchBalance || '0', 2)}`
        },
        {
            infoName: 'STAKED ZOOMERS',
            infoValue: `${arcadeZoomerBalance}`
        },
        {
            infoName: 'PLAY COST',
            infoValue: getGameFee(arcadeZoomerBalance || '0') + '%'
        },
    ]

    const updateAfterGameOver = async () => {
        setLoading(true);
        // Waitan extra second to make sure the server has updated the balance
        await new Promise(resolve => setTimeout(resolve, 1000));
        const [{ arcadeBalance, zoomerBalance }, serverSeedHash] = await Promise.all(
            [
                getUserScratchAndZoomerBalance(),
                getNewServerSeedHash()
            ]
        );
        setArcadeScratchBalance(arcadeBalance);
        setArcadeZoomerBalance(zoomerBalance);
        setServerSeedHash(serverSeedHash);
        setLoading(false);
        refreshBalance();
    }

    const updateUserBalance = async () => {
        const { arcadeBalance, zoomerBalance } = await getUserScratchAndZoomerBalance();
        setArcadeScratchBalance(arcadeBalance);
        setArcadeZoomerBalance(zoomerBalance);
    }

    const getNewServerSeedHash = async () => {
        const startGameSessionResponse = await arcadeApi.startGameSession('BLACKJACK');
        if (startGameSessionResponse.createSessionRes.seedHash) {
            return startGameSessionResponse.createSessionRes.seedHash;
        }
    }

    const getUserScratchAndZoomerBalance = async (): Promise<{ arcadeBalance: string, zoomerBalance: string }> => {
        const response = await arcadeApi.getUserBalance()
        if (
            response.balanceResponse.arcadeBalance &&
            response.balanceResponse.zoomerBalance &&
            response.balanceResponse.zoomerBalance.balance
        ) {
            return {
                arcadeBalance: response.balanceResponse.arcadeBalance,
                zoomerBalance: response.balanceResponse.zoomerBalance.balance
            }
        } else {
            return { arcadeBalance: '0', zoomerBalance: '0' }
        }
    }

    useEffect(() => {
        togglePageLoad(true);
        // Define an async function to use await inside
        const initializeGameSessionAndBalance = async () => {
            try {
                const gameName = 'BLACKJACK';
                const promises = [
                    arcadeApi.startGameSession(gameName),
                    arcadeApi.getUserBalance(),
                    arcadeApi.getBlackjackGame()
                ];
                const [startGameSessionResponse, userBalanceResponse, blackjackGameResponse] = await Promise.all(promises);
                // console.log('startGameSessionResponse', startGameSessionResponse);
                // console.log('blackjackGameResponse', blackjackGameResponse);
                // Check for balance response and set arcade scratch balance
                if (
                    userBalanceResponse.balanceResponse.arcadeBalance &&
                    userBalanceResponse.balanceResponse.zoomerBalance &&
                    userBalanceResponse.balanceResponse.zoomerBalance.balance &&
                    startGameSessionResponse.createSessionRes.seedHash &&
                    blackjackGameResponse.currentBlackjackGame ||
                    blackjackGameResponse.currentBlackjackGame === null
                ) {
                    setArcadeScratchBalance(userBalanceResponse.balanceResponse.arcadeBalance);
                    setArcadeZoomerBalance(userBalanceResponse.balanceResponse.zoomerBalance.balance);
                    setServerSeedHash(startGameSessionResponse.createSessionRes.seedHash);
                    setCurrentBlackjackGame(blackjackGameResponse.currentBlackjackGame);
                }
            } catch (err) {
                // Catch any errors from either of the promises
                console.error(err);
            }
            togglePageLoad(false);
        };

        // Execute the async function
        initializeGameSessionAndBalance();
    }, []); // Empty dependency array ensures this effect runs once on mount

    return (
        <div className='blackjackPage page'>
            <InfoBar gameInfo={gameInfo} />
            <img className={'tetris-titleImage'} src={'titleCards/hitme.png'} alt='' />
            <BlackjackGame
                game={currentBlackjackGame}
                onStartGame={onStartGame}
                onHit={onHit}
                onStand={onStand}
                onDouble={onDouble}
                onSplit={onSplit}
                loading={loading}
                playAmountDisplay={playAmountDisplay}
                handlePlayAmountChange={handlePlayAmountChange}
                checkCanIncreasePlayAmount={checkCanIncreasePlayAmount}
                checkCanPlay={checkCanPlay}
            />
        </div>
    )
}