import React, { useRef } from 'react';
import './connectModal.css';  // Import the CSS
import { connectMetaMask } from '../../web3/providers/metamask';
import { useWeb3 } from '../../context/web3Context';
import { usePageLoad } from '../../context/pageLoadContext';
import { connectDeFi } from '../../web3/providers/deficonnect';

interface ConnectModalProps {
    isOpen: boolean;
    toggleModal: () => void;
}


export const ConnectModal: React.FC<ConnectModalProps> = ({ isOpen, toggleModal }) => {
    const { setWeb3 } = useWeb3();
    const { togglePageLoad } = usePageLoad();
    const overlayRef = useRef(null);

    const handleModalButtonClick = (connectionFn: () => Promise<any>) => {
        // Start the loading spinner
        togglePageLoad(true);
        
        connectionFn()
            .then(() => {
                // Connected successfully, toggle off the loading spinner
                togglePageLoad(false);
                // Close the modal
                toggleModal();
            })
            .catch((err) => {
                console.error("Connection error:", err);
                // If there's an error, toggle off the loading spinner
                togglePageLoad(false);
                toggleModal();
            });
    };
    

    const modalButtons = {
        metamask: {
            text: 'Metamask',
            icon: 'https://arcade-web-images.s3.amazonaws.com/icons/metamask500.png',
            onClick: () => handleModalButtonClick(() => connectMetaMask({ setWeb3 })),
        },
        walletConnect: {
            text: 'WalletConnect',
            icon: 'https://arcade-web-images.s3.amazonaws.com/icons/cdc500.png',
            onClick: () => handleModalButtonClick(() => connectDeFi({ setWeb3 })),
        },
    };
    

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === overlayRef.current) {
            toggleModal();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay background-secondary" ref={overlayRef} onClick={handleOverlayClick}>
            <div className="modal-container background-primary">
                <h2>Select Method</h2>
                <div className="button-container">
                    {Object.values(modalButtons).map((button, index) => (
                        <button key={index} onClick={button.onClick} className='button-provider button-tertiary'>
                            <div className='button-provider-icon-container'>
                                <img className='button-icon' src={button.icon} alt=''/>
                            </div>
                            {button.text}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
