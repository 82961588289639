import React, { useEffect, useRef, ReactNode } from 'react';
import './dropDownMenu.css';

type DropDownProps = {
    button: ReactNode;
    items: Array<string | ReactNode>;
    itemOnClicks: Array<() => void>;
    isOpen: boolean;
    toggleDropDown: () => void;
    position?: 'left' | 'middle' | 'right';
};

export const DropDownMenu: React.FC<DropDownProps> = ({ button, items, itemOnClicks, isOpen, toggleDropDown, position }) => {

    // Create a ref that we add to the element for which we want to detect outside clicks
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleItemClick = (index: number) => {
        itemOnClicks[index]();
        toggleDropDown();
    }


    useEffect(() => {

        const handleClickOutside = (event: Event) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                toggleDropDown();
            }
        };

        if (isOpen) {
            // Attach the event listener when the dropdown is open
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            // Remove the event listener when the dropdown is closed
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Cleanup function to remove the listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, toggleDropDown]); // Re-run the effect when `isOpen` changes

    return (
        <div className="dropdown-container" ref={containerRef}>
            <div onClick={toggleDropDown}>
                {button}
            </div>
            {isOpen && (
                <ul className={`dropdown-list ${position}`}>
                    {items.map((item, index) => (
                        <li key={index} className="dropdown-item" onClick={() => handleItemClick(index)}>
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
