import config from '../../config'
import { approveContractForAll, checkIsApprovedForAll } from './generalMethods';

// Load config variables from ENV
const arcadePassS1ContractAddress = config.ARCADE_GAMEPASS_S1_CONTRACT_ADDRESS;
const s1RedeemContractAddress = config.S1_REDEEM_CONTRACT_ADDRESS

// Check if the user has approved the arcade staking contract to spend their zoomers
export const checkIsApprovedForAllPassesS1 = async (web3: any, account: string): Promise<boolean> => {

    const approved = await checkIsApprovedForAll(web3, account, s1RedeemContractAddress, arcadePassS1ContractAddress)

    return approved;
}

export const approveS1RedeemContract = async (web3: any, account: string): Promise<any> => {

    const tx = await approveContractForAll(web3, account, s1RedeemContractAddress, arcadePassS1ContractAddress)

    return tx;
}