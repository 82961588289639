import { BigNumber } from 'bignumber.js';

// Set up the configuration for BigNumber.
// This configuration could be moved to a more central location if it's shared across several parts of your application.
BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });

/**
 * Add two string numbers. Since we are working with values in wei,
 * the result should be a whole number (no decimals).
 * @param a First number as string.
 * @param b Second number as string.
 * @returns Result as string, represented as a whole number.
 */
export const add = (a: string, b: string): string => {
    const number1 = new BigNumber(a);
    const number2 = new BigNumber(b);
    // We use integerValue(BigNumber.ROUND_DOWN) to get the whole part of the number.
    // It's unnecessary to round as we are working with whole numbers, but this method discards the decimal part.
    return number1.plus(number2).integerValue(BigNumber.ROUND_DOWN).toFixed();
};

/**
 * Subtract two string numbers. Since we are working with values in wei,
 * the result should be a whole number (no decimals).
 * @param a First number as string.
 * @param b Second number as string.
 * @returns Result as string, represented as a whole number.
 */
export const subtract = (a: string, b: string): string => {
    const number1 = new BigNumber(a);
    const number2 = new BigNumber(b);
    // Similar to the 'add' function, integerValue(BigNumber.ROUND_DOWN) is used to ensure the result is a whole number.
    return number1.minus(number2).integerValue(BigNumber.ROUND_DOWN).toFixed();
};

/**
 * Compare two string numbers to determine if a is greater than b.
 * @param a First number as string.
 * @param b Second number as string.
 * @returns Result as a boolean, true if a is greater than b, false otherwise.
 */
export const isBiggerThan = (a: string, b: string): boolean => {
    const number1 = new BigNumber(a);
    const number2 = new BigNumber(b);
    return number1.isGreaterThan(number2);
}

/**
 * Compare two string numbers to determine if a is greater than or equal to b.
 * @param a First number as string.
 * @param b Second number as string.
 * @returns Result as a boolean, true if a is greater than or equal to b, false otherwise.
 */
export const isBiggerThanOrEqualTo = (a: string, b: string): boolean => {
    const number1 = new BigNumber(a);
    const number2 = new BigNumber(b);
    return number1.isGreaterThanOrEqualTo(number2);
}

/**
 * Multiply two string numbers. Since we are working with a share price (up to 18 decimals)
 * and the number of shares (whole number), the result should be a whole number (no decimals) in wei.
 * @param a First number as string (e.g., share price).
 * @param b Second number as string (e.g., number of shares).
 * @returns Result as string, represented as a whole number in wei.
 */
export const multiply = (a: string, b: string): string => {
    const number1 = new BigNumber(a);
    const number2 = new BigNumber(b);
    // Using integerValue(BigNumber.ROUND_DOWN) to discard the fractional part, as the result should be in wei.
    return number1.multipliedBy(number2).integerValue(BigNumber.ROUND_DOWN).toFixed();
};


/**
 * Performs division that preserves decimal places, specifically for calculations like determining share price.
 * This function maintains high precision to ensure accurate financial calculations.
 *
 * @param a {string} The dividend, as a string, to support large numbers.
 * @param b {string} The divisor, as a string, to support large numbers.
 * @returns {string} The result of the division, as a string, with up to 18 decimal places.
 * 
 * @throws Will throw an error if attempting to divide by zero.
 */
export const divideWithDecimals = (a: string, b: string, decimals: number): string => {
    // Check to prevent division by zero, as it's mathematically invalid and could cause crashes.
    if (b === "0") {
        throw new Error("Division by zero is not allowed.");
    }

    // Convert string arguments into instances of BigNumber to handle very large numbers and perform division.
    const dividend = new BigNumber(a);
    const divisor = new BigNumber(b);

    // Perform the division and convert the result to a string with exactly 18 decimal places.
    // This precision is necessary for operations that deal with cryptocurrency values, ensuring minute fractions are accurately represented.
    return dividend.dividedBy(divisor).toFixed(decimals);
};

/**
 * Performs division intended for calculating whole numbers of shares.
 * This function is used when determining the quantity of shares a user is purchasing or selling.
 *
 * @param a {string} The dividend, as a string, which represents the total amount to be divided.
 * @param b {string} The divisor, as a string, which represents the share price.
 * @returns {string} The result, as a string, representing a whole number of shares.
 * 
 * @throws Will throw an error if attempting to divide by zero.
 */
export const divideToIntegers = (a: string, b: string): string => {
    // Check to prevent division by zero, as it's mathematically invalid and could cause crashes.
    if (b === "0") {
        throw new Error("Division by zero is not allowed.");
    }

    // Convert string arguments into instances of BigNumber, as we are potentially dealing with very large numbers.
    const dividend = new BigNumber(a);
    const divisor = new BigNumber(b);

    // Perform the division, then round the result down to the nearest whole number to represent a valid number of shares.
    // Rounding down ensures that we don't overestimate the number of shares the user can buy.
    // The result is a string representing the integer value of shares, which avoids fractional shares and conforms to transaction standards.
    return dividend.dividedBy(divisor).integerValue(BigNumber.ROUND_DOWN).toFixed();
};
