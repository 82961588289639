import React, { createContext, useContext, useState, useEffect } from 'react';

type IsLoggedInContextType = {
    isLoggedIn: boolean;
    login: () => void;
    logout: () => void;
};

const defaultValue: IsLoggedInContextType = {
    isLoggedIn: false,
    login: () => null,
    logout: () => null,
};

const IsLoggedInContext = createContext(defaultValue);

export const IsLoggedInProvider = ({ children }) => {
    // Initialize isLoggedIn state from localStorage if available
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        return storedIsLoggedIn === 'true'; // Convert string back to boolean
    });

    // Update localStorage when isLoggedIn changes
    useEffect(() => {
        localStorage.setItem('isLoggedIn', isLoggedIn.toString()); // Store as string
    }, [isLoggedIn]);

    const login = () => setIsLoggedIn(true);
    const logout = () => setIsLoggedIn(false);

    return (
        <IsLoggedInContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </IsLoggedInContext.Provider>
    );
};

export const useIsLoggedIn = () => useContext(IsLoggedInContext);
