import React from 'react';
import './pageSpinner.css';

const PageSpinner = () => {
    return (
        <div className="overlay">
            <div className="ring">
                Loading
                <span></span>
            </div>
        </div>
    );
};

export default PageSpinner;
